import React from 'react';
import { Edit, Trash2 } from 'lucide-react';

const ArticlesList = ({ articles, onEdit, onDelete }:any) => {
  return (
    <div className="overflow-x-auto">
      <table className="w-full border-collapse">
        <thead className="bg-gray-100 dark:bg-gray-700">
          <tr>
            <th className="p-3 text-left">ID</th>
            <th className="p-3 text-left">Title</th>
            <th className="p-3 text-left">Author</th>
            <th className="p-3 text-left">Status</th>
            <th className="p-3 text-left">Published Date</th>
            <th className="p-3 text-left">Actions</th>
          </tr>
        </thead>
        <tbody>
          {articles.map((article:any) => (
            <tr 
              key={article.id} 
              className="border-b dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-700"
            >
              <td className="p-3">{article.id}</td>
              <td className="p-3">{article.title}</td>
              <td className="p-3">{article.author}</td>
              <td className="p-3">
                <span className={`
                  px-2 py-1 rounded-full text-xs 
                  ${article.status === 'Published' 
                    ? 'bg-green-100 text-green-800' 
                    : 'bg-yellow-100 text-yellow-800'}
                `}>
                  {article.status}
                </span>
              </td>
              <td className="p-3">{article.publishedDate}</td>
              <td className="p-3 flex items-center space-x-2">
                <button 
                  onClick={() => onEdit(article)} 
                  className="text-blue-500 hover:text-blue-700 transition-colors"
                  title="Edit Article"
                >
                  <Edit size={20} />
                </button>
                <button 
                  onClick={() => onDelete(article.id)} 
                  className="text-red-500 hover:text-red-700 transition-colors"
                  title="Delete Article"
                >
                  <Trash2 size={20} />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ArticlesList;