import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { Globe } from "lucide-react";

let countries = [
  { code: "fr", name: "Français", country_code: "fr" },
  { code: "en", name: "English", country_code: "gb" },
  { code: "hi", name: "हिन्दी", country_code: "in" },
  { code: "es", name: "Español", country_code: "es" },
  { code: "ru", name: "Русский", country_code: "ru" },
  { code: "pt", name: "Português", country_code: "pt" },
];

const LanguageSelector = () => {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const changeLanguage = (code: string) => {
    i18n.changeLanguage(code);
    localStorage.setItem("selectedLanguage", code); // Save selected language
    setIsOpen(false); // Close the dropdown after selecting a language
    console.log(`Language changed to: ${i18n.language}`);
  };

  // Get the name of the selected language
  const selectedLanguage = countries.find(
    (lng) => lng.code === i18n.language
  )?.name;

  useEffect(() => {
    const savedLanguage = localStorage.getItem("selectedLanguage");
    if (savedLanguage && i18n.language !== savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);

  return (
    <div className="relative">
      <button
        onClick={toggleDropdown}
        className="flex items-center space-x-2 bg-white p-2 rounded-md text-gray-700"
      >
        <Globe />{" "}
        <span className="font-semibold">{selectedLanguage || "English"}</span>
      </button>
      {isOpen && (
        <div
          className="absolute right-0 mt-2 w-40 bg-white border rounded-lg shadow-lg z-10"
          onClick={(e) => e.stopPropagation()}
        >
          {countries.map((lng) => (
            <button
              key={lng.code}
              onClick={() => changeLanguage(lng.code)}
              className={`block px-4 py-2 w-full text-left ${
                i18n.language === lng.code ? "bg-gray-200" : "hover:bg-gray-100"
              }`}
              disabled={i18n.language === lng.code}
            >
              {lng.name}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default LanguageSelector;
