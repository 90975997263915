import React, { Component } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { adminRolesArray } from "../../../constants";
import ButtonWithIcon from "../../../Neet-Components/Buttons/ButtonWithIcon";
import ToggleButtons from "../../../Neet-Components/Toggle_Buttons/ToggleButtons";
import style from "./settings.module.css";
import CustomAlert from "../../../components/CustomAlert";
import { AssignApartmentComponentWrap } from "./AssignApartmentComponent";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { Toaster } from "react-hot-toast";

interface Props {
  controller: AssignApartmentComponentWrap;
}
enum LoadingEnum {
  NONE = "NONE",
  PAGELOADING = "PAGELOADING",
  ASSIGNBUTTONLOADING = "ASSIGNBUTTONLOADING",
  DELETEBUTTONLOADING = "DELETEBUTTONLOADING",
}
enum ScreenType {
  ASSIGNNEWADMIN = "ASSIGNNEWADMIN",
  MANAGEASSINEDADMIN = "MANAGEASSINEDADMIN",
}
export default class AssignApartmentView extends Component<Props> {
  handleChange = () => {};
  render() {
    const { admin, currentBuildingType }: any = this.props.controller.context;
    const t = this.props.controller.props.t;
    return (
      <div className="bg-white w-full h-screen px-10 py-5 flex-row items-center justify-center">
        <div className={style.toggle_buttons}>
          <ToggleButtons
            buttonId="1"
            buttonName={t("Assign Roles")}
            onClick={() => {
              this.props.controller.onToggleButtonClicked(
                ScreenType.ASSIGNNEWADMIN
              );
            }}
            isSelected={
              this.props.controller.state.screenType ===
              ScreenType.ASSIGNNEWADMIN
                ? true
                : false
            }
          />
          <ToggleButtons
            buttonId="3"
            buttonName={t("Edit Roles")}
            onClick={() => {
              this.props.controller.onToggleButtonClicked(
                ScreenType.MANAGEASSINEDADMIN
              );
            }}
            isSelected={
              this.props.controller.state.screenType ===
              ScreenType.MANAGEASSINEDADMIN
                ? true
                : false
            }
          />
        </div>
        {this.props.controller.state.screenType == ScreenType.ASSIGNNEWADMIN ? (
          <>
            {/* <h1 className='text-2xl font-semibold mb-6'>Assign Apartment To Admin And Set Roles For Him</h1> */}
            <FormControl sx={{ minWidth: 250, mb: 3 }} size="small">
              <InputLabel id="demo-select-small-label">
                {t("Select Admin")}
              </InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={
                  this.props.controller.state.admins.length <= 0
                    ? "none"
                    : this.props.controller.state.selectedAdmin
                }
                label={t("Select Admin")}
                onChange={this.props.controller.handleChange}
                style={{ height: "40px", width: "250px" }}
              >
                {this.props.controller.state.admins.length <= 0 && (
                  <MenuItem value={"none"}>{t("No Admin Found")}</MenuItem>
                )}
                {this.props.controller.state.admins.map(
                  (admin: any, index: number) => (
                    <MenuItem value={admin}>
                      {admin?.name ? admin.name : "admin 1"}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
            <h1>{t("Select Roles")}</h1>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={this.props.controller.state.role}
                onChange={this.props.controller.handleRoleSelection}
              >
                {adminRolesArray.map((roles: any, index: number) => (
                  <FormControlLabel
                    value={roles.value}
                    control={<Radio />}
                    label={t(roles.name)}
                    disabled={
                      this.props.controller.state.selectedAdmin?._id
                        ? false
                        : true
                    }
                  />
                ))}
              </RadioGroup>
            </FormControl>
            {this.props.controller.state.selectedAdmin?._id && (
              <div className="mt-6 max-w-60">
                <ButtonWithIcon
                  buttonLoading={
                    this.props.controller.state.loading ==
                    LoadingEnum.ASSIGNBUTTONLOADING
                  }
                  buttonName={t("Assign Roles")}
                  onClick={this.props.controller.assignApartmentAndRoleToAdmin}
                />
              </div>
            )}
          </>
        ) : (
          <>
            <h1 className="text-2xl font-semibold mb-6">
              {
                this.props.controller.state.selectedAdminForAssignedAdmin?.admin
                  ?.name
              }
            </h1>
            {this.props.controller.state.selectedAdminForAssignedAdmin?._id ? (
              <FormControl sx={{ minWidth: 250, mb: 3 }} size="small">
                <InputLabel id="demo-select-small-label2">
                  {t("Select Admin")}
                </InputLabel>
                <Select
                  labelId="demo-select-small-label2"
                  id="demo-select-small"
                  value={
                    this.props.controller.state.selectedAdminForAssignedAdmin
                  }
                  defaultValue={
                    this.props.controller.state.selectedAdminForAssignedAdmin
                  }
                  label={t("Select Admin")}
                  onChange={this.props.controller.handleChangeForAssignedAdmin}
                  style={{ height: "40px", width: "250px" }}
                >
                  {this.props.controller.state.assignedAdmins.map(
                    (_admin: any, index: number) => {
                      if (_admin.admin?._id != admin?._id)
                        return (
                          <MenuItem value={_admin}>
                            {_admin?.admin?.name
                              ? _admin?.admin?.name
                              : `admin ${index + 1}`}
                          </MenuItem>
                        );
                    }
                  )}
                </Select>
              </FormControl>
            ) : (
              <>
                <InputLabel id="demo-select-small-label3">
                  {t("Select Admin")}
                </InputLabel>
                <Select
                  labelId="demo-select-small-label3"
                  id="demo-select-small"
                  value={"none"}
                  label={t("Select Admin")}
                  style={{ height: "40px", width: "250px" }}
                  // onChange={this.props.controller.handleChange}
                >
                  <MenuItem value={"none"}>{t("No Admin Found")}</MenuItem>
                </Select>
              </>
            )}
            <FormGroup>
              <h1 className="mt-3">{t("Select Roles")}</h1>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={this.props.controller.state.alreadyAdminRole}
                  onChange={this.props.controller.handleRoleSelectionForEdit}
                >
                  {adminRolesArray.map((roles: any, index: number) => (
                    <FormControlLabel
                      value={roles.value}
                      control={<Radio />}
                      label={t(roles.name)}
                      disabled={
                        this.props.controller.state
                          .selectedAdminForAssignedAdmin?._id
                          ? false
                          : true
                      }
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </FormGroup>
            {this.props.controller.state.selectedAdminForAssignedAdmin?._id && (
              <div className="mt-6 max-w-60">
                {/* <ButtonWithIcon iconName={} buttonLoading={this.props.controller.state.loading==LoadingEnum.DELETEBUTTONLOADING} buttonName={"Remove Admin"} buttonic onClick={()=>{}}/> */}
                <ButtonWithIcon
                  buttonLoading={
                    this.props.controller.state.loading ==
                    LoadingEnum.ASSIGNBUTTONLOADING
                  }
                  buttonName={t("Assign Roles")}
                  onClick={
                    this.props.controller
                      .assignApartmentAndRoleToAdminAlredayInApartment
                  }
                />
                <div className="bg-red-600 rounded-md text-center my-4">
                  <CustomAlert
                    color={"#fff"}
                    text={t("Remove Admin")}
                    onPressDelete={() => {
                      this.props.controller.deleteAdminFromApartment(
                        this.props.controller.state
                          .selectedAdminForAssignedAdmin.admin._id
                      );
                    }}
                  />
                </div>
              </div>
            )}
          </>
        )}
        <Toaster />
      </div>
    );
  }
}
