import React, { Component } from "react";
import { FlatComponentWrap } from "./FlatComponent";
import { Add, Edit, QrCode } from "@mui/icons-material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import ButtonWithIcon from "../../../Neet-Components/Buttons/ButtonWithIcon";
import CustomAlert from "../../../components/CustomAlert";
import AddFlat from "../../../components/AddFlat";
import { AdminRoles } from "../../../constants";
import { StyledDataGrid } from "../apartments/AparmetsView";

interface Props {
  controller: FlatComponentWrap;
}
enum loadingEnu {
  PAGELOADING = "PAGELOADING",
  ADDBUTTONLOADING = "ADDBUTTONLOADING",
  EDITLOADING = "EDITLOADING",
  DELETELOADING = "DELETELOADING",
  NONE = "NONE",
}
export default class FlatView extends Component<Props> {
  render() {
    const { controller } = this.props;

    const { admin, currentBuildingType, apartmentOfSubdivision }: any =
      controller.context;
    const t = this.props.controller.props.t;
    const columns: GridColDef[] = [
      { field: "id", headerName: "ID", width: 100 },
      {
        field: "flatNo",
        headerName: currentBuildingType?.FlatName
          ? t(currentBuildingType?.FlatName)
          : "",
        width: 250,
      },
      // { field: 'name', headerName: 'Name', width: 200 },
      {
        field: "Qr Code",
        headerName: t("QR Code"),
        width: 200,
        renderCell: (index) => {
          return (
            <ButtonWithIcon
              buttonName={
                controller.state.genrateQrLoading ==
                index.api.getRow(index.row.id)._id
                  ? t("Loading..")
                  : t("View QR Code")
              }
              onClick={() => {
                controller.onQrButtonPressed(index.api.getRow(index.row.id));
              }}
              iconName={<QrCode />}
            />
          );
        },
      },
      {
        field: "edit",
        headerName: t("Edit"),
        width: 100,
        renderCell: (index) => {
          return (
            <div
              className="cursor-pointer"
              onClick={() => {
                controller.onShowEditApartmentWithValue(
                  index.api.getRow(index.row.id)
                );
              }}
            >
              <Edit />{" "}
            </div>
          );
        },
      },
      {
        field: "delete",
        headerName: t("Delete"),
        width: 100,
        renderCell: (index) => {
          return (
            <div className="flex-row justify-center gap-5 cursor-pointer">
              <CustomAlert
                onPressDelete={() => {
                  controller.deleteApartment(
                    index.api.getRow(index.row.id)._id
                  );
                }}
              />
            </div>
          );
        },
      },
    ];
    return (
      <div>
        <>
          {controller.state.isShowAddApartment && (
            <AddFlat
              controller={controller}
              onClose={controller.onShowAddApartment}
              onSubmit={controller.addAparment}
              buttonLoading={
                controller.state.loading == loadingEnu.ADDBUTTONLOADING
                  ? true
                  : false
              }
              isAddBox={true}
              textFields={[]}
            />
          )}
          {controller.state.isShowEditApartment && (
            <AddFlat
              controller={controller}
              onClose={controller.onShowEditApartment}
              onSubmit={controller.editAparment}
              buttonLoading={
                controller.state.loading == loadingEnu.EDITLOADING
                  ? true
                  : false
              }
              isAddBox={false}
              textFields={[]}
            />
          )}
          {apartmentOfSubdivision.role !== AdminRoles.Viewer &&
            apartmentOfSubdivision.role !== AdminRoles.Approver && (
              <div className="flex gap-10 w-60">
                <ButtonWithIcon
                  buttonName={
                    currentBuildingType?.addFlat
                      ? t(currentBuildingType.addFlat)
                      : ""
                  }
                  iconName={<Add />}
                  onClick={controller.onShowAddApartment}
                />
              </div>
            )}
          <div className="mt-6 rounded-md overflow-x-scroll h-[67vh] overflow-y-scroll">
            <StyledDataGrid
              rows={controller.state.apartments.map((row: any, index) => ({
                ...row,
                id: index + 1,
              }))}
              columns={columns}
              style={{ background: "#fff", height: "100%" }}
              hideFooterPagination={true}
            />
          </div>
        </>
      </div>
    );
  }
}
