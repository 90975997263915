import React, { MouseEventHandler, useState } from "react";
import TextFieldSimple from "../Neet-Components/Text-Fields/TextFieldSimple";
import { Clear, LocationCitySharp, Map } from "@mui/icons-material";
import { useStateContext } from "../context/ContextProvider";
import ButtonWithIcon from "../Neet-Components/Buttons/ButtonWithIcon";

import { FlatComponentWrap } from "../pages/dashboard/flat/FlatComponent";
import { useTranslation } from "react-i18next";

interface Props {
  textFields: Array<{}>;
  onSubmit: Function;
  buttonLoading: boolean;
  onClose: MouseEventHandler<HTMLDivElement>;
  controller: FlatComponentWrap;
  isAddBox: boolean;
}
interface State {
  isAllValueAreValid: string;
}
const AddFlat = (props: Props) => {
  const { currentColor, currentMode, currentBuildingType } = useStateContext();
  const [isAllValueAreValid, setIsAllValueAreValid] = useState("none");
  const {t}=useTranslation();
  return (
    <div className="fixed h-screen w-screen left-0 top-0 bg-[#0000007b] z-10 flex items-center justify-center">
      <div className="bg-white  flex flex-wrap items-center justify-center w-[500px] p-10 pt-20 relative rounded-xl">
        <div
          onClick={props.onClose}
          className="rounded-full w-10 h-10 flex items-center justify-center absolute right-2 top-2 cursor-pointer shadow-black shadow-md"
          style={{ background: currentColor }}
        >
          <Clear sx={{ color: "white" }} />
        </div>       
        <TextFieldSimple
          onChange={
            props.isAddBox
              ? props.controller.onAddAparmentTextChange
              : props.controller.onEditAparmentTextChange
          }
          placeholder={
            currentBuildingType?.flatTagName
              ? t(currentBuildingType?.flatTagName)
              : ""
          }
          defaultValue={
            props.isAddBox
              ? props.controller.state.apartmentToAdd.flatNo
              : props.controller.state.apartmentToEdit.flatNo
          }
          value={
            props.isAddBox
              ? props.controller.state.apartmentToAdd.flatNo
              : props.controller.state.apartmentToEdit.flatNo
          }
          errorMsg={
            isAllValueAreValid == "flatNo" ? t("Enter a valid flat number") : ""
          }
          width="100%"
          name="flatNo"
        />
        <ButtonWithIcon
          buttonName={t("Save")}
          onClick={async () => {
            const res = await props.onSubmit();
            console.log(res);
            if (res) {
              setIsAllValueAreValid(res);
            }
          }}
          buttonLoading={props.buttonLoading}
        />
      </div>
    </div>
  );
};

export default AddFlat;
