import React, { Component } from "react";
import { useParams } from "react-router-dom";
import { getAdminsApi } from "../admin/api";
import Loading_Page from "../../../Neet-Components/Loading-Page/Loading_Page";
import { getUserToken } from "../../../Globals/Functions";
import {
  assignAdminToSubdivision,
  getAssignedAdminForSubdivision,
  removeAdminFromSubdivision,
} from "../apartments/api";
import DescriptionAlert from "../../../components/DescriptionAlert";
import { AlertTypeEnum } from "../../../Utils/Constants";
import AssignSubdivisionView from "./AssignSubdivisionView";
import { StateContext } from "../../../context/ContextProvider";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

interface Props {
  apartment: string;
  t:any;
}
interface State {
  admins: Array<any>;
  assignedAdmins: Array<any>;
  privileges: Array<any>;
  apartment: any;
  loading: string;
  authToken: string;
  selectedAdmin: any;
  selectedAdminForAssignedAdmin: any;
  screenType: string;
  assignedAdminPrivileges: Array<any>;
}
enum LoadingEnum {
  NONE = "NONE",
  PAGELOADING = "PAGELOADING",
  ASSIGNBUTTONLOADING = "ASSIGNBUTTONLOADING",
}
enum ScreenType {
  ASSIGNNEWADMIN = "ASSIGNNEWADMIN",
  MANAGEASSINEDADMIN = "MANAGEASSINEDADMIN",
}
export class AssignSubdivisionComponentWrap extends Component<Props, State> {
  static contextType: any = StateContext; // Assign StateContext to static contextType property
  constructor(props: Props) {
    super(props);
    this.state = {
      admins: [],
      privileges: [],
      assignedAdminPrivileges: [],
      apartment: this.props.apartment,
      loading: LoadingEnum.PAGELOADING,
      authToken: "",
      selectedAdmin: {},
      screenType: ScreenType.ASSIGNNEWADMIN,
      selectedAdminForAssignedAdmin: {},
      assignedAdmins: [],
    };
  }
  componentDidMount(): void {
    const auth_Token = getUserToken();
    if (auth_Token) {
      this.setState({ authToken: auth_Token });
      this.getAdmins(auth_Token);
      this.getAdminsByApartment(this.state.apartment, auth_Token);
    }
  }
  onToggleButtonClicked = (screenType: string) => {
    this.setState({ screenType: screenType });
    if (screenType === ScreenType.ASSIGNNEWADMIN) {
      this.setState({ privileges: [] });
    }
  };
  assignApartmentAndRoleToAdminAlredayInApartment = async () => {
    this.setState({ loading: LoadingEnum.ASSIGNBUTTONLOADING });
    const resForAssignApartment = await assignAdminToSubdivision(
      {
        admin: this.state.selectedAdminForAssignedAdmin.admin._id,
        subDivision: this.state.apartment,
        privileges: this.state.assignedAdminPrivileges,
      },
      this.state.authToken
    );
    console.log("resForAssignApartment", resForAssignApartment);
    if (
      resForAssignApartment.isSuccess &&
      resForAssignApartment.statusCode == 200
    ) {
      const res = await getAssignedAdminForSubdivision(
        this.state.apartment,
        this.state.authToken
      );
      console.log("admins by apratments", res.data);
      if (res.isSuccess && res.statusCode == 200 && res.data.length > 0) {
        this.setState({
          selectedAdminForAssignedAdmin: res.data[0],
          assignedAdminPrivileges: [...res.data[0].privileges],
        });
        this.setState({ assignedAdmins: res.data });
        toast("Assigned Role Succesfully");
        DescriptionAlert({
          type: AlertTypeEnum.Success,
          msg: "Admin Assigned Successfully",
          title: "Success",
        });
        console.log(resForAssignApartment);
      }
    } else {
      console.log(
        "something went wrong while assiging aparment and role to admin"
      );
    }
    this.setState({
      loading: LoadingEnum.NONE,
    });
  };
  assignApartmentAndRoleToAdmin = async () => {
    this.setState({ loading: LoadingEnum.ASSIGNBUTTONLOADING });
    const resForAssignApartment = await assignAdminToSubdivision(
      {
        admin: this.state.selectedAdmin._id,
        subDivision: this.state.apartment,
        privileges: this.state.privileges,
      },
      this.state.authToken
    );
    console.log("resForAssignApartment", resForAssignApartment);
    if (
      resForAssignApartment.isSuccess &&
      resForAssignApartment.statusCode == 200
    ) {
      const res = await getAssignedAdminForSubdivision(
        this.state.apartment,
        this.state.authToken
      );
      console.log("admins by apratments", res);
      if (res.isSuccess && res.statusCode == 200 && res.data.length > 0) {
        this.setState({
          selectedAdminForAssignedAdmin: res.data[0],
          assignedAdminPrivileges: [...res.data[0].privileges],
        });
        this.setState({ assignedAdmins: res.data });
        toast("Assigned Role Succesfully");
        DescriptionAlert({
          type: AlertTypeEnum.Success,
          msg: "Admin Assigned Successfully",
          title: "Success",
        });
        console.log(resForAssignApartment);
      }
    } else {
      console.log(
        "something went wrong while assiging aparment and role to admin"
      );
    }
    this.setState({
      loading: LoadingEnum.NONE,
    });
  };
  handlePrivilegeChange = (e: any, privilegeName: string) => {
    const { checked } = e.target;
    const { privileges } = this.state;
    if (checked) {
      this.setState({
        privileges: [...privileges, privilegeName],
      });
    } else {
      this.setState({
        privileges: privileges.filter((name) => name !== privilegeName),
      });
    }
    console.log(this.state.privileges);
  };
  handlePrivilegeChangeForAssignedAdmin = (e: any, privilegeName: string) => {
    const { checked } = e.target;
    const { assignedAdminPrivileges } = this.state;
    if (checked) {
      this.setState({
        assignedAdminPrivileges: [...assignedAdminPrivileges, privilegeName],
      });
    } else {
      this.setState({
        assignedAdminPrivileges: assignedAdminPrivileges.filter(
          (name) => name !== privilegeName
        ),
      });
    }
    console.log(this.state.privileges);
  };
  handleChangeForAssignedAdmin = (event: any) => {
    const { value } = event.target;
    const { privileges } = value;
    console.log("admin privileges for assigned one", privileges);
    console.log(value);
    this.setState({
      assignedAdminPrivileges: [...privileges],
      selectedAdminForAssignedAdmin: value,
    });
  };

  handleChange = (event: any) => {
    const { value } = event.target;
    const { privileges } = value;
    this.setState({
      privileges: [...privileges],
      selectedAdmin: value,
    });
  };

  getAdmins = async (token: string) => {
    this.setState({ loading: LoadingEnum.PAGELOADING });
    const res = await getAdminsApi(token);
    console.log("all admins", res);
    if (res.isSuccess && res.statusCode == 200 && res.data.length > 0) {
      this.setState({
        selectedAdmin: res.data[0],
        privileges: [...res.data[0].privileges],
      });
      this.setState({ admins: res.data });
    }
    // this.setState({
    //   loading: LoadingEnum.NONE
    // })
  };
  getAdminsByApartment = async (apartment: string, token: string) => {
    this.setState({ loading: LoadingEnum.PAGELOADING });
    const res = await getAssignedAdminForSubdivision(apartment, token);
    console.log("admins by apratments", res.data);
    if (res.isSuccess && res.statusCode == 200 && res.data.length > 1) {
      this.setState({
        selectedAdminForAssignedAdmin: res.data[0],
        assignedAdminPrivileges: [...res.data[0].privileges],
      });
      this.setState({ assignedAdmins: res.data });
    }
    this.setState({
      loading: LoadingEnum.NONE,
    });
  };
  deleteAdminFromApartment = async (adminId: string) => {
    const { admin }: any = this.context;
    console.log(adminId);
    const res = await removeAdminFromSubdivision(
      this.state.apartment,
      adminId,
      this.state.authToken
    );
    console.log(res);
    if (res.isSuccess && res.statusCode == 200) {
      const res = await getAssignedAdminForSubdivision(
        this.state.apartment,
        this.state.authToken
      );
      console.log("admins by apratments", res.data);
      if (res.isSuccess && res.statusCode == 200 && res.data.length > 0) {
        this.setState({
          selectedAdminForAssignedAdmin: res.data[0],
          assignedAdminPrivileges: [...res.data[0].privileges],
        });
        this.setState({ assignedAdmins: res.data });
        toast("Admin Removed Succesfully");
      }
    }
  };
  render() {
    console.log(this.props.apartment);
    if (this.state.loading == LoadingEnum.PAGELOADING) {
      return <Loading_Page />;
    }
    return <AssignSubdivisionView controller={this} />;
  }
}
const AssignSubdivisionComponent = () => {
  const { apartment } = useParams();
  const {t}=useTranslation();
  return <AssignSubdivisionComponentWrap apartment={apartment as string} t={t}/>;
};

export default AssignSubdivisionComponent;
