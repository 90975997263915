export const BuildingTypes = [
  {
    name: "Apartment",
    type: "apartment",
    buildingName: "Tower/Wings",
    FlatName: "Manage House / Flat",
    addBulding: "Add Tower / Wings",
    addFlat: "Add Flat/House",
    shortName: "manage company",
    buildingTagName: "Tower/Wing",
    flatTagName: "Flat/House Name/No",
  },
  {
    name: "Coworking",
    type: "coworking",
    buildingName: "Companies",
    FlatName: "Manage Coworking Departments / Category",
    addBulding: "Add Company",
    addFlat: "Add Department",
    shortName: "manage company",
    buildingTagName: "Company",
    flatTagName: "Department",
  },
  {
    name: "Multi",
    type: "multi",
    buildingName: "Tower/Wings",
    FlatName: "Single House / Unit",
    addBulding: "Add Tower / Wings",
    addFlat: "Add / Manage Unit",
    shortName: "manage company",
    buildingTagName: "Tower/Wing",
    flatTagName: "House / Unit",
  },
  {
    name: "Company",
    type: "company",
    buildingName: "Dept.",
    FlatName: "Manage Departments",
    addBulding: "Add Departments",
    addFlat: "Add Departments",
    shortName: "manage company",
    buildingTagName: "",
    flatTagName: "Departments",
  },
  {
    name: "Hotel",
    type: "hotel",
    buildingName: "Dept.",
    FlatName: "Manage Departments",
    addBulding: "Add Departments",
    addFlat: "Add Departments",
    shortName: "manage company",
    buildingTagName: "",
    flatTagName: "Departments",
  },
];

export const getBuildingDetailsWithTypeName = (name) => {
  const building = BuildingTypes.filter((_item) => _item.name === name);
  console.log(building);
  if (building) {
    return building[0];
  } else {
    return BuildingTypes[0];
  }
};
