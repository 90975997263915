import React, { Component } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  LogOutAdmin,
  getUserToken,
  validateObject,
} from "../../../Globals/Functions";
import { StateContext } from "../../../context/ContextProvider";
import {
  addLockApi,
  deleteLockByIdApi,
  getLocksApi,
  changeLockStatusByIdApi,
  changeLockStatusByPropertyApi,
  getCloudNodeByCredentialsApi,
  getDevicesByCredentialsApi,
} from "./apis";
import LockManagerView from "./LockManagerView";
import Loading_Page from "../../../Neet-Components/Loading-Page/Loading_Page";
import toast from "react-hot-toast";
import { SelectChangeEvent } from "@mui/material";
import ApiClient from "../../../Api/apiClient";
import { useTranslation } from "react-i18next";

interface Props {
  navigate: any;
  levelType: string;
  levelId: string;
  t:any;
}
interface State {
  lockToAdd: {
    name: string;
    lockId: string;
    pdkId: string;
    autoLockTime?: string;
  };
  lockToEdit: {
    name: string;
    lockId: string;
    _id: string;
    pdkId: string;
    autoLockTime?: string;
  };
  authToken: string;
  loading: string;
  isShowAddLock: boolean;
  isShowEditLock: boolean;
  isShowDeleteLock: boolean;
  locks: Array<Object>;
  isAddLock: boolean;
  swithcButtonLoadingId: string;
  cloudNode: any;
  selectedCloudNode: any;
  devices: any;
  selectedDevice: any;
  waveShareRelays: any;
  selectedWaveRelay: any;
  selectWaveRelayChanel: any;
  IntelbrasRelays: any;
  selectedIntelbras: any;
  selectIntelbrasChanel: any;
  showDisableLockPopup: boolean;
  disableLockDetails: {
    lockId: string;
    _id: string;
    status: boolean;
  };
}
enum loadingEnu {
  PAGELOADING = "PAGELOADING",
  ADDBUTTONLOADING = "ADDBUTTONLOADING",
  EDITLOADING = "EDITLOADING",
  DELETELOADING = "DELETELOADING",
  NONE = "NONE",
  REMOVELOGO = "REMOVELOGO",
}
export class LockManagerComponentWrap extends Component<Props, State> {
  static contextType: any = StateContext; // Assign StateContext to static contextType property
  constructor(props: Props) {
    super(props);
    this.state = {
      lockToAdd: {
        name: "",
        lockId: "",
        pdkId: "",
        autoLockTime: "-1",
      },
      lockToEdit: {
        _id: "",
        name: "",
        lockId: "",
        pdkId: "",
        autoLockTime: "-1",
      },
      authToken: "",
      loading: loadingEnu.PAGELOADING,
      isShowAddLock: false,
      isShowEditLock: false,
      isShowDeleteLock: false,
      isAddLock: true,
      locks: [],
      swithcButtonLoadingId: "",
      cloudNode: [],
      selectedCloudNode: {},
      devices: [],
      selectedDevice: {},
      waveShareRelays: [],
      selectedWaveRelay: {},
      selectWaveRelayChanel: {},
      IntelbrasRelays: [],
      selectedIntelbras: {},
      selectIntelbrasChanel: {},
      showDisableLockPopup: false,
      disableLockDetails: {
        lockId: "",
        _id: "",
        status: false,
      },
    };
  }
  componentDidMount(): void {
    const { setIsUserLogedIn, admin }: any = this.context;
    const auth_Token = getUserToken();
    if (auth_Token) {
      this.setState({ authToken: auth_Token });
      this.getLocks(auth_Token);
    } else {
      LogOutAdmin(this.props.navigate, setIsUserLogedIn);
    }
  }
  getCloudNodeByCredentials = async (credentialId: string) => {
    this.setState({ loading: loadingEnu.ADDBUTTONLOADING });
    const res = await getCloudNodeByCredentialsApi(credentialId);
    console.log("get cloud node response", res);
    if (res?.isSuccess && res?.statusCode == 200) {
      this.setState({
        cloudNode: res.data,
        selectedCloudNode: res.data.length > 0 ? res.data[0] : {},
      });
      if (res.data.length > 0) {
        this.getDevicesByCredential(res.data[0].id, credentialId);
      }
    } else {
      console.log("error while getting cloud node");
      toast("Something went wrong!");
    }
    this.setState({ loading: loadingEnu.NONE });
  };
  getDevicesByCredential = async (cludNodeId: string, credentialId: string) => {
    this.setState({ loading: loadingEnu.ADDBUTTONLOADING });
    const res = await getDevicesByCredentialsApi(credentialId, cludNodeId);
    console.log("get device response", res);
    if (res?.isSuccess && res?.statusCode == 200) {
      this.setState({
        devices: res.data,
        selectedDevice: res.data.length > 0 ? res.data[0] : {},
      });
    } else {
      console.log("error while getting cloud node");
      toast("Something went wrong!");
    }
    this.setState({ loading: loadingEnu.NONE });
  };

  getWaveShareRelays = async () => {
    const { admin }: any = this.context;
    this.setState({ loading: loadingEnu.ADDBUTTONLOADING });
    const res = await ApiClient.getInstance().get(
      `/waveshareRelay/getRelayForAdmin/${admin?._id}/isChannelDetailsRequired/1`
    );
    console.log("get cloud node response", res);
    if (res?.isSuccess && res?.statusCode == 200) {
      this.setState({
        waveShareRelays: res.data,
        selectedWaveRelay: res.data.length > 0 ? res.data[0] : {},
      });
      if (res?.data?.length > 0 && res?.data[0]?.channels.length > 0) {
        this.setState({
          selectWaveRelayChanel: res?.data[0]?.channels[0],
        });
      }
    } else {
      console.log("error while getting cloud node");
      toast(this.props.t("something went wrong!"));
    }
    this.setState({ loading: loadingEnu.NONE });
  };
  getIntelbrasRelays = async () => {
    const { admin }: any = this.context;
    this.setState({ loading: loadingEnu.ADDBUTTONLOADING });
    const res = await ApiClient.getInstance().get(      
      `/interbras/getForAdmin/${admin?._id}/isChannelDetailsRequired/1`
    );
    console.log("get cloud node response", res);
    if (res?.isSuccess && res?.statusCode == 200) {
      this.setState({
        IntelbrasRelays: res.data,
        selectedIntelbras: res.data.length > 0 ? res.data[0] : {},
      });
      if (res?.data?.length > 0 && res?.data[0]?.channels.length > 0) {
        this.setState({
          selectIntelbrasChanel: res?.data[0]?.channels[0],
        });
      }
    } else {
      console.log("error while getting cloud node");
      toast(this.props.t("something went wrong!"));
    }
    this.setState({ loading: loadingEnu.NONE });
  };
  handleChangeCloudNodeChange = (event: SelectChangeEvent) => {
    this.setState({ selectedCloudNode: event.target.value });
  };
  handleChangeWaveRelayChange = (event: SelectChangeEvent) => {
    this.setState({ selectedWaveRelay: event.target.value });
    const relayWave: any = event.target.value;
    if (relayWave?.channels.length > 0) {
      this.setState({
        selectWaveRelayChanel: relayWave?.channels[0],
      });
    }
  };
  handleWaveRelayChanelChange = (event: SelectChangeEvent) => {
    this.setState({ selectWaveRelayChanel: event.target.value });
  };
  handleChangeIntelbrasChange = (event: SelectChangeEvent) => {
    this.setState({ selectedIntelbras: event.target.value });
    const relayWave: any = event.target.value;
    if (relayWave?.channels.length > 0) {
      this.setState({
        selectIntelbrasChanel: relayWave?.channels[0],
      });
    }
  };
  handleIntelbrasChanelChange = (event: SelectChangeEvent) => {
    this.setState({ selectIntelbrasChanel: event.target.value });
  };
  handleChangeDeviceIdChange = (event: SelectChangeEvent) => {
    this.setState({ selectedDevice: event.target.value });
  };
  getLocks = async (token: string) => {
    this.setState({ loading: loadingEnu.PAGELOADING });
    const res = await getLocksApi(token, this.props.levelId);
    console.log("get aparments response", res);
    if (res.isSuccess && res.statusCode == 200) {
      this.setState({ locks: res.data });
    } else {
      console.log("error while getting locks");
    }
    this.setState({
      loading: loadingEnu.NONE,
    });
  };
  onShowEditApartmentWithValue = (lock: any) => {
    console.log("lock to edit", lock);
    this.setState({
      lockToEdit: {
        _id: lock?.lock?._id,
        name: lock.name,
        lockId: lock?.lockId,
        pdkId: lock.cloudNode,
      },
    });
    this.setState({
      isShowEditLock: !this.state.isShowEditLock,
      isAddLock: false,
    });
  };
  deleteLock = async (lockId: string) => {
    this.setState({ loading: loadingEnu.DELETELOADING });
    const res = await deleteLockByIdApi(lockId, this.state.authToken);
    console.log(res);
    if (res.isSuccess && res.statusCode == 200) {
      const resForGetingLocks = await getLocksApi(
        this.state.authToken,
        this.props.levelId
      );
      console.log("resForGetingLocks", resForGetingLocks);
      if (resForGetingLocks.isSuccess && resForGetingLocks.statusCode == 200) {
        this.setState({ locks: resForGetingLocks.data });
      } else {
        console.log("errorr while refreshing list of aparment after deleting");
      }
    } else {
      console.log("errorr while deleting the apartment");
    }
    this.setState({ loading: loadingEnu.NONE });
  };

  addLock = async (lockCredentialsId: string) => {
    // console.log( this.state.lockToAdd.autoLockTime);
    // return
    if (this.state.loading == loadingEnu.ADDBUTTONLOADING) {
      return;
    }
    const { admin }: any = this.context;
    console.log(admin);
    // return
    const isAllValueAreValid = validateObject(this.state.lockToAdd, [
      "lockId",
      "pdkId",
    ]);
    if (isAllValueAreValid === "right") {
      console.log(this.state.selectWaveRelayChanel);
      console.log(this.state.selectedDevice);
      console.log(lockCredentialsId);

      this.setState({ loading: loadingEnu.ADDBUTTONLOADING });
      const matchedItem = admin.lockCredentials.find(
        (item: any) => item._id === lockCredentialsId
      );
      console.log(matchedItem);
      // return
      const res = await addLockApi(
        matchedItem.name == "WaveShare" || matchedItem.name == "Intelbras"
          ? {
              name: this.state.lockToAdd.name,
              lockCredentials: lockCredentialsId,
              aptOrSubDivision: this.props.levelId,
              deviceId:matchedItem.name == "Intelbras"?this.state.selectIntelbrasChanel?._id: this.state.selectWaveRelayChanel?._id,
              createdBy: admin._id,
              cloudNode:matchedItem.name == "Intelbras"?this.state.selectIntelbrasChanel:this.state.selectWaveRelayChanel?._id,
              autoLockTimeInSec:
                this.state.lockToAdd.autoLockTime != undefined &&
                this.state.lockToAdd.autoLockTime != "-1"
                  ? parseInt(this.state.lockToAdd.autoLockTime)
                  : -1,
            }
          : {
              name: this.state.lockToAdd.name,
              lockCredentials: lockCredentialsId,
              aptOrSubDivision: this.props.levelId,
              deviceId: this.state.selectedDevice?.id,
              createdBy: admin._id,
              cloudNode: this.state.selectedCloudNode?.id,
            },
        this.state.authToken
      );
      // console.log(this.state)
      console.log(res);
      if (res.isSuccess && res.statusCode == 200) {
        this.setState({ loading: loadingEnu.PAGELOADING });
        const resForGettingLocks = await getLocksApi(
          this.state.authToken,
          this.props.levelId
        );
        console.log(
          "resForGettingLocks after adding new one",
          resForGettingLocks
        );
        if (
          resForGettingLocks.isSuccess &&
          resForGettingLocks.statusCode == 200
        ) {
          this.onShowAddLock();
          this.setState({
            loading: loadingEnu.NONE,
            lockToAdd: {
              name: "",
              lockId: "",
              pdkId: "",
              autoLockTime: "",
            },
          });
          this.setState({
            locks: resForGettingLocks.data,
          });
        } else {
          console.log("error while geting apartments after adding");
        }
      } else {
        console.log("someting is wrong when adding apartment");
      }
      this.setState({ loading: loadingEnu.NONE });
    } else {
      return isAllValueAreValid;
    }
  };
  editAparment = async () => {
    if (this.state.loading == loadingEnu.EDITLOADING) {
      return;
    }
    const { admin }: any = this.context;
    const isAllValueAreValid = validateObject(this.state.lockToEdit, []);
    if (isAllValueAreValid === "right") {
      this.setState({ loading: loadingEnu.EDITLOADING });
      const res = await addLockApi(
        {
          name: this.state.lockToEdit.name,
          lockCredentials: admin.lockCredentials[0]?._id,
          aptOrSubDivision: this.props.levelId,
          deviceId: this.state.selectedDevice.id,
          createdBy: admin._id,
          _id: this.state.lockToEdit._id,
          cloudNode: this.state.selectedCloudNode.id,
        },
        this.state.authToken
      );
      console.log(res);
      const resForGettingAparments = await getLocksApi(
        this.state.authToken,
        this.props.levelId
      );
      console.log(resForGettingAparments);
      if (
        resForGettingAparments.isSuccess &&
        resForGettingAparments.statusCode == 200
      ) {
        this.onShowEditLock();
        this.setState({
          loading: loadingEnu.NONE,
          lockToEdit: {
            name: "",
            _id: "",
            lockId: "",
            pdkId: "",
          },
        });
        this.setState({
          locks: resForGettingAparments.data,
        });
      } else {
        console.log("error while geting locks after adding");
      }
    } else {
      console.log("someting is wrong when adding lock");
    }
  };
  onShowAddLock = () => {
    const { setIsUserLogedIn, admin }: any = this.context;
    this.getWaveShareRelays();
    this.getIntelbrasRelays()
    if (admin?.lockCredentials[0]?._id && !this.state.isShowAddLock) {
      this.getCloudNodeByCredentials(admin?.lockCredentials[0]?._id);
    }
    console.log("onShowAddApartment clicked");
    this.setState({
      isShowAddLock: !this.state.isShowAddLock,
      isAddLock: true,
    });
  };
  onShowEditLock = () => {
    const { setIsUserLogedIn, admin }: any = this.context;
    this.getWaveShareRelays();
    this.getIntelbrasRelays()
    if (admin?.lockCredentials[0]?._id && !this.state.isShowAddLock) {
      this.getCloudNodeByCredentials(admin?.lockCredentials[0]?._id);
    }
    console.log("onShowEditApartment clicked");
    this.setState({
      isShowEditLock: !this.state.isShowEditLock,
      isAddLock: false,
    });
  };
  onAddAparmentTextChange = (e: any) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      lockToAdd: {
        ...prevState.lockToAdd,
        [name]: value,
      },
    }));
  };
  onToggleLockStatusByPropertyId = async (
    propertyId: string,
    status: boolean,
    _id: string
  ) => {
    if (this.state.swithcButtonLoadingId == "") {
      this.setState({ swithcButtonLoadingId: _id });
      const res = await changeLockStatusByPropertyApi(propertyId, status);
      console.log(res);
      if (res.isSuccess) {
        const res = await getLocksApi(this.state.authToken, this.props.levelId);
        console.log("get aparments response", res);
        if (res.isSuccess && res.statusCode == 200) {
          this.setState({ locks: res.data });
        }
        this.setState({ swithcButtonLoadingId: "" });
      } else {
        alert("something went wrong");
        this.setState({ swithcButtonLoadingId: "" });
      }
    } else {
      console.log("Another button is loading, please wait...");
    }
  };
  onLockDisableButtonPressed = (
    lockId: string,
    status: boolean,
    _id: string
  ) => {
    this.setState({
      showDisableLockPopup: true,
      disableLockDetails: { lockId, status, _id },
    });
  };

  onToggleLockStatus = async (lockId: string, status: boolean, _id: string) => {
    if (this.state.swithcButtonLoadingId == "") {
      this.setState({ swithcButtonLoadingId: _id });
      const res = await changeLockStatusByIdApi(lockId, status);
      console.log(res);
      if (res.isSuccess) {
        const res = await getLocksApi(this.state.authToken, this.props.levelId);
        console.log("get aparments response", res);
        if (res.isSuccess && res.statusCode == 200) {
          this.setState({ locks: res.data });
        }
        this.setState({ swithcButtonLoadingId: "" });
      } else {
        this.setState({ swithcButtonLoadingId: "" });        
        toast(this.props.t("something went wrong!"));
      }
    } else {
      console.log("Another button is loading, please wait...");
    }
  };
  onEditAparmentTextChange = (e: any) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      lockToEdit: {
        ...prevState.lockToEdit,
        [name]: value,
      },
    }));
  };
  render() {
    return <LockManagerView controller={this as any} />;
  }
}

const LockManagerComponent = () => {
  const navigate = useNavigate();
  const {t}=useTranslation();
  const { levelId, levelType }: any = useParams();
  return (
    <LockManagerComponentWrap
      navigate={navigate}
      levelId={levelId}
      levelType={levelType}
      t={t}
    />
  );
};

export default LockManagerComponent;
