import React, { Component } from "react";
import { useNavigate } from "react-router-dom";
import { LogOutAdmin, getUserToken } from "../../../Globals/Functions";
import { StateContext } from "../../../context/ContextProvider";
import Loading_Page from "../../../Neet-Components/Loading-Page/Loading_Page";
import { getUsersByApartmentIdAndStatus } from "./apiHandler";
import UserView from "./UserView";
import { getApartmentsApi } from "../apartments/api";
import { useTranslation } from "react-i18next";

interface Props {
  navigate: any;
  showRequest: boolean;
  t:any;
}
interface State {
  authToken: string;
  loading: string;
  isShowAddApartment: boolean;
  isShowEditApartment: boolean;
  isShowDeleteApartment: boolean;
  requests: Array<Object>;
  status: string;
  apartments: Array<Object>;
  selectedApartment: any;
  adminRole: number;
}
enum loadingEnu {
  PAGELOADING = "PAGELOADING",
  ADDBUTTONLOADING = "ADDBUTTONLOADING",
  EDITLOADING = "EDITLOADING",
  DELETELOADING = "DELETELOADING",
  NONE = "NONE",
}
export class UserComponentWrap extends Component<Props, State> {
  static contextType: any = StateContext; // Assign StateContext to static contextType property
  constructor(props: Props) {
    super(props);
    this.state = {
      authToken: "",
      loading: loadingEnu.PAGELOADING,
      isShowAddApartment: false,
      isShowEditApartment: false,
      isShowDeleteApartment: false,
      status: "all",
      apartments: [],
      selectedApartment: { _id: "" },
      requests: [],
      adminRole: 10000,
    };
  }
  componentDidMount(): void {
    const { setIsUserLogedIn }: any = this.context;
    const auth_Token = getUserToken();
    if (auth_Token) {
      this.setState({ authToken: auth_Token });
      this.getRequests(auth_Token);
    } else {
      LogOutAdmin(this.props.navigate, setIsUserLogedIn);
    }
  }

  getRequests = async (token: string) => {
    const { admin }: any = this.context;
    this.setState({ loading: loadingEnu.PAGELOADING });
    const res = await getApartmentsApi(token);
    console.log("get aparments response", res);
    if (res.isSuccess && res.statusCode == 200 && res.data.length > 0) {
      this.setState({
        apartments: res.data,
        selectedApartment: res.data[0].apartment,
        adminRole: res.data[0].role,
      });
      const userDetailsResponse = await getUsersByApartmentIdAndStatus(
        undefined,
        this.state.status,
        token
      );
      console.log("get user response", userDetailsResponse);
      if (
        userDetailsResponse.isSuccess &&
        userDetailsResponse.statusCode == 200
      ) {
        this.setState({ requests: userDetailsResponse.data });
      }
    }
    this.setState({
      loading: loadingEnu.NONE,
    });
  };
  deleteApartment = async (apartmentId: string) => {
    // this.setState({ loading: loadingEnu.DELETELOADING });
    // const res = await deleteApartmentsByIdApi(apartmentId, this.state.authToken);
    // console.log(res);
    // if (res.isSuccess && res.statusCode == 200) {
    //   const resForGetingAparment = await getRequestsApi(this.state.authToken)
    //   console.log("resForGetingAparment", resForGetingAparment);
    //   if (resForGetingAparment.isSuccess && resForGetingAparment.statusCode == 200) {
    //     this.setState({ requests: resForGetingAparment.data });
    //   }
    //   else {
    //     console.log("errorr while refreshing list of aparment after deleting")
    //   }
    // }
    // else {
    //   console.log("errorr while deleting the apartment")
    // }
    // this.setState({ loading: loadingEnu.NONE });
  };
  onShowAddApartment = () => {
    console.log("onShowAddApartment clicked");
    this.setState({ isShowAddApartment: !this.state.isShowAddApartment });
  };
  onApartmentStatusChange = async (value: any, role: any) => {
    console.log("value from onApartmentStatusChange");
    console.log(value);
    // return;
    if (value == "all") {
      this.setState({ selectedApartment: value });
      const res = await getUsersByApartmentIdAndStatus(
        undefined,
        this.state.status,
        this.state.authToken
      );
      console.log("get user response response after status change", res);
      if (res.isSuccess && res.statusCode == 200) {
        this.setState({ requests: res.data });
      }
    } else {
      console.log(value);
      this.setState({ selectedApartment: value, adminRole: parseInt(role) });
      const res = await getUsersByApartmentIdAndStatus(
        value._id,
        this.state.status,
        this.state.authToken
      );
      console.log("get user response response after status change", res);
      if (res.isSuccess && res.statusCode == 200) {
        this.setState({ requests: res.data });
      }
    }
  };
  onStatusChange = async (value: string) => {
    this.setState({ status: value });
    const res = await getUsersByApartmentIdAndStatus(
      this.state.selectedApartment?._id,
      value,
      this.state.authToken
    );
    console.log("get user response response after status change", res);
    if (res.isSuccess && res.statusCode == 200) {
      this.setState({ requests: res.data });
    }
  };
  render() {
    // if (this.state.loading == loadingEnu.PAGELOADING) {
    //   return <Loading_Page />
    // }
    return <UserView controller={this as any} />;
  }
}

const UserComponent = ({ showRequest }: { showRequest: boolean }) => {
  const navigate = useNavigate();
  const {t}=useTranslation();
  return <UserComponentWrap navigate={navigate} showRequest={showRequest} t={t}/>;
};

export default UserComponent;
