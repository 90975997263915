import React, { useState, useEffect } from "react";
import { X, HelpCircle, Copy, Eye, EyeOff,RefreshCw, } from "lucide-react";
import ApiClient from "../Api/apiClient";
import toast from "react-hot-toast";
import { useStateContext } from "../context/ContextProvider";
import { useTranslation } from "react-i18next";
// import { toast } from '@/components/ui/use-toast';

interface Device {
  _id?: string;
  deviceType: string;
  deviceName: string;
  description: string;
  onlineStatus: "active" | "notactivated" | "offline";
  lastUpTime: string;
}

interface EditModalProps {
  isOpen: boolean;
  onClose: () => void;  
  onAdded:Function;
  device:any;
}

interface AddModalProps {
  isOpen: boolean;
  onClose: () => void;  
  onAdded:Function;
}

interface SubscriptionModalProps {
  isOpen: boolean;
  onClose: () => void;
  device: any;
  onConfirm: (data: any) => void;
}

export const EditWebSharePdk: React.FC<EditModalProps> = ({
  isOpen,
  onClose,
  onAdded,  
  device
}) => {
  const [formData, setFormData] = useState({
    deviceType: "Websharepdk",
    deviceName: "",
    noOfChanel: 1,
    mqttServer: "",
    deviceId:"",
  });
  const [listMqttServer, setListMqttServer] = useState([]);
  const [loading, setLoading] = useState(false);
  const {admin}=useStateContext();
  const {t}=useTranslation();
  const onConfirm = async (formData: any) => {
    if (loading) {
      return;
    }
    setLoading(true);
    const res = await ApiClient.getInstance().post("/waveshareRelay/addEditRelay", {
      name: formData?.deviceName,
      deviceId: formData?.deviceId??'',
      noOfChannel: formData?.noOfChanel,
      mqttServer: formData?.mqttServer,
      createdBy: admin?._id,      
      _id: device?._id,
    });
    console.log(res);
    if (res.isSuccess) {
      setFormData({
        deviceId:"",
        deviceName:"",
        mqttServer:"",
        noOfChanel:1,
        deviceType: "Websharepdk",
      })
      toast.success(t("Added successfully"));
      onAdded();
    } else {
      toast.error(t("Something went wrong! Please try again later"));
    }
    setLoading(false);
  };
  useEffect(()=>{
    console.log(device)
    setFormData({
      deviceName:device.name,
      deviceId:device.deviceId,
      mqttServer:device.mqttPubSub?.mqttServer?._id,
      noOfChanel:device.noOfChannel,
      deviceType: "Websharepdk",
    })
  },[device])
  const getAllMqttServers = async () => {
    const res = await ApiClient.getInstance().get(`/mqttServer/getAllServers`);
    if (res.isSuccess) {
      setListMqttServer(res.data);      
    }
  };
  useEffect(() => {
    getAllMqttServers();
  }, []);
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg w-full max-w-md p-6 relative">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">{t("Add")}</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              <span className="text-red-500">*</span> {t("Device Type")}
            </label>
            <select
              className="w-full border rounded-md px-3 py-2"
              value={formData.deviceType}
              onChange={(e) =>
                setFormData({ ...formData, deviceType: e.target.value })
              }
            >
              {/* <option value="">Select type</option> */}
              <option value="Websharepdk">WaveShare</option>
              {/* <option value="Serial">Serial</option> */}
              {/* <option value="Ethernet">Ethernet</option> */}
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              <span className="text-red-500">*</span> {t("Device name")}
            </label>
            <input
              type="text"
              className="w-full border rounded-md px-3 py-2"
              value={formData.deviceName}
              onChange={(e) =>
                setFormData({ ...formData, deviceName: e.target.value })
              }
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
               {t("Device ID")}
            </label>
            <input
              type="text"
              className="w-full border rounded-md px-3 py-2"
              value={formData.deviceId}
              onChange={(e) =>
                setFormData({ ...formData, deviceId: e.target.value })
              }
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              <span className="text-red-500">*</span> {t("Mqtt Server")}
            </label>
            <select
              className="w-full border rounded-md px-3 py-2"
              value={formData.mqttServer}
              onChange={(e) =>
                setFormData({ ...formData, mqttServer: e.target.value })
              }
            >
              <option value="">{t("Select type")}</option>
              {listMqttServer.map((server: any) => {
                return (
                  <option key={server?._id} value={server?._id}>
                    {server?.name}
                  </option>
                );
              })}

              {/* <option value="Serial">Serial</option>
              <option value="Ethernet">Ethernet</option> */}
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              <span className="text-red-500">*</span> {t("Number Of Chanel")}
            </label>
            <input
              type="number"
              className="w-full border rounded-md px-3 py-2"
              value={formData.noOfChanel}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  noOfChanel: parseInt(e.target.value),
                })
              }
            />
          </div>

          <div className="flex justify-end mt-6">
            <button
              onClick={() => onConfirm(formData)}
              className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
            >
              {loading?<RefreshCw className="w-6 h-6 animate-spin mx-auto mb-2" />:t("Confirm")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const AddWebSharePdk: React.FC<AddModalProps> = ({
  isOpen,
  onClose,
  onAdded,  
}) => {
  const [formData, setFormData] = useState({
    deviceType: "Websharepdk",
    deviceName: "",
    noOfChanel: 1,
    mqttServer: "",
    deviceId:"",
  });
  const [listMqttServer, setListMqttServer] = useState([]);
  const [loading, setLoading] = useState(false);
  const {admin}=useStateContext();
  const {t}=useTranslation();
  const onConfirm = async (formData: any) => {
    if (loading) {
      return;
    }
    setLoading(true);
    const res = await ApiClient.getInstance().post("/waveshareRelay/addEditRelay", {
      name: formData?.deviceName,
      deviceId: formData?.deviceId??'',
      noOfChannel: formData?.noOfChanel,
      mqttServer: formData?.mqttServer,
      createdBy: admin?._id,      
    });
    console.log(res);
    if (res.isSuccess) {
      setFormData({
        deviceId:"",
        deviceName:"",
        mqttServer:"",
        noOfChanel:1,
        deviceType: "Websharepdk",
      })
      toast.success(t("Added successfully"));
      onAdded();
    } else {
      toast.error(t("Something went wrong! Please try again later"));
    }
    setLoading(false);
  };
  const getAllMqttServers = async () => {
    const res = await ApiClient.getInstance().get(`/mqttServer/getAllServers`);
    if (res.isSuccess) {
      setListMqttServer(res.data);      
    }
  };
  useEffect(() => {
    getAllMqttServers();
  }, []);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg w-full max-w-md p-6 relative">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">{t("Add")}</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              <span className="text-red-500">*</span> {t("Device Type")}
            </label>
            <select
              className="w-full border rounded-md px-3 py-2"
              value={formData.deviceType}
              onChange={(e) =>
                setFormData({ ...formData, deviceType: e.target.value })
              }
            >
              {/* <option value="">Select type</option> */}
              <option value="Websharepdk">{t("WaveShare")}</option>
              {/* <option value="Serial">Serial</option> */}
              {/* <option value="Ethernet">Ethernet</option> */}
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              <span className="text-red-500">*</span> {t("Device name")}
            </label>
            <input
              type="text"
              className="w-full border rounded-md px-3 py-2"
              value={formData.deviceName}
              onChange={(e) =>
                setFormData({ ...formData, deviceName: e.target.value })
              }
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
               {t("Device ID")}
            </label>
            <input
              type="text"
              className="w-full border rounded-md px-3 py-2"
              value={formData.deviceId}
              onChange={(e) =>
                setFormData({ ...formData, deviceId: e.target.value })
              }
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              <span className="text-red-500">*</span> {t("Mqtt Server")}
            </label>
            <select
              className="w-full border rounded-md px-3 py-2"
              value={formData.mqttServer}
              onChange={(e) =>
                setFormData({ ...formData, mqttServer: e.target.value })
              }
            >
              <option value="">{t("Select type")}</option>
              {listMqttServer.map((server: any) => {
                return (
                  <option key={server?._id} value={server?._id}>
                    {server?.name}
                  </option>
                );
              })}

              {/* <option value="Serial">Serial</option>
              <option value="Ethernet">Ethernet</option> */}
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              <span className="text-red-500">*</span> {t("Number Of Chanel")}
            </label>
            <input
              type="number"
              className="w-full border rounded-md px-3 py-2"
              value={formData.noOfChanel}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  noOfChanel: parseInt(e.target.value),
                })
              }
            />
          </div>

          <div className="flex justify-end mt-6">
            <button
              onClick={() => onConfirm(formData)}
              className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
            >
              {loading?<RefreshCw className="w-6 h-6 animate-spin mx-auto mb-2" />:t("Confirm")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

// export const SubscriptionModal: React.FC<SubscriptionModalProps> = ({ isOpen, onClose, deviceId, onConfirm }) => {
//     const [formData, setFormData] = useState({
//       mqttPath: 'mqtt.waveshare.cloud',
//       port: '1883',
//       clientId: 'b2469197',
//       pubTopic: 'Pub/396/9/b2469197',
//       subTopic: 'Sub/396/9/b2469197'
//     });

//     if (!isOpen) return null;

//     return (
//       <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
//         <div className="bg-white rounded-lg w-full max-w-md p-6 relative">
//           <div className="flex justify-between items-center mb-4">
//             <h2 className="text-xl font-semibold">Subscription Addresses</h2>
//             <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
//               <X className="w-5 h-5" />
//             </button>
//           </div>

//           <div className="space-y-4">
//             <div>
//               <div className="flex items-center mb-1">
//                 <label className="block text-sm font-medium text-gray-700">MqttPath</label>
//                 <HelpCircle className="w-4 h-4 ml-1 text-gray-400" />
//               </div>
//               <div className="relative">
//                 <input
//                   type="text"
//                   className="w-full border rounded-md px-3 py-2 pr-8"
//                   value={formData.mqttPath}
//                   onChange={(e) => setFormData({ ...formData, mqttPath: e.target.value })}
//                 />
//                 <button className="absolute right-2 top-2 text-gray-400">
//                   <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
//                     <path d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" />
//                   </svg>
//                 </button>
//               </div>
//             </div>

//             {[
//               { label: 'Port', key: 'port' },
//               { label: 'Client ID', key: 'clientId' },
//               { label: 'Pub Topic', key: 'pubTopic' },
//               { label: 'Sub Topic', key: 'subTopic' }
//             ].map((field) => (
//               <div key={field.key}>
//                 <div className="flex items-center mb-1">
//                   <label className="block text-sm font-medium text-gray-700">{field.label}</label>
//                   <HelpCircle className="w-4 h-4 ml-1 text-gray-400" />
//                 </div>
//                 <div className="relative">
//                   <input
//                     type="text"
//                     className="w-full border rounded-md px-3 py-2 pr-8"
//                     value={formData[field.key as keyof typeof formData]}
//                     onChange={(e) => setFormData({ ...formData, [field.key]: e.target.value })}
//                   />
//                   <button className="absolute right-2 top-2 text-gray-400">
//                     <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
//                       <path d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" />
//                     </svg>
//                   </button>
//                 </div>
//               </div>
//             ))}

//             <div className="flex justify-end mt-6">
//               <button
//                 onClick={() => onConfirm(formData)}
//                 className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
//               >
//                 Confirm
//               </button>
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   };

// import React, { useState } from 'react';
// import { X, HelpCircle, Copy, Eye, EyeOff } from 'lucide-react';
// import { toast } from '@/components/ui/use-toast';

export const SubscriptionModal: React.FC<SubscriptionModalProps> = ({
  isOpen,
  onClose,
  device,  
}) => {
  const [formData, setFormData] = useState({
    mqttPath: device?.mqttPubSub?.mqttServer?.url,
    port: "1883",
    clientId: "b2469197",
    pubTopic: device?.mqttPubSub?.pubTopic,
    subTopic: device?.mqttPubSub?.subTopic,
    username: device?.mqttPubSub?.mqttServer?.username,
    password: device?.mqttPubSub?.mqttServer?.password,
  });

  const [showPassword, setShowPassword] = useState(false);
const {t}=useTranslation();
  useEffect(()=>{
setFormData({
  mqttPath: device?.mqttPubSub?.mqttServer?.url,
    port: "1883",
    clientId: "b2469197",
    pubTopic: device?.mqttPubSub?.pubTopic,
    subTopic: device?.mqttPubSub?.subTopic,
    username: device?.mqttPubSub?.mqttServer?.username,
    password: device?.mqttPubSub?.mqttServer?.password,
})
  },[device])
  const handleCopy = (value: string, field: string) => {
    navigator.clipboard.writeText(value);
    toast.success(t("copied successfully"))
  };

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg w-full max-w-md p-6 relative">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">{t("Subscription Addresses")}</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        <div className="space-y-4">
          {/* Existing fields */}
          {[
            { label: t("MqttPath"), key: "mqttPath" },
            // { label: "Client ID", key: "clientId" },
            { label: t("Pub Topic"), key: "pubTopic" },
            { label: t("Sub Topic"), key: "subTopic" },
            { label: t("Username"), key: "username" },
            { label: t("Password"), key: "password" },
          ].map((field) =>
            field.key == "password" ? (
              <div>
                <div className="flex items-center mb-1">
                  <label className="block text-sm font-medium text-gray-700">
                    {t("Password")}
                  </label>
                  <HelpCircle className="w-4 h-4 ml-1 text-gray-400" />
                </div>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="w-full border rounded-md px-3 py-2 pr-16"
                    value={formData.password}
                    readOnly
                    onChange={(e) =>
                      setFormData({ ...formData, password: e.target.value })
                    }
                  />
                  <div className="absolute right-2 top-2 flex space-x-2">
                    <button
                      className="text-gray-400 hover:text-gray-600"
                      onClick={togglePassword}
                    >
                      {showPassword ? (
                        <EyeOff className="w-4 h-4" />
                      ) : (
                        <Eye className="w-4 h-4" />
                      )}
                    </button>
                    <button
                      className="text-gray-400 hover:text-gray-600"
                      onClick={() => handleCopy(formData.password, "Password")}
                    >
                      <Copy className="w-4 h-4" />
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div key={field.key}>
                <div className="flex items-center mb-1">
                  <label className="block text-sm font-medium text-gray-700">
                    {field.label}
                  </label>
                  <HelpCircle className="w-4 h-4 ml-1 text-gray-400" />
                </div>
                <div className="relative">
                  <input
                    type="text"
                    readOnly
                    className="w-full border rounded-md px-3 py-2 pr-16"
                    value={formData[field.key as keyof typeof formData]}
                    onChange={(e) =>
                      setFormData({ ...formData, [field.key]: e.target.value })
                    }
                  />
                  <button
                    className="absolute right-2 top-2 text-gray-400 hover:text-gray-600"
                    onClick={() =>
                      handleCopy(
                        formData[field.key as keyof typeof formData],
                        field.label
                      )
                    }
                  >
                    <Copy className="w-4 h-4" />
                  </button>
                </div>
              </div>
            )
          )}
          {/* Password field */}

          {/* <div className="flex justify-end mt-6">
            <button
              onClick={() => onConfirm(formData)}
              className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
            >
              Confirm
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionModal;
