import React, { useState, useEffect } from "react";
import {
  PlusCircle,
  RefreshCw,
  Pencil,
  Trash2,
  Database,
  LayoutGrid,
} from "lucide-react";
import {
  AddWebSharePdk,
  EditWebSharePdk,
  SubscriptionModal,
} from "../../../components/EditWebSharePdk";
import ApiClient from "../../../Api/apiClient";
import toast from "react-hot-toast";
import { useStateContext } from "../../../context/ContextProvider";
import { validateObject } from "../../../Utils/Utils";
import LockConfigPopup from "../../../components/LockAddEdit/InterbrasEditAdd";
import { useTranslation } from "react-i18next";

interface Device {
  // id: number;
  // deviceType: string;
  // deviceName: string;
  // description: string;
  // onlineStatus: "active" | "notactivated" | "offline";
  // lastUpTime: string;
  _id: string;
  name: string;
  deviceId: string;
  noOfChannel: number;
  isActive: boolean;
  createdBy: string;
  createdAt: string;
  updatedAt: string;
  mqttPubSub: string;
  providerName?: string;
}
export enum IntelbrasAuthType {
  // Digest = "Digest",
  None = "None",
  Basic = "Basic",
}

const WebSharePdk = () => {
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [devices, setDevices] = useState<Device[]>([]);
  const [showSubscriptionModel, setShowSubscriptionModel] = useState(false);
  const [showEditModel, setShowEditModel] = useState(false);
  const [showAddModel, setShowAddModel] = useState(false);
  const [deviceToEdit, setDeviceToEdit] = useState({});
  const [deviceToSubscription, setDeviceToSubscription] = useState({});
  const { admin } = useStateContext();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const { t } = useTranslation();
  const [interbrasDeviceToEdit, setInterbrasDDeviceToEdit]: any = useState({});
  // const [IntelbrasToEdit, setIntelbrasToEdit] = useState({});
  // const [IntelbrasEdit, setIntelbrasEdit] = useState(false);
  const [missingFieldsForIntelbrasLock, setMissingFieldsForIntelbrasLock]: any =
    useState([]);

  const hasWaveshare = admin?.lockCredentials?.some(
    (item: any) => item.name == "WaveShare"
  );
  const hasInterbred = admin?.lockCredentials?.some(
    (item: any) => item.name == "Intelbras"
  );
  // Different lock configurations
  const IntelbrasLockFields = [
    {
      key: "name",
      label: t("Lock Name"),
      helpText: t("Enter Lock Name"),
    },
    {
      key: "authType",
      label: t("Select Auth Type"),
      type: "select",
      options: ["None", "Basic"],
      helpText: t("Select an option"),
    },
    {
      key: "ipAddress",
      label: t("IP Address"),
      helpText: t("Enter Lock IP Address"),
    },
    {
      key: "username",
      label: t("Username"),
      helpText: t("Enter username"),
    },
    {
      key: "password",
      label: t("Password"),
      type: "password",
      helpText: t("Enter password"),
    },
  ];
  // Simulated API data

  // Simulate API call
  useEffect(() => {
    const fetchDevices = async () => {
      setLoading(true);
      try {
        const res = await ApiClient.getInstance().get(
          `/waveshareRelay/getRelayForAdmin/${admin?._id}/isChannelDetailsRequired/0`
        );
        const res2 = await ApiClient.getInstance().get(
          `/interbras/getForAdmin/${admin?._id}/isChannelDetailsRequired/0`
        );
        if (res2 && res2.isSuccess && res2.data) {
          res2.data.forEach((eachIntelbras: any) => {
            eachIntelbras.type = "intelbras";
          });
        }
        if (res && res.isSuccess && res.data) {
          res.data.forEach((eachWaveshare: any) => {
            eachWaveshare.type = "waveshare";
          });
        }
        if (res?.isSuccess && res2?.isSuccess) {
          const updatedRes2Data =
            res2.data?.length > 0
              ? res2.data.map((item: any) => ({
                  ...item, // Spread the existing properties
                  providerName: "interbras", // Add the new property
                }))
              : [];

          // Combine the arrays
          const totalDevices = res.data.concat(updatedRes2Data);
          setDevices(totalDevices);
        } else {
          toast.error(t("Something went wrong! Please try again later"));
        }
      } catch (error) {
        console.error("Error fetching devices:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDevices();
  }, []);

  const getStatusColor = (status: boolean) => {
    if (status) {
      return "bg-green-500";
    } else {
      return "bg-red-500";
    }
  };

  // Calculate pagination
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedDevices = devices.slice(startIndex, endIndex);
  const totalPages = Math.ceil(devices.length / itemsPerPage);

  // Handle refresh
  const handleRefresh = async () => {
    setLoading(true);
    try {
      const res = await ApiClient.getInstance().get(
        `/waveshareRelay/getRelayForAdmin/${admin?._id}/isChannelDetailsRequired/0`
      );
      const res2 = await ApiClient.getInstance().get(
        `/interbras/getForAdmin/${admin?._id}/isChannelDetailsRequired/0`
      );
      if (res2 && res2.isSuccess && res2.data) {
        res2.data.forEach((eachIntelbras: any) => {
          eachIntelbras.type = "intelbras";
        });
      }
      if (res && res.isSuccess && res.data) {
        res.data.forEach((eachWaveshare: any) => {
          eachWaveshare.type = "waveshare";
        });
      }
      console.log("res2", res2);
      if (res?.isSuccess && res2?.isSuccess) {
        const updatedRes2Data =
          res2.data?.length > 0
            ? res2.data.map((item: any) => ({
                ...item, // Spread the existing properties
                providerName: "interbras", // Add the new property
              }))
            : [];

        // Combine the arrays
        const totalDevices = res.data.concat(updatedRes2Data);
        setDevices(totalDevices);
      } else {
        toast.error(t("Something went wrong! Please try again later"));
      }
    } finally {
      setLoading(false);
    }
  };
  const handleSave = async (values: any) => {
    const { name, ipAddress, username, password, authType } = values;
    if (name == "" || ipAddress == "" || username == "" || password == "") {
      setMissingFieldsForIntelbrasLock(["all fields Required"]);
      return false;
    }
    const validationResult = validateObject(values);
    // Check if there are any missing fields or validation errors
    if (validationResult !== true) {
      if (validationResult == false) {
        setMissingFieldsForIntelbrasLock(["all fields are "]);
      } else {
        setMissingFieldsForIntelbrasLock(validationResult);
      }
      return false;
    }
    // const data={name:"test",ipAddress:"189.26.24.197",noOfChannel:2,authType:"Basic",createdBy:"6731e5d9f6dd2fb930f32cc8",username:"admin",password:"123456aB"}
    try {
      const res = await ApiClient.getInstance().post(`/interbras/addEdit`, {
        name: name,
        ipAddress: ipAddress,
        authType: authType,
        createdBy: admin._id,
        username: username,
        password: password,
      });
      console.log("response", res);
      if (res?.isSuccess) {
        toast.success(t("Lock Added successfully"));
        setIsPopupOpen(false);
        return true;
      } else if (res?.statusCode == 400) {
        toast.error(t("Intelbras Device not found."));
        setIsPopupOpen(false);
        return true;
      } else {
        toast.error(t("Something went wrong! Please try again later."));
        setIsPopupOpen(false);
        return true;
      }
    } catch (error) {
      toast.error(t("An unexpected error occurred. Please try again."));
      setIsPopupOpen(false);
      return true;
    }
  };
  const handleSaveEdit = async (values: any) => {
    const { name, ipAddress, username, password, authType } = values;
    if (name == "" || ipAddress == "" || username == "" || password == "") {
      setMissingFieldsForIntelbrasLock(["all fields Required"]);
      return false;
    }
    const validationResult = validateObject(values);
    // Check if there are any missing fields or validation errors
    if (validationResult !== true) {
      if (validationResult == false) {
        setMissingFieldsForIntelbrasLock(["all fields are "]);
      } else {
        setMissingFieldsForIntelbrasLock(validationResult);
      }
      return false;
    }
    // const data={name:"test",ipAddress:"189.26.24.197",noOfChannel:2,authType:"Basic",createdBy:"6731e5d9f6dd2fb930f32cc8",username:"admin",password:"123456aB"}
    try {
      const res = await ApiClient.getInstance().post(`/interbras/addEdit`, {
        _id: interbrasDeviceToEdit._id,
        name: name,
        ipAddress: ipAddress,
        authType: authType,
        createdBy: admin._id,
        username: username,
        password: password,
      });
      console.log("response", res);
      if (res?.isSuccess) {
        setInterbrasDDeviceToEdit({});
        toast.success(t("Lock Added successfully"));
        setIsEditPopupOpen(false);
        return true;
      } else if (res?.statusCode == 400) {
        setInterbrasDDeviceToEdit({});
        toast.error(t("Intelbras Device not found."));
        setIsEditPopupOpen(false);
        return true;
      } else {
        setInterbrasDDeviceToEdit({});
        toast.error(t("Something went wrong! Please try again later."));
        setIsEditPopupOpen(false);
        return true;
      }
    } catch (error) {
      setInterbrasDDeviceToEdit({});
      toast.error(t("An unexpected error occurred. Please try again."));
      setIsEditPopupOpen(false);
      return true;
    }
  };

  return (
    <div className="h-[90vh] flex flex-col">
      <SubscriptionModal
        isOpen={showSubscriptionModel}
        onClose={() => {
          setShowSubscriptionModel(false);
        }}
        device={deviceToSubscription}
        onConfirm={function (data: any): void {
          throw new Error("Function not implemented.");
        }}
      />
      <EditWebSharePdk
        isOpen={showEditModel}
        onClose={() => {
          setShowEditModel(false);
        }}
        onAdded={() => {
          setShowEditModel(false);
          handleRefresh();
        }}
        device={deviceToEdit}
      />
      <LockConfigPopup
        isOpen={isPopupOpen}
        onClose={() => {
          setIsPopupOpen(false);
          setMissingFieldsForIntelbrasLock([]);
        }}
        onSave={handleSave}
        title={t("Add Intelbras")}
        fields={IntelbrasLockFields}
        missingFieldsForIntelbrasLock={missingFieldsForIntelbrasLock}
        initialData={{
          name: "",
          ipAddress: "",
          username: "",
          password: "",
        }}
      />
      <LockConfigPopup
        isOpen={isEditPopupOpen}
        onClose={() => {
          setIsEditPopupOpen(false);
          setMissingFieldsForIntelbrasLock([]);
          setInterbrasDDeviceToEdit({});
        }}
        onSave={handleSaveEdit}
        title={t("Edit Intelbras")}
        fields={IntelbrasLockFields}
        missingFieldsForIntelbrasLock={missingFieldsForIntelbrasLock}
        initialData={{
          name: interbrasDeviceToEdit?.name ?? "lock name",
          ipAddress: interbrasDeviceToEdit.ipAddress,
          username: interbrasDeviceToEdit.username,
          password: interbrasDeviceToEdit.password,
          authType: interbrasDeviceToEdit.authType,
        }}
      />
      <AddWebSharePdk
        isOpen={showAddModel}
        onClose={() => {
          setShowAddModel(false);
        }}
        onAdded={() => {
          setShowAddModel(false);
          handleRefresh();
        }}
      />
      <div className="flex-1 p-4 flex flex-col">
        <div className="bg-white rounded-lg shadow flex flex-col flex-1">
          {/* Header Actions */}
          <div className="p-4 flex flex-wrap gap-2 border-b">
            {hasWaveshare && (
              <button
                className="flex items-center gap-2 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                onClick={() => {
                  setShowAddModel(true);
                }}
              >
                <PlusCircle className="w-4 h-4" />
                {t("Add New WebShare/PDK")}
              </button>
            )}
            {hasInterbred && (
              <button
                className="flex items-center gap-2 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                onClick={() => {
                  setIsPopupOpen(true);
                }}
              >
                <PlusCircle className="w-4 h-4" />
                {t("Add New WebShare/PDK")}
              </button>
            )}
            <button
              className="flex items-center gap-2 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
              onClick={handleRefresh}
              disabled={loading}
            >
              <RefreshCw
                className={`w-4 h-4 ${loading ? "animate-spin" : ""}`}
              />
              {t("Refresh")}
            </button>
          </div>

          {/* Table Container with Scroll */}
          <div className="flex-1 overflow-auto">
            <table className="w-full">
              <thead className="bg-gray-50 sticky top-0">
                <tr>
                  <th className="px-4 py-3 text-left text-sm font-medium text-gray-500">
                    {t("Device Id")}
                  </th>
                  {/* <th className="px-4 py-3 text-left text-sm font-medium text-gray-500">
                    Device Type
                  </th> */}
                  <th className="px-4 py-3 text-left text-sm font-medium text-gray-500">
                    {t("Device name")}
                  </th>
                  <th className="px-4 py-3 text-left text-sm font-medium text-gray-500">
                    {t("Online status")}
                  </th>
                  <th className="px-4 py-3 text-left text-sm font-medium text-gray-500">
                    {t("Created at")}
                  </th>
                  <th className="px-4 py-3 text-left text-sm font-medium text-gray-500">
                    {t("Operation")}
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {loading ? (
                  <tr>
                    <td
                      colSpan={7}
                      className="px-4 py-8 text-center text-gray-500"
                    >
                      <RefreshCw className="w-6 h-6 animate-spin mx-auto mb-2" />
                      {t("Loading devices...")}
                    </td>
                  </tr>
                ) : paginatedDevices.length === 0 ? (
                  <tr>
                    <td
                      colSpan={7}
                      className="px-4 py-8 text-center text-gray-500"
                    >
                      {t("No devices found")}
                    </td>
                  </tr>
                ) : (
                  paginatedDevices.map((device: any) => (
                    <tr key={device._id} className="hover:bg-gray-50">
                      <td className="px-4 py-3 text-sm">
                        {device.deviceId ?? "--"}
                      </td>
                      {/* <td className="px-4 py-3">
                        <span className="px-2 py-1 text-xs font-medium bg-blue-50 text-blue-600 rounded-full">
                          {"Wave Share PDK"}
                        </span>
                      </td> */}
                      <td className="px-4 py-3 text-sm">{device.name}</td>
                      <td className="px-4 py-3">
                        <span
                          className={`px-2 py-1 text-xs font-medium text-white rounded-full ${getStatusColor(
                            device.isActive
                          )}`}
                        >
                          {device.isActive ? t("Online status") : "Offline"}
                        </span>
                      </td>
                      <td className="px-4 py-3 text-sm">{device.createdAt}</td>
                      <td className="px-4 py-3">
                        <div className="flex gap-2">
                          <button
                            className="p-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 cursor-pointer"
                            onClick={() => {
                              if (device.providerName != "interbras") {
                                setShowEditModel(true);
                                setDeviceToEdit(device);
                              } else {
                                console.log(device);
                                setIsEditPopupOpen(true);
                                setInterbrasDDeviceToEdit(device);
                              }
                            }}
                          >
                            <Pencil className="h-4 w-4" />
                          </button>
                          {/* <button className="p-2 bg-green-500 text-white rounded-md hover:bg-green-600">
                            <Database className="h-4 w-4" />
                          </button> */}

                          {device.type && device.type === "waveshare" ? (
                            // {device.providerName!="interbras"&&
                            <button
                              className="p-2 bg-amber-500 text-white rounded-md hover:bg-amber-600 cursor-pointer"
                              onClick={() => {
                                setShowSubscriptionModel(true);
                                setDeviceToSubscription(device);
                              }}
                            >
                              <LayoutGrid className="h-4 w-4" />
                            </button>
                          ) : null}
                          {/* <button className="p-2 bg-red-500 text-white rounded-md hover:bg-red-600">
                            <Trash2 className="h-4 w-4" />
                          </button> */}
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>

          {/* Pagination - Now sticky to bottom */}
          <div className="p-4 flex items-center justify-between border-t bg-white">
            <div className="flex items-center gap-2">
              <span className="text-sm text-gray-500">
                {t("Total")} {devices.length}
              </span>
              <select
                className="border rounded px-2 py-1 text-sm"
                value={itemsPerPage}
                onChange={(e) => {
                  setItemsPerPage(Number(e.target.value));
                  setCurrentPage(1); // Reset to first page when changing items per page
                }}
              >
                <option value={20}>{t("20/page")}</option>
                <option value={50}>{t("50/page")}</option>
                <option value={100}>{t("100/page")}</option>
              </select>
            </div>
            <div className="flex items-center gap-2">
              <button
                className="px-3 py-1 text-sm border rounded-md hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                onClick={() => setCurrentPage((p) => Math.max(1, p - 1))}
                disabled={currentPage === 1 || loading}
              >
                {t("Previous")}
              </button>
              <span className="px-4 py-1 text-sm">
                {t("Page")} {currentPage} of {totalPages || 1}
              </span>
              <button
                className="px-3 py-1 text-sm border rounded-md hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                onClick={() =>
                  setCurrentPage((p) => Math.min(totalPages, p + 1))
                }
                disabled={currentPage === totalPages || loading}
              >
                {t("Next")}
              </button>
              <div className="flex items-center gap-2 ml-4">
                <span className="text-sm">{t("Go to")}</span>
                <input
                  type="number"
                  className="border rounded w-16 px-2 py-1 text-sm"
                  min={1}
                  max={totalPages}
                  value={currentPage}
                  onChange={(e) => {
                    const value = Number(e.target.value);
                    if (value >= 1 && value <= totalPages) {
                      setCurrentPage(value);
                    }
                  }}
                  disabled={loading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebSharePdk;
