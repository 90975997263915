import React, { Component } from "react";
import { useNavigate } from "react-router-dom";
import AparmetsView from "./AparmetsView";
import {
  LogOutAdmin,
  getUserToken,
  validateObject,
} from "../../../Globals/Functions";
import { StateContext } from "../../../context/ContextProvider";
import {
  addApartmentApi,
  assignAdminToApartmentApi,
  deleteApartmentsByIdApi,
  genrateQrCodeApi,
  getApartmentsApi,
  removeApartmentLogoApi,
} from "./api";
import Loading_Page from "../../../Neet-Components/Loading-Page/Loading_Page";
import { AdminPrivileges } from "../../../constants";
import { SelectChangeEvent } from "@mui/material";
import { getBuildingDetailsWithTypeName } from "../../../data/BuildingTypes";
import toast from "react-hot-toast";
import axios from "axios";
import { useTranslation } from "react-i18next";

interface Props {
  navigate: any; 
  isShowDropdown?: boolean;
  t: any;
}
interface State {
  apartmentToAdd: {
    address: string;
    name: string;
    location: string;
    phoneNumber: string;
    website: string;
    latitude: string;
    longitude: string;
    logo: string;
    shouldAutoApproveJoinRequest: boolean;
  };
  apartmentToEdit: {
    address: string;
    name: string;
    location: string;
    phoneNumber: string;
    website: string;
    _id: string;
    latitude: string;
    longitude: string;
    logo: string;
    shouldAutoApproveJoinRequest: boolean;
  };
  authToken: string;
  loading: string;
  isShowAddApartment: boolean;
  isShowEditApartment: boolean;
  isShowDeleteApartment: boolean;
  apartments: Array<Object>;
  isShowMap: boolean;
  isAddApartment: boolean;
  genrateQrLoading: boolean;
  apartmentToGenrateQrCode: string;
  apartmentType: any;
  editHasLogo: boolean;
  linkGenrateLoading: boolean;
  genratedLink: string;
  showLinkCopyPopup: boolean;
}
enum loadingEnu {
  PAGELOADING = "PAGELOADING",
  ADDBUTTONLOADING = "ADDBUTTONLOADING",
  EDITLOADING = "EDITLOADING",
  DELETELOADING = "DELETELOADING",
  NONE = "NONE",
  REMOVELOGO = "REMOVELOGO",
}
export class AparmetsComponentWrap extends Component<Props, State> {
  static contextType: any = StateContext; // Assign StateContext to static contextType property
  constructor(props: Props) {
    super(props);
    this.state = {
      apartmentToAdd: {
        name: "",
        address: "",
        location: "",
        phoneNumber: "",
        website: "",
        shouldAutoApproveJoinRequest: false,
        latitude: "",
        longitude: "",
        logo: "",
      },
      apartmentToEdit: {
        name: "",
        address: "",
        location: "",
        phoneNumber: "",
        website: "",
        _id: "",
        latitude: "",
        longitude: "",
        logo: "",
        shouldAutoApproveJoinRequest: false,
      },
      authToken: "",
      loading: loadingEnu.PAGELOADING,
      isShowAddApartment: false,
      isShowEditApartment: false,
      isShowDeleteApartment: false,
      isShowMap: false,
      isAddApartment: true,
      genrateQrLoading: false,
      apartmentToGenrateQrCode: "",
      apartmentType: {},
      editHasLogo: false,
      linkGenrateLoading: false,
      genratedLink: "",
      showLinkCopyPopup: false,
      apartments: [
        {
          _id: "663a13d707f051c7b8cdb43a",
          accessGivenBy: "6639c979efb6fe9a4a685d1f",
          admin: "6639c979efb6fe9a4a685d1f",
          apartment: {
            _id: "663a115d07f051c7b8cdb42b",
            address: "dfdf",
            createdAt: "2024-05-07T11:32:45.607Z",
            createdBy: "6639c979efb6fe9a4a685d1f",
            location: "dfdsf",
            name: "Nmae",
            phoneNumber: "1234567891",
            updatedAt: "2024-05-07T11:32:45.607Z",
            website: "www.com.com",
          },
          privileges: ["CanAddAdmin", "CanAddApartments"],
        },
      ],
    };
  }
  componentDidMount(): void {
    const { setIsUserLogedIn, subdivisionTreeNavigaitonStack }: any =
      this.context;
    if (
      !subdivisionTreeNavigaitonStack.includes({ name: "Home", id: "none" })
    ) {
      subdivisionTreeNavigaitonStack.push({ name: "Home", id: "none" });
    }
    const auth_Token = getUserToken();
    if (auth_Token) {
      this.setState({ authToken: auth_Token });
      this.getApartments(auth_Token);
    } else {
      LogOutAdmin(this.props.navigate, setIsUserLogedIn);
    }
  }
  onCopyButtonPressed = async () => {
    await navigator.clipboard.writeText(this.state.genratedLink);
    toast(this.props.t("link copied"));
  };
  onShareButtonPressed = async (
    aptName: string,
    aptType: string,
    aptId: string
  ) => {
    this.setState({ linkGenrateLoading: true, showLinkCopyPopup: true });
    // setLinkLoading(true);
    // "appName": "DoorVi",
    // "appShortName": "DVI",
    // "webUrl": "https://play.google.com/store/apps/details?id=com.doorvi",
    // "iOSPackage": "com.doorvi",
    // "androidPackage": "com.doorvi",
    // "shortLinkData": {
    //   "app_id": "AIzaSyAZobD4J-psemk0NBrgO_w6hiEqrsEbxvM",
    //   "baseDomain": "https://app.doorvi.co/refer",
    //   "provider": "firebase",
    //   "domainUriPrefix": "https://app.doorvi.co",
    //   "knowMoreLink": "https://www.doorvi.co/refer-earn",
    //   "appStoreId": "1634023696"
    // },

    try {
      let data = JSON.stringify({
        apartmentName: aptName,
        apartmentType: aptType,
        aptId: aptId,
        type: "apartmentSearch",
      });
      const response = await axios.post(
        `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyAZobD4J-psemk0NBrgO_w6hiEqrsEbxvM`,
        {
          dynamicLinkInfo: {
            link: `https://app.doorvi.co/refer/?data=${data}`,
            domainUriPrefix: "https://app.doorvi.co",
            androidInfo: {
              androidPackageName: "com.doorvi",
            },
            iosInfo: {
              iosBundleId: "com.doorvi",
              iosAppStoreId: "1634023696",
            },
          },
          suffix: {
            option: "SHORT",
          },
        }
      );

      console.log("Short link:", response.data.shortLink);
      // setLink(response.data.shortLink);
      this.setState({ genratedLink: response.data.shortLink });
    } catch (error: any) {
      console.error(
        "Error creating dynamic link:",
        error.response.data.error.message
      );
      // return null;
    }
    // setLinkLoading(false);
    this.setState({ linkGenrateLoading: false });
  };
  onQrButtonPressed = async (apartment: any) => {
    console.log(apartment);
    // return
    console.log(apartment);
    // return;
    if (apartment?.apartment?.qrCode) {
      // this.props.navigate(
      //   `/dashboard/showQrCode/${apartment.apartment.qrCode}`
      // );
      window.open(`/showQrCode/${apartment.apartment.qrCode}`, "_blank");
    } else {
      this.setState({
        genrateQrLoading: true,
        apartmentToGenrateQrCode: apartment.apartment._id,
      });
      const res = await genrateQrCodeApi(
        { type: "apartment", apartmentSubdivisionFlat: apartment.apartment },
        this.state.authToken
      );
      console.log(res.data._id);
      if (res.isSuccess && res.statusCode == 200) {
        const updatedApartment = { ...apartment.apartment };
        updatedApartment.qrCode = res.data[0]?._id;
        const addResponse = await addApartmentApi(
          updatedApartment,
          this.state.authToken
        );
        console.log(addResponse);
        if (addResponse) {
          const resForGetingAparment = await getApartmentsApi(
            this.state.authToken
          );
          console.log("resForGetingAparment", resForGetingAparment);
          if (
            resForGetingAparment.isSuccess &&
            resForGetingAparment.statusCode == 200
          ) {
            this.setState({ apartments: resForGetingAparment.data });
            if (addResponse?.data?.qrCode) {
              window.open(`/showQrCode/${addResponse?.data?.qrCode}`, "_blank");
            } else {
              toast("qr code created successfully");
            }
          } else {
            toast("Something went wrong!");
            console.log(
              "errorr while refreshing list of aparment after deleting"
            );
          }
        }
      } else {
        toast("something went wrong!");
      }
      this.setState({ genrateQrLoading: false, apartmentToGenrateQrCode: "" });
    }
  };
  onShowMapButtonPressed = () => {
    this.setState({ isShowMap: !this.state.isShowMap });
  };
  mapSubmitButtonPressed = (selectedPlace: any) => {
    console.log(selectedPlace);
    this.onShowMapButtonPressed();
    const updatedApartmentToAdd = { ...this.state.apartmentToAdd };
    updatedApartmentToAdd.location = selectedPlace?.name || "";
    updatedApartmentToAdd.latitude = selectedPlace?.lat || "";
    updatedApartmentToAdd.longitude = selectedPlace?.lng || "";
    console.log(updatedApartmentToAdd);

    this.setState({ apartmentToAdd: updatedApartmentToAdd });
  };
  mapSubmitButtonPressedForEdit = (selectedPlace: any) => {
    console.log(selectedPlace);
    this.onShowMapButtonPressed();
    const updatedApartmentToAdd = { ...this.state.apartmentToEdit };
    updatedApartmentToAdd.location = selectedPlace?.name || "";
    updatedApartmentToAdd.latitude = selectedPlace?.lat || "";
    updatedApartmentToAdd.longitude = selectedPlace?.lng || "";
    this.setState({ apartmentToEdit: updatedApartmentToAdd });
  };

  mapCancelButtonPressed = () => {
    this.onShowMapButtonPressed();
  };
  onShowEditApartmentWithValue = (apartment: any) => {
    console.log("apartment to edit", apartment);
    console.log(
      getBuildingDetailsWithTypeName(
        apartment?.apartment?.apartmentType || "Apartment"
      )
    );
    this.setState({
      apartmentToEdit: {
        _id: apartment.apartment._id,
        name: apartment.name,
        address: apartment.address,
        location: apartment.location,
        phoneNumber: apartment.phoneNumber,
        website: apartment.website,
        latitude: apartment.latitude,
        longitude: apartment.longitude,
        logo: apartment?.logo,
        shouldAutoApproveJoinRequest:
          apartment?.apartment.shouldAutoApproveJoinRequest ?? false,
      },
      editHasLogo: apartment.apartment.thumbLogo ? true : false,
    });
    this.setState({
      isShowEditApartment: !this.state.isShowEditApartment,
      isAddApartment: false,
      apartmentType: getBuildingDetailsWithTypeName(
        apartment?.apartment?.apartmentType || "Apartment"
      ),
    });
  };
  getApartments = async (token: string) => {
    const { setCurrentBuildingType }: any = this.context;
    this.setState({ loading: loadingEnu.PAGELOADING });
    const res = await getApartmentsApi(token);
    console.log("get aparments response", res);
    if (res.isSuccess && res.statusCode == 200) {
      this.setState({ apartments: res.data });
      // getBuildingDetailsWithTypeName(res.data[0]?.apartment.apartment.apartmentType)
      setCurrentBuildingType(
        res.data.length > 0 &&
          getBuildingDetailsWithTypeName(res.data[0]?.apartment.apartmentType)
      );
    }
    this.setState({
      loading: loadingEnu.NONE,
    });
  };
  deleteApartment = async (apartmentId: string) => {
    const { setCurrentBuildingType }: any = this.context;
    this.setState({ loading: loadingEnu.DELETELOADING });
    const res = await deleteApartmentsByIdApi(
      apartmentId,
      this.state.authToken
    );
    console.log(res);
    if (res.isSuccess && res.statusCode == 200) {
      const resForGetingAparment = await getApartmentsApi(this.state.authToken);
      console.log("resForGetingAparment", resForGetingAparment);
      if (
        resForGetingAparment.isSuccess &&
        resForGetingAparment.statusCode == 200
      ) {
        this.setState({ apartments: resForGetingAparment.data });
        setCurrentBuildingType(
          resForGetingAparment.data.length > 0 &&
            getBuildingDetailsWithTypeName(
              resForGetingAparment.data[0]?.apartment.apartmentType
            )
        );
      } else {
        console.log("errorr while refreshing list of aparment after deleting");
      }
    } else {
      console.log("errorr while deleting the apartment");
    }
    this.setState({ loading: loadingEnu.NONE, isShowEditApartment: false });
  };
  removeLogo = async (id: string) => {
    this.setState({
      loading: loadingEnu.REMOVELOGO,
    });
    const res = await removeApartmentLogoApi(id, this.state.authToken);
    if (res.isSuccess && res.statusCode === 200) {
      const resForGettingAparments = await getApartmentsApi(
        this.state.authToken
      );
      console.log(resForGettingAparments);
      if (
        resForGettingAparments.isSuccess &&
        resForGettingAparments.statusCode == 200
      ) {
        toast("logo removed successfully");
        this.setState({
          loading: loadingEnu.NONE,
        });
      } else {
        console.log("error while geting apartments after adding");
      }
    } else {
      console.log("errorr while deleting the apartment");
      toast("Something went wrong Please try again");
    }
  };
  addAparment = async () => {
    console.log(this.state.apartmentToAdd);
    const { setCurrentBuildingType, admin }: any = this.context;
    if (!this.state.apartmentType.name) {
      toast("Please select building type");
    }
    const isAllValueAreValid = validateObject(this.state.apartmentToAdd, [
      "phoneNumber",
      "website",
      "latitude",
      "longitude",
      "logo",
    ]);
    if (isAllValueAreValid === "right") {
      console.log(
        this.state.apartmentToEdit,
        "this is coming form save funtion"
      );
      // return
      this.setState({ loading: loadingEnu.ADDBUTTONLOADING });
      const res = await addApartmentApi(
        {
          ...this.state.apartmentToAdd,
          apartmentType: this.state.apartmentType?.name,
        },
        this.state.authToken
      );
      // console.log(this.state)
      console.log("added apartment", res);
      // if (res.isSuccess && res.statusCode == 200) {
      // return;
      if (res.isSuccess && res.statusCode == 200) {
        const resForGenrateQr = await genrateQrCodeApi(
          { type: "apartment", apartmentSubdivisionFlat: res?.data },
          this.state.authToken
        );
        console.log(resForGenrateQr, "genrated");
        const resForAssignApartment = await assignAdminToApartmentApi(
          {
            admin: res.data.createdBy,
            apartment: res.data._id,
            role: admin?.role ?? 100,
            privileges: [
              AdminPrivileges.CanAddAdmin,
              AdminPrivileges.CanAddApartments,
              AdminPrivileges.CanAddTowerAndFloor,
              AdminPrivileges.CanAddApartments,
              AdminPrivileges.CanEditApartments,
              AdminPrivileges.CanEditTowerAndFloor,
            ],
          },
          this.state.authToken
        );
        console.log("resForAssignApartment", resForAssignApartment);
        if (
          resForAssignApartment.isSuccess &&
          resForAssignApartment.statusCode == 200
        ) {
          const resForGettingAparments = await getApartmentsApi(
            this.state.authToken
          );
          console.log(resForGettingAparments);
          if (
            resForGettingAparments.isSuccess &&
            resForGettingAparments.statusCode == 200
          ) {
            setCurrentBuildingType(
              resForGettingAparments.data.length > 0 &&
                getBuildingDetailsWithTypeName(
                  resForGettingAparments.data[0]?.apartment.apartmentType
                )
            );
            this.onShowAddApartment();
            this.setState({
              loading: loadingEnu.NONE,
              apartmentToAdd: {
                name: "",
                address: "",
                location: "",
                phoneNumber: "",
                website: "",
                latitude: "",
                longitude: "",
                logo: "",
                shouldAutoApproveJoinRequest: false,
              },
            });
            this.setState({
              apartments: resForGettingAparments.data,
              apartmentType: {},
            });
          } else {
            console.log("error while geting apartments after adding");
          }
        } else {
          console.log("someting wrong when assingning aparment to admin");
        }
      } else {
        console.log("someting is wrong when adding apartment");
      }
    } else {
      return isAllValueAreValid;
    }
  };
  editAparment = async () => {
    const { setCurrentBuildingType, admin }: any = this.context;
    if (!this.state.apartmentType.name) {
      toast("Please select building type");
    }
    console.log(this.state.apartmentToEdit);
    const isAllValueAreValid = validateObject(this.state.apartmentToEdit, [
      "phoneNumber",
      "website",
      "latitude",
      "longitude",
      "logo",
    ]);
    // console.log(this.state.)
    // return;
    if (isAllValueAreValid === "right") {
      this.setState({ loading: loadingEnu.EDITLOADING });
      const res = await addApartmentApi(
        {
          ...this.state.apartmentToEdit,
          apartmentType: this.state.apartmentType?.name,
        },
        this.state.authToken
      );
      console.log(res);
      if (res.isSuccess && res.statusCode == 200) {
        this.onShowEditApartment();
        this.setState({
          loading: loadingEnu.NONE,
          apartmentToEdit: {
            name: "",
            address: "",
            location: "",
            phoneNumber: "",
            website: "",
            _id: "",
            latitude: "",
            longitude: "",
            logo: "",
            shouldAutoApproveJoinRequest: false,
          },
        });
        this.setState({
          apartmentType: {},
          editHasLogo: false,
        });
        this.getApartments(this.state.authToken);
      } else {
        console.log("someting is wrong when adding apartment");
      }
    } else {
      return isAllValueAreValid;
    }
  };
  onShowAddApartment = () => {
    console.log("onShowAddApartment clicked");
    this.setState({
      isShowAddApartment: !this.state.isShowAddApartment,
      isAddApartment: true,
      editHasLogo: false,
    });
  };
  onShowEditApartment = () => {
    console.log("onShowEditApartment clicked");
    this.setState({
      isShowEditApartment: !this.state.isShowEditApartment,
      isAddApartment: false,
      editHasLogo: false,
    });
    if (this.state.isShowEditApartment) {
      this.getApartments(this.state.authToken);
    }
  };
  handleRadioChange = (value: any) => {
    console.log(value, "from inptu field");
    console.log(JSON.parse(value));
    this.setState((prevState) => ({
      apartmentToAdd: {
        ...prevState.apartmentToAdd,
        shouldAutoApproveJoinRequest: JSON.parse(value),
      },
    }));
  };
  handleEditRadioChange = (value: any) => {
    console.log(value, "from inptu field");
    console.log(JSON.parse(value));
    this.setState((prevState) => ({
      apartmentToEdit: {
        ...prevState.apartmentToEdit,
        shouldAutoApproveJoinRequest: JSON.parse(value),
      },
    }));
  };
  onAddAparmentTextChange = (e: any) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      apartmentToAdd: {
        ...prevState.apartmentToAdd,
        [name]: value,
      },
    }));
  };

  onEditAparmentTextChange = (e: any) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      apartmentToEdit: {
        ...prevState.apartmentToEdit,
        [name]: value,
      },
    }));
  };
  handleChange = (event: SelectChangeEvent) => {
    this.setState({ apartmentType: event.target.value });
  };
  render() {
    return <AparmetsView controller={this as any} />;
  }
}

const AparmetsComponent = ({
  isShowDropdown,
}: {
  isShowDropdown?: boolean;
}) => {
  const navigate = useNavigate();
  const {t}=useTranslation();
  return <AparmetsComponentWrap navigate={navigate} isShowDropdown t={t} />;
};

export default AparmetsComponent;
