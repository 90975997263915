import { Component } from "react";
import { GridColDef } from "@mui/x-data-grid";
import moment from "moment";
// import moment from "moment-timezone";
import LinearIndeterminate from "../../../Neet-Components/Loading-Page/LinearLoader";
import { CallLogsWrap } from "./CallLogsComponent";
import TablePaginationMui from "../../../components/TablePagination";
import SelectApartmentOneOfAll from "../../../components/SelectApartmentOneOfAll";
import style from "../apartments/settings.module.css";
import { logsImageBaseUrl } from "../../../constants";
import { Clear, More } from "@mui/icons-material";
import { StyledDataGrid } from "../apartments/AparmetsView";
import ButtonWithIcon from "../../../Neet-Components/Buttons/ButtonWithIcon";
import { TbReload } from "react-icons/tb";
import { CgDetailsMore } from "react-icons/cg";
import MoreDetails from "../../../components/MoreDetails";

interface Props {
  controller: CallLogsWrap;
}
enum loadingEnu {
  PAGELOADING = "PAGELOADING",
  ADDBUTTONLOADING = "ADDBUTTONLOADING",
  EDITLOADING = "EDITLOADING",
  DELETELOADING = "DELETELOADING",
  NONE = "NONE",
}
export class CallLogsView extends Component<Props> {
  render() {
    const { controller } = this.props;
    const { admin, currentBuildingType, currentColor }: any =
      this.props.controller.context;
      const t=this.props.controller.props.t;
    const columns: GridColDef[] = [
      {
        field: "picture",
        headerName: t("Visitor Image"),
        width: 120,
        headerAlign: "center",
        renderCell: (index) => {
          return index.api.getRow(index.row.id)?.picture ? (
            <div
              className={style.logo_container}
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.props.controller.setState({
                  showImage: true,
                  imageUrl:
                    logsImageBaseUrl + index.api.getRow(index.row.id).picture,
                });
              }}
            >
              <img
                src={logsImageBaseUrl + index.api.getRow(index.row.id).picture}
              />
            </div>
          ) : (
            <div className={style.logo_container}>
              <p>--</p>
            </div>
          );
        },
      },
      {
        field: "name",
        headerName: t("Visitor Name"),
        width: 150,
        headerAlign: "center",
        align: "center",
      },
      { field: "calldetails", headerName: t("Call Status"), width: 150 },
      {
        headerAlign: "center",
        align: "center",
        field: "apartment",
        headerName: t("Units"),
        width: 200,
      },
      {
        field: "timestamp",
        headerName: t("Date/Time"),
        width: 200,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "manage",
        headerName: t("More Details"),
        headerAlign: "center",
        width: 150,
        align: "center",

        renderCell: (index) => {
          return (
            <div className="w-full flex items-center justify-center h-[50px]">
              <ButtonWithIcon
                buttonName={""}
                background={currentColor}
                width={"60px"}
                iconName={<CgDetailsMore size={24} />}
                onClick={() => {
                  this.props.controller.onMoreDetailsButtonPressed(
                    index.api.getRow(index.row.id)
                  );
                }}
              />
            </div>
          );
        },
      },
    ];
    const callLogsDetails: any = this.props.controller.state.setLogDetails;
    return (
      <div className="p-3 lg:px-10 lg:py-5 flex-col items-center justify-center  lg:max-w-[84vw] w-[100vw]">
        <>
          <div className="rounded-md relative">
            <ButtonWithIcon
              buttonName={t("Refresh Page")}
              background={currentColor}
              width={"200px"}
              iconName={<TbReload size={24} />}
              onClick={() => {
                this.props.controller.onPageReload();
              }}
            />
          </div>
          <div className="mt-10 rounded-md overflow-x-scroll h-[70vh] overflow-y-scroll relative">
            {this.props.controller.state.loading != loadingEnu.PAGELOADING && (
              <div className="flex gap-2 w-60 absolute z-10 right-[10px] -top-2">
                <SelectApartmentOneOfAll
                  onStatusChange={this.props.controller.onApartmentStatusChange}
                  apartments={this.props.controller.state.apartments as any}
                  value={this.props.controller.state.selectedApartment}
                />
              </div>
            )}
            {/* 
                      timestamp: moment(new Date(row.time)).format(
                        " DD-MM-YYYY hh:mm A"
                      ) */}
            {this.props.controller.state.isShowLogDetails && (
              <MoreDetails
                currentColor={currentColor}
                onClose={this.props.controller.onMoreDetailsCloseButtonPressed}
                details={{
                  Caller_Name: callLogsDetails.callUserDetail?.name ?? "--",
                  Caller_Phone:
                    callLogsDetails.callUserDetail?.phoneNumber ?? "---",
                  Room_No: callLogsDetails.callUserDetail?.roomNo ?? "---",
                  Purpose_Of_Visit:
                    callLogsDetails.callUserDetail?.purposeOfVisit ?? "--",
                  Received_By: callLogsDetails?.attendedBy?.name ?? "--",
                  Call_Status: callLogsDetails?.calldetails ?? "--",
                  Unit_Details: `${
                    callLogsDetails.subDivision?.parentApartmentSubDivision
                      ?.name ?? ""
                  }${
                    callLogsDetails.subDivision?.parentApartmentSubDivision
                      ?.name
                      ? "/"
                      : ""
                  }${callLogsDetails.subDivision?.name}`,
                  Call_By_Guard: callLogsDetails.isFromVisitorApp
                    ? t("Yes")
                    : t("No"),
                  Entry_Status: callLogsDetails.entryApprovalStatus,
                  From_Intercom: callLogsDetails.isFromIntercom ? t("Yes") : t("No"),
                  Time: moment(new Date(callLogsDetails?.time)).format(
                    " DD-MM-YYYY hh:mm A"
                  ),
                  Call_Event: this.props.controller.state.logEvents,
                }}
              />
            )}
            {this.props.controller.state.showImage && (
              <div className="w-screen h-screen fixed flex items-center justify-center left-0 top-0 bg-[#0000007b] z-10">
                <div className="relative p-2 bg-white rounded-md">
                  <div
                    onClick={() => {
                      this.props.controller.setState({
                        showImage: false,
                      });
                    }}
                    className="rounded-full w-10 h-10 z-50 flex items-center justify-center absolute right-[-6px] top-[-6px] cursor-pointer shadow-black shadow-md"
                    style={{ background: currentColor }}
                  >
                    <Clear sx={{ color: "white" }} />
                  </div>
                  <img
                    src={this.props.controller.state.imageUrl}
                    alt="logs-img"
                    style={{ width: "500px", height: "auto" }}
                  />
                </div>
              </div>
            )}
            {this.props.controller.state.loading == loadingEnu.PAGELOADING ? (
              <div className="h-full">
                <LinearIndeterminate />
                <StyledDataGrid
                  columns={columns}
                  hideFooterPagination={true}
                  style={{ background: "#fff", height: "90%" }}
                />
              </div>
            ) : (
              <>
                <StyledDataGrid
                  rows={controller.state.requests.callLogs.map(
                    (row: any, index: any) => ({
                      ...row,
                      id: index + 1,
                      apartment: `${
                        row.subDivision?.parentApartmentSubDivision?.name ?? ""
                      }${
                        row.subDivision?.parentApartmentSubDivision?.name
                          ? "/"
                          : ""
                      }${row.subDivision?.name}`,
                      timestamp: moment(new Date(row.time)).format(
                        " DD-MM-YYYY hh:mm A"
                      ),
                      picture: row.photo ?? row.callUserDetail?.photo,
                      // ansby: row?.attendedBy?.name ?? "--",
                      calldetails: row?.attendedBy?.name
                        ? row.isFromIntercom
                          ? t("Received (Intercom)")
                          : t("Received")
                        : row.isFromIntercom
                        ? t("Missed call (Intercom)")
                        : t("Missed call"),
                      phone: `${row.callUserDetail?.phoneNumber ? "+" : ""}${
                        row.callUserDetail?.phoneNumber ?? "---"
                      }`,
                      name: row.callUserDetail?.name ?? "---",
                    })
                  )}
                  columns={columns}
                  hideFooterPagination={true}
                  style={{ background: "#fff", height: "100%" }}
                  // hideFooterPagination={true}
                  hideFooter={true}
                />
              </>
            )}
          </div>
          <div>
            <TablePaginationMui
              pageNo={this.props.controller.state.pageNo}
              pageSize={this.props.controller.state.pageSize}
              onPageNumberChange={this.props.controller.onPageNumberChange}
              onPageSizeChange={this.props.controller.onPageSizeChange}
              totalItemSize={this.props.controller.state.requests.total}
            />
          </div>
        </>
      </div>
    );
  }
}

export default CallLogsView;
