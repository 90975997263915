import React, { Component } from "react";
import { useNavigate } from "react-router-dom";
import {
  LogOutAdmin,
  getUserToken,
  validateObject,
} from "../../../Globals/Functions";
import { StateContext } from "../../../context/ContextProvider";
import Loading_Page from "../../../Neet-Components/Loading-Page/Loading_Page";
import { AdminPrivileges } from "../../../constants";
import AdminView from "./AdminView";
import { addAdminApi, deleteAdminByIdApi, getAdminsApi } from "./api";
import { useTranslation } from "react-i18next";

interface Props {
  navigate: any;
  t:any;
}
interface State {
  adminToAdd: {
    phoneNo: string;
    name: string;
    country: string;
    email: string;
    address: string;
    username: string;
    password: string;
    createdBy: string;
    privileges: Array<string>;
    cloneLockCredentials:boolean,
    role?: number;    
  };
  adminToEdit: {
    phoneNo: string;
    name: string;
    country: string;
    email: string;
    address: string;
    username: string;
    password: string;
    createdBy: string;
    privileges: Array<string>;
    cloneLockCredentials:boolean,
    role?: number;
  };
  authToken: string;
  loading: string;
  isShowAddAdmin: boolean;
  isShowEditAdmin: boolean;
  isShowDeleteAdmin: boolean;
  admins: Array<Object>;
}
enum loadingEnu {
  PAGELOADING = "PAGELOADING",
  ADDBUTTONLOADING = "ADDBUTTONLOADING",
  EDITLOADING = "EDITLOADING",
  DELETELOADING = "DELETELOADING",
  NONE = "NONE",
}
export class AdminComponentWrap extends Component<Props, State> {
  static contextType: any = StateContext; // Assign StateContext to static contextType property
  constructor(props: Props) {
    super(props);
    this.state = {
      adminToAdd: {
        phoneNo: "",
        name: "",
        country: "",
        email: "",
        address: "",
        username: "",
        password: "",
        createdBy: "",
        privileges: [AdminPrivileges.CanAddAdmin],
        cloneLockCredentials:false,
        role: 0,
      },
      adminToEdit: {
        phoneNo: "",
        name: "",
        country: "",
        email: "",
        address: "",
        username: "",
        password: "",
        createdBy: "",
        privileges: [AdminPrivileges.CanAddAdmin],
        cloneLockCredentials:false,
        role: 0,
      },
      authToken: "",
      loading: loadingEnu.PAGELOADING,
      isShowAddAdmin: false,
      isShowEditAdmin: false,
      isShowDeleteAdmin: false,
      admins: [],
    };
  }
  componentDidMount(): void {
    const { setCookie, setAdmin, setIsUserLogedIn, admin }: any = this.context;
    const auth_Token = getUserToken();
    if (auth_Token) {
      this.setState({ authToken: auth_Token });
      this.getAdmins(auth_Token);
      this.setState((prevState) => ({
        adminToAdd: {
          ...prevState.adminToAdd,
          ["createdBy"]: admin._id,
        },
      }));
      this.setState((prevState) => ({
        adminToEdit: {
          ...prevState.adminToEdit,
          ["createdBy"]: admin._id,
        },
      }));
    } else {
      LogOutAdmin(this.props.navigate, setIsUserLogedIn);
    }
  }
  getAdmins = async (token: string) => {
    this.setState({ loading: loadingEnu.PAGELOADING });
    const res = await getAdminsApi(token);
    console.log(res);
    if (res.isSuccess && res.statusCode == 200) {
      this.setState({ admins: res.data });
    }
    this.setState({
      loading: loadingEnu.NONE,
    });
  };
  deleteAdmin = async (adminId: string) => {
    this.setState({ loading: loadingEnu.DELETELOADING });
    const res = await deleteAdminByIdApi(adminId, this.state.authToken);
    console.log(res);
    if (res.isSuccess && res.statusCode == 200) {
      const resForGetingAdmin = await getAdminsApi(this.state.authToken);
      console.log("resForGetingAdmin", resForGetingAdmin);
      if (resForGetingAdmin.isSuccess && resForGetingAdmin.statusCode == 200) {
        this.setState({ admins: resForGetingAdmin.data });
      } else {
        console.log("errorr while refreshing list of admin after deleting");
      }
    } else {
      console.log("errorr while deleting the admin");
    }
    this.setState({ loading: loadingEnu.NONE });
  };
  addAdmin = async () => {
    const { setCookie, setAdmin, setIsUserLogedIn, admin }: any = this.context;
    const isAllValueAreValid = validateObject(this.state.adminToAdd, [
      "country",
      "name",
      "address",
      "phoneNo",
      "role",
    ]);
    if (isAllValueAreValid === "right") {
      this.setState({ loading: loadingEnu.ADDBUTTONLOADING });
      let adminToAdd = this.state.adminToAdd;
      if (this.state.adminToAdd.role == 0) {
        delete adminToAdd.role;
      }
      const res = await addAdminApi(
        this.state.adminToAdd,
        this.state.authToken
      );
      console.log(res);
      if (res.statusCode == 403) {
        this.setState({
          loading: loadingEnu.NONE,
        });
        return { errorName: "Username Already Exist", type: "uni" };
      }
      if (res.isSuccess && res.statusCode == 200) {
        const resForGetingAdmin = await getAdminsApi(this.state.authToken);
        console.log("resForGetingAdmin", resForGetingAdmin);
        if (
          resForGetingAdmin.isSuccess &&
          resForGetingAdmin.statusCode == 200
        ) {
          this.setState({ admins: resForGetingAdmin.data });
          this.onShowAddAdmin();
          this.setState({
            loading: loadingEnu.NONE,
            adminToAdd: {
              phoneNo: "",
              name: "",
              country: "",
              email: "",
              address: "",
              username: "",
              password: "",
              createdBy: admin._id,
              privileges: [],
              cloneLockCredentials:false,
              role: 0,
            },
          });
        } else {
          this.setState({
            loading: loadingEnu.NONE,
          });
          console.log("errorr while refreshing list of admin after adding");
        }
      } else {
        this.setState({
          loading: loadingEnu.NONE,
        });
        console.log("someting is wrong when adding apartment");
      }
    } else {
      this.setState({
        loading: loadingEnu.NONE,
      });
      return isAllValueAreValid;
    }
  };
  onShowAddAdmin = () => {
    console.log("onShowAddApartment clicked");
    this.setState({ isShowAddAdmin: !this.state.isShowAddAdmin });
  };
  onAddAdminTextChange = (e: any) => {
    const { name, value } = e.target;
    console.log(name + "====", value);
    this.setState((prevState) => ({
      adminToAdd: {
        ...prevState.adminToAdd,
        [name]: value,
      },
    }));
  };
  render() {
    // if (this.state.loading == loadingEnu.PAGELOADING) {
    //   return <Loading_Page />
    // }
    return <AdminView controller={this as any} />;
  }
}

const AdminComponent = () => {
  const navigate = useNavigate();
  const {t}=useTranslation();
  return <AdminComponentWrap navigate={navigate} t={t}/>;
};

export default AdminComponent;
