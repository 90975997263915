import React, { MouseEventHandler, useState } from "react";
import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tabs,
  Tab,
  IconButton,
  Button,
  Typography,
  Paper,
} from "@mui/material";
import { Clear } from "@mui/icons-material";
import { useStateContext } from "../context/ContextProvider";
import { useTranslation } from "react-i18next";

interface Props {
  textFields: Array<{}>;
  onSubmit: Function;
  buttonLoading: boolean;
  onClose: MouseEventHandler<HTMLDivElement>;
  controller: any;
  isAddBox: boolean;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: string;
  value: string;
}

export enum LockProvider {
  Igloo = "Igloo",
  PDK = "PDK",
  WaveShare = "WaveShare",
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`lock-tabpanel-${index}`}
      aria-labelledby={`lock-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

interface LockFormProps {
  controller: any;
  isAddBox: boolean;
  isAllValueAreValid: string;
  type: "pdk" | "relay" | 'Intelbras';
  t:any
}

const LockForm = ({
  controller,
  isAddBox,
  isAllValueAreValid,
  type,
  t
}: LockFormProps) => (
  <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
    <TextField
      fullWidth
      size="small"
      label={t("Lock Name")}
      placeholder={t("Enter Lock Name")}
      value={
        isAddBox
          ? controller.state.lockToAdd.name
          : controller.state.lockToEdit.name
      }
      onChange={
        isAddBox
          ? controller.onAddAparmentTextChange
          : controller.onEditAparmentTextChange
      }
      error={isAllValueAreValid === "name"}
      helperText={
        isAllValueAreValid === "name" ? t("Enter a valid lock name") : ""
      }
      name="name"
    />

    <FormControl fullWidth size="small">
      <InputLabel>{t("Select Cloud Node")}</InputLabel>
      <Select
        value={controller.state.selectedCloudNode}
        label={t("Select Cloud Node")}
        onChange={controller.handleChangeCloudNodeChange}
      >
        {controller.state.cloudNode.map((cloudNode: any) => (
          <MenuItem key={cloudNode.id} value={cloudNode}>
            {cloudNode?.name}--{cloudNode?.id}
          </MenuItem>
        ))}
        {controller.state.cloudNode.length <= 0 && (
          <MenuItem value={""}>{t("No Device Found")}</MenuItem>
        )}
      </Select>
    </FormControl>

    <FormControl fullWidth size="small">
      <InputLabel>{t("Select Device Id")}</InputLabel>
      <Select
        value={controller.state.selectedDevice}
        label={t("Select Device Id")}
        onChange={controller.handleChangeDeviceIdChange}
      >
        {controller.state.devices.map((device: any) => (
          <MenuItem key={device.id} value={device}>
            {device?.name}
          </MenuItem>
        ))}
        {controller.state.devices.length <= 0 && (
          <MenuItem value={""}>{t("No Device Found")}</MenuItem>
        )}
      </Select>
    </FormControl>
  </Box>
);

const LockFormRelay = ({
  controller,
  isAddBox,
  isAllValueAreValid,
  type,
  t
}: LockFormProps) => (
  <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
    <TextField
      fullWidth
      size="small"
      label={t("Lock Name")}
      placeholder={t("Enter Lock Name")}
      value={
        isAddBox
          ? controller.state.lockToAdd.name
          : controller.state.lockToEdit.name
      }
      onChange={
        isAddBox
          ? controller.onAddAparmentTextChange
          : controller.onEditAparmentTextChange
      }
      error={isAllValueAreValid === "name"}
      helperText={
        isAllValueAreValid === "name" ? t("Enter a valid lock name") : ""
      }
      name="name"
    />

    <FormControl fullWidth size="small">
      <InputLabel>{t("Select Wave Share Relay")}</InputLabel>
      <Select
        value={controller.state.selectedWaveRelay}
        label={t("Select Wave Share Relay")}
        onChange={controller.handleChangeWaveRelayChange}
      >
        {controller.state.waveShareRelays.map((relay: any) => (
          <MenuItem key={relay.id} value={relay}>
            {relay?.name}--{relay?.id}
          </MenuItem>
        ))}
        {controller.state.waveShareRelays.length <= 0 && (
          <MenuItem value={""}>{t("No Wave Share Relay Found")}</MenuItem>
        )}
      </Select>
    </FormControl>
    <FormControl fullWidth size="small">
      <InputLabel>{t("Select Channel For Lock")}</InputLabel>
      <Select
        value={controller.state.selectWaveRelayChanel}
        label={t("Select Channel For Lock")}
        onChange={controller.handleWaveRelayChanelChange}
      >
        {controller.state?.selectedWaveRelay?.channels?.map((device: any) => (
          <MenuItem key={device._id} value={device}>
            {device?.channelName}
          </MenuItem>
        ))}
        {controller.state?.selectedWaveRelay?.channels?.length <= 0 && (
          <MenuItem value={""}>{t("No Channels Found")}</MenuItem>
        )}
      </Select>
    </FormControl>

    <TextField
      fullWidth
      size="small"
      label={t("Auto Lock Time (in sec)")}
      placeholder={t("Auto Lock Time (in sec)")}
      value={
        isAddBox
          ? controller.state.lockToAdd?.autoLockTime
          : controller.state.lockToEdit?.autoLockTime
      }
      onChange={
        isAddBox
          ? controller.onAddAparmentTextChange
          : controller.onEditAparmentTextChange
      }
      error={isAllValueAreValid === "autoLockTime"}
      helperText={
        isAllValueAreValid === "autoLockTime" ? t("Enter a valid time") : ""
      }
      name="autoLockTime"
    />
  </Box>
);

const LockFormRelayForIntelbras = ({
  controller,
  isAddBox,
  isAllValueAreValid,
  type,
  t
}: LockFormProps) => (
  <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
    <TextField
      fullWidth
      size="small"
      label={t("Lock Name")}
      placeholder={t("Enter Lock Name")}
      value={
        isAddBox
          ? controller.state.lockToAdd.name
          : controller.state.lockToEdit.name
      }
      onChange={
        isAddBox
          ? controller.onAddAparmentTextChange
          : controller.onEditAparmentTextChange
      }
      error={isAllValueAreValid === "name"}
      helperText={
        isAllValueAreValid === "name" ? t("Enter a valid lock name") : ""
      }
      name="name"
    />

    <FormControl fullWidth size="small">
      <InputLabel>{t("Select Intelbras Relay")}</InputLabel>
      <Select
        value={controller.state.selectedIntelbras}
        label={t("Select Intelbras Relay")}
        onChange={controller.handleChangeIntelbrasChange}
      >
        {controller.state.IntelbrasRelays.map((relay: any) => (
          <MenuItem key={relay.id} value={relay}>
            {relay?.name}--{relay?.id}
          </MenuItem>
        ))}
        {controller.state.IntelbrasRelays.length <= 0 && (
          <MenuItem value={""}>{t("No Intelbras Relay Found")}</MenuItem>
        )}
      </Select>
    </FormControl>
    <FormControl fullWidth size="small">
      <InputLabel>{t("Select Channel For Lock")}</InputLabel>
      <Select
        value={controller.state.selectIntelbrasChanel}
        label={t("Select Channel For Lock")}
        onChange={controller.handleIntelbrasChanelChange}
      >
        {controller.state?.selectedIntelbras?.channels?.map((device: any) => (
          <MenuItem key={device._id} value={device}>
            {device?.channelName}
          </MenuItem>
        ))}
        {controller.state?.selectedIntelbras?.channels?.length <= 0 && (
          <MenuItem value={""}>{t("No Channels Found")}</MenuItem>
        )}
      </Select>
    </FormControl>

    <TextField
      fullWidth
      size="small"
      label={t("Auto Lock Time (in sec)")}
      placeholder={t("Auto Lock Time (in sec)")}
      value={
        isAddBox
          ? controller.state.lockToAdd?.autoLockTime
          : controller.state.lockToEdit?.autoLockTime
      }
      onChange={
        isAddBox
          ? controller.onAddAparmentTextChange
          : controller.onEditAparmentTextChange
      }
      error={isAllValueAreValid === "autoLockTime"}
      helperText={
        isAllValueAreValid === "autoLockTime" ? t("Enter a valid time") : ""
      }
      name="autoLockTime"
    />
  </Box>
);

const AddLock = (props: Props) => {
  const { currentColor, admin } = useStateContext();
  const {t}=useTranslation();
  const [selectedTabId, setSelectedTabId] = useState<string>(
    admin.lockCredentials[0]?._id || ""
  );
  const [isAllValueAreValid, setIsAllValueAreValid] = useState("none");

  const handleChange = (_: React.SyntheticEvent, newValue: string) => {
    setSelectedTabId(newValue);
  };

  const handleSubmit = async () => {
    const res = await props.onSubmit(selectedTabId);
    if (res) {
      setIsAllValueAreValid(res);
    }
  };

  if (!admin.lockCredentials.length) {
    return null;
  }

  return (
    <div
      className="fixed inset-0 flex items-center justify-center"
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: 1000,
      }}
    >
      <Paper
        elevation={3}
        sx={{
          width: "500px",
          position: "relative",
          maxHeight: "90vh",
          overflow: "auto",
          borderRadius: 2,
        }}
      >
        <IconButton
          onClick={props.onClose as any}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            backgroundColor: currentColor,
            color: "white",
            "&:hover": {
              backgroundColor: currentColor,
              opacity: 0.9,
            },
          }}
        >
          <Clear />
        </IconButton>

        <Box sx={{ p: 4, pt: 6 }}>
          <Typography
            variant="h5"
            component="h2"
            sx={{
              textAlign: "center",
              mb: 4,
              fontWeight: 600,
              color: "text.primary",
            }}
          >
            {t("Add Lock")}
          </Typography>

          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={selectedTabId}
              onChange={handleChange}
              aria-label="lock type tabs"
              centered
              sx={{
                "& .MuiTabs-flexContainer": {
                  justifyContent: "center",
                },
              }}
            >
              {admin.lockCredentials.map((lock: any) => (
                <Tab
                  key={lock._id}
                  label={lock.name}
                  value={lock._id}
                  id={`lock-tab-${lock._id}`}
                  aria-controls={`lock-tabpanel-${lock._id}`}
                />
              ))}
            </Tabs>
          </Box>

          {admin.lockCredentials.map((lock: any, index: number) => (
            <TabPanel key={lock._id} value={selectedTabId} index={lock._id}>
              {lock.name == LockProvider.PDK ? (
                <LockForm
                  controller={props.controller}
                  isAddBox={props.isAddBox}
                  isAllValueAreValid={isAllValueAreValid}
                  type="pdk"
                  t={t}
                />
              ) : lock.name == LockProvider.WaveShare ? (
                <LockFormRelay
                  controller={props.controller}
                  isAddBox={props.isAddBox}
                  isAllValueAreValid={isAllValueAreValid}
                  type="relay"
                  t={t}
                />
              ) : <LockFormRelayForIntelbras
              controller={props.controller}
              isAddBox={props.isAddBox}
              isAllValueAreValid={isAllValueAreValid}
              type="Intelbras"
              t={t}
            />}
            </TabPanel>
          ))}

          <Box sx={{ mt: 3, display: "flex", justifyContent: "center" }}>
            <Button
              variant="contained"
              fullWidth
              disabled={props.buttonLoading}
              onClick={handleSubmit}
              sx={{
                backgroundColor: currentColor,
                "&:hover": {
                  backgroundColor: currentColor,
                  opacity: 0.9,
                },
              }}
            >
              {props.buttonLoading ? t("Saving...") : t("Save")}
            </Button>
          </Box>
        </Box>
      </Paper>
    </div>
  );
};

export default AddLock;
