import { Clear } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

// Helper function to recursively render nested objects
const renderDetails = (details: any,t:any) => {
  return Object.entries(details).map(([key, value]) => {
    if (typeof value === "object" && value !== null) {
      return (
        <div key={key} style={{ marginLeft: "20px" }}>
          <h3 id="details-dialog" className="col-span-2 mb-4 text-xl font-bold">
            {t(String(key).replace(/_/g, " "))}
          </h3>
          {renderDetails(value,t)}
        </div>
      );
    }
    return (
      <div key={key} style={{ marginBottom: "10px" }}>
        <strong>{t(String(key).replace(/_/g, " "))}:</strong>{" "}
        {key.toLocaleLowerCase() == "caller_phone" && String(value).length < 5
          ? "-"
          : key.toLocaleLowerCase() == "room_no" && String(value) == ""
          ? "-"
          : key.toLocaleLowerCase() == "entry_status" &&
            String(value).toLocaleLowerCase() == "none"
          ? "-"
          : String(value)}
      </div>
    );
  });
};

const MoreDetails = ({ details, currentColor, onClose }: any) => {
  const { t } = useTranslation();
  return (
    <div
      className="fixed top-0 left-0 flex items-center justify-center bg-neutral-400 bg-opacity-50 h-screen w-screen z-20"
      role="dialog"
      aria-labelledby="details-dialog"
    >
      <div className="grid grid-cols-2 bg-white h-[400px] w-[600px] max-w-[600px] p-5 overflow-y-auto shadow-lg rounded-lg relative">
        <div
          onClick={onClose}
          className="rounded-full w-10 h-10 flex items-center justify-center absolute right-2 top-2 cursor-pointer"
          style={{
            background: currentColor,
            boxShadow:
              "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;",
          }}
        >
          <Clear sx={{ color: "white" }} />
        </div>
        <h2
          id="details-dialog"
          className="col-span-2 mb-6 text-[30px] font-bold text-center"
        >
          {t("More Details")}
        </h2>
        {renderDetails(details,t)}
      </div>
    </div>
  );
};

export default MoreDetails;
