import React, { Component, useContext, useEffect, useState } from "react";
import { useNavigate, useNavigation, useParams } from "react-router-dom";
import SubdivisionVIew from "./SubdivisionVIew";
import {
  LogOutAdmin,
  getUserToken,
  validateObject,
} from "../../../Globals/Functions";
import {
  StateContext,
  useStateContext,
} from "../../../context/ContextProvider";
import {
  addSubdivisionApi,
  deleteSubdivisionById,
  genrateQrCodeApi,
  getApartmentsApi,
  getSubdivisionByApartment,
  getSubdivisionByParentSubdivision,
  removeSubdivisionLogoApi,
} from "../apartments/api";
import Loading_Page from "../../../Neet-Components/Loading-Page/Loading_Page";
import { getBuildingDetailsWithTypeName } from "../../../data/BuildingTypes";
import { useTranslation } from "react-i18next";

interface Props {
  navigate: any;
  apartmentId: string | undefined;
  isShowDropdown?: boolean;
  t:any;
}
interface State {
  apartmentToAdd: {
    name: string;
    location: string;
    phoneNumber: string;
    logo: string;
  };
  apartmentToEdit: {
    name: string;
    location: string;
    phoneNumber: string;
    _id: string;
    logo: string;
  };
  authToken: string;
  loading: string;
  isShowAddApartment: boolean;
  isShowEditApartment: boolean;
  isShowDeleteApartment: boolean;
  apartments: Array<Object>;
  screenType: string;
  genrateQrLoading: string;
  showFlatsOnly: boolean;
  editHasLogo: boolean;
}
enum loadingEnu {
  PAGELOADING = "PAGELOADING",
  ADDBUTTONLOADING = "ADDBUTTONLOADING",
  EDITLOADING = "EDITLOADING",
  DELETELOADING = "DELETELOADING",
  NONE = "NONE",
  REMOVELOGO = "REMOVELOGO",
}
enum ScreenType {
  ManageSubdivision = "ManageSubdivision",
  ManageFlats = "ManageFlats",
}
export class SubdivisionComponentWrap extends Component<Props, State> {
  static contextType: any = StateContext; // Assign StateContext to static contextType property
  constructor(props: Props) {
    super(props);
    this.state = {
      apartmentToAdd: {
        name: "",
        location: "",
        phoneNumber: "",
        logo: "",
      },
      apartmentToEdit: {
        name: "",
        location: "",
        phoneNumber: "",
        _id: "",
        logo: "",
      },
      genrateQrLoading: "",
      authToken: "",
      loading: loadingEnu.PAGELOADING,
      isShowAddApartment: false,
      isShowEditApartment: false,
      isShowDeleteApartment: false,
      screenType: ScreenType.ManageSubdivision,
      showFlatsOnly: false,
      apartments: [],
      editHasLogo: false,
    };
  }
  componentDidMount(): void {
    console.log("should be rerender", this.props.apartmentId);
    const { setIsUserLogedIn }: any = this.context;
    const auth_Token = getUserToken();
    if (auth_Token) {
      this.setState({ authToken: auth_Token });
      this.getSubdivisions(this.props.apartmentId, auth_Token);
    } else {
      LogOutAdmin(this.props.navigate, setIsUserLogedIn);
    }
  }
  getSubdivisions = async (apartmentId: string | undefined, token: string) => {
    const { admin, superParent }: any = this.context;
    this.setState({ loading: loadingEnu.PAGELOADING });
    if (superParent == apartmentId) {
      const res = await getSubdivisionByApartment(
        admin?._id ? admin?._id : "",
        apartmentId as string,
        token
      );
      console.log(`get aparment response by id ==${apartmentId}`, res);
      if (res.isSuccess && res.statusCode == 200) {
        this.setState({ apartments: res.data.data });
      }
    } else {
      const res = await getSubdivisionByParentSubdivision(
        admin?._id ? admin?._id : "",
        apartmentId as string,
        token
      );
      console.log(`get aparment response by id ==${apartmentId}`, res);
      if (res.isSuccess && res.statusCode == 200) {
        this.setState({
          apartments: res.data.data,
          showFlatsOnly: true,
          screenType: ScreenType.ManageFlats,
        });
      }
    }

    this.setState({
      loading: loadingEnu.NONE,
    });
  };
  removeLogo = async (id: string) => {
    const { admin, superParent }: any = this.context;
    this.setState({
      loading: loadingEnu.REMOVELOGO,
    });
    const res = await removeSubdivisionLogoApi(id, this.state.authToken);
    if (res.isSuccess && res.statusCode == 200) {
      if (superParent == this.props.apartmentId) {
        const res = await getSubdivisionByApartment(
          admin?._id ? admin?._id : "",
          this.props.apartmentId as string,
          this.state.authToken
        );
        console.log(
          `get aparment response by id ==${this.props.apartmentId}`,
          res
        );
        if (res.isSuccess && res.statusCode == 200) {
          this.setState({
            apartments: res.data.data,
            loading: loadingEnu.NONE,
          });
          alert("logo removed successfully");
        }
      } else {
        const res = await getSubdivisionByParentSubdivision(
          admin?._id ? admin?._id : "",
          this.props.apartmentId as string,
          this.state.authToken
        );
        console.log(
          `get aparment response by id ==${this.props.apartmentId}`,
          res
        );
        if (res.isSuccess && res.statusCode == 200) {
          this.setState({ apartments: res.data.data });
        }
      }
    } else {
      console.log("errorr while deleting the apartment");
      alert("Something went wrong Please try again");
    }
  };
  deleteApartment = async (apartmentId: string) => {
    const { admin, superParent }: any = this.context;
    this.setState({ loading: loadingEnu.DELETELOADING });
    const res = await deleteSubdivisionById(apartmentId, this.state.authToken);
    console.log(res);
    if (res.isSuccess && res.statusCode == 200) {
      if (superParent == this.props.apartmentId) {
        const res = await getSubdivisionByApartment(
          admin?._id ? admin?._id : "",
          this.props.apartmentId as string,
          this.state.authToken
        );
        console.log(
          `get aparment response by id ==${this.props.apartmentId}`,
          res
        );
        if (res.isSuccess && res.statusCode == 200) {
          this.setState({ apartments: res.data.data });
        }
      } else {
        const res = await getSubdivisionByParentSubdivision(
          admin?._id ? admin?._id : "",
          this.props.apartmentId as string,
          this.state.authToken
        );
        console.log(
          `get aparment response by id ==${this.props.apartmentId}`,
          res
        );
        if (res.isSuccess && res.statusCode == 200) {
          this.setState({ apartments: res.data.data });
        }
      }
    } else {
      console.log("errorr while deleting the apartment");
    }
    this.setState({ loading: loadingEnu.NONE });
  };

  addAparment = async () => {
    const { admin, superParent }: any = this.context;
    const isAllValueAreValid = validateObject(this.state.apartmentToAdd, [
      "location",
      "phoneNumber",
      "logo",
    ]);
    if (isAllValueAreValid === "right") {
      this.setState({ loading: loadingEnu.ADDBUTTONLOADING });
      const postApiData: {
        parentApartmentSubDivision: string | undefined;
        apartment: any;
        name: string;
        location: string;
        phoneNumber: string;
      } = {
        ...this.state.apartmentToAdd,
        parentApartmentSubDivision:
          this.props.apartmentId == superParent
            ? undefined
            : (this.props.apartmentId as string),
        apartment: superParent,
      };
      console.log(postApiData);
      const res = await addSubdivisionApi(postApiData, this.state.authToken);
      // console.log(this.state)
      console.log(res);
      if (res.isSuccess && res.statusCode == 200) {
        if (superParent == this.props.apartmentId) {
          const resForGettingAparments = await getSubdivisionByApartment(
            admin?._id ? admin?._id : "",
            this.props.apartmentId as string,
            this.state.authToken
          );
          console.log(
            `get aparment resForGettingAparmentsponse by id ==${this.props.apartmentId}`,
            resForGettingAparments
          );
          // if (resForGettingAparments.isSuccess && resForGettingAparments.statusCode == 200) {
          if (
            resForGettingAparments.isSuccess &&
            resForGettingAparments.statusCode == 200
          ) {
            this.onShowAddApartment();
            this.setState({
              loading: loadingEnu.NONE,
              apartmentToAdd: {
                name: "",
                location: "",
                phoneNumber: "",
                logo: "",
              },
            });
            this.setState({ apartments: resForGettingAparments.data.data });
          } else {
            console.log("error while geting apartments after adding");
          }
          // }
        } else {
          const resForGettingAparments =
            await getSubdivisionByParentSubdivision(
              admin?._id ? admin?._id : "",
              this.props.apartmentId as string,
              this.state.authToken
            );
          console.log(
            `get aparment resForGettingAparmentsponse by id ==${this.props.apartmentId}`,
            resForGettingAparments
          );
          // if (resForGettingAparments.isSuccess && resForGettingAparments.statusCode == 200) {
          if (
            resForGettingAparments.isSuccess &&
            resForGettingAparments.statusCode == 200
          ) {
            this.onShowAddApartment();
            this.setState({
              loading: loadingEnu.NONE,
              apartmentToAdd: {
                name: "",
                location: "",
                phoneNumber: "",
                logo: "",
              },
            });
            this.setState({ apartments: resForGettingAparments.data.data });
          } else {
            console.log("error while geting apartments after adding");
          }
          // }
        }
      } else {
        console.log("someting is wrong when adding apartment");
      }
    } else {
      return isAllValueAreValid;
    }
  };
  onToggleButtonClicked = (screenType: string) => {
    this.setState({ screenType: screenType });
  };
  onShowAddApartment = () => {
    console.log("onShowAddApartment clicked");
    this.setState({ isShowAddApartment: !this.state.isShowAddApartment });
  };
  onAddAparmentTextChange = (e: any) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      apartmentToAdd: {
        ...prevState.apartmentToAdd,
        [name]: value,
      },
    }));
  };
  onEditAparmentTextChange = (e: any) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      apartmentToEdit: {
        ...prevState.apartmentToEdit,
        [name]: value,
      },
    }));
  };
  handleNaivgationStackClick = (stackObj: { name: string; id: string }) => {
    if (stackObj.id.length < 5) {
      this.props.navigate("/dashboard/apartments");
    } else {
      this.props.navigate(`/dashboard/subdivision/${stackObj.id}`);
    }
  };

  onQrButtonPressed = async (subdivision: any) => {
    const { admin, superParent, setSuperParent }: any = this.context;
    console.log(subdivision);
    if (subdivision?.subDivision?.qrCode) {
      // this.props.navigate(`/dashboard/showQrCode/${subdivision.subDivision.qrCode}`)
      window.open(`/showQrCode/${subdivision.subDivision.qrCode}`, "_blank");
    } else {
      this.setState({ genrateQrLoading: subdivision.subDivision._id });
      const res = await genrateQrCodeApi(
        {
          type: "subdivision",
          apartmentSubdivisionFlat: subdivision.subDivision,
        },
        this.state.authToken
      );
      if (res.isSuccess && res.statusCode == 200) {
        if (superParent == this.props.apartmentId) {
          const resForGettingAparments = await getSubdivisionByApartment(
            admin?._id ? admin?._id : "",
            this.props.apartmentId as string,
            this.state.authToken
          );
          console.log(
            `get aparment resForGettingAparmentsponse by id ==${this.props.apartmentId}`,
            resForGettingAparments
          );
          if (
            resForGettingAparments.isSuccess &&
            resForGettingAparments.statusCode == 200
          ) {
            this.setState({ apartments: resForGettingAparments.data.data });
            alert("Qr Genrated Successfully");
          } else {
            console.log("error while geting apartments after adding");
          }
          // }
        } else {
          const resForGettingAparments =
            await getSubdivisionByParentSubdivision(
              admin?._id ? admin?._id : "",
              this.props.apartmentId as string,
              this.state.authToken
            );
          console.log(
            `get aparment resForGettingAparmentsponse by id ==${this.props.apartmentId}`,
            resForGettingAparments
          );
          // if (resForGettingAparments.isSuccess && resForGettingAparments.statusCode == 200) {
          if (
            resForGettingAparments.isSuccess &&
            resForGettingAparments.statusCode == 200
          ) {
            alert("Qr Genrated Successfully");
            this.setState({ apartments: resForGettingAparments.data.data });
          } else {
            console.log("error while geting apartments after adding");
          }
          // }
        }
      } else {
        alert("something went wrong!");
      }
      this.setState({ genrateQrLoading: "" });
    }
  };
  onShowEditApartmentWithValue = (subDivision: any) => {
    console.log("subDivision to edit", subDivision);
    this.setState({
      apartmentToEdit: {
        _id: subDivision.subDivision._id,
        name: subDivision.subDivision.name,
        location: subDivision.subDivision.location,
        phoneNumber: subDivision.subDivision.phoneNumber,
        logo: "",
      },
    });
    this.setState({
      isShowEditApartment: !this.state.isShowEditApartment,
      editHasLogo: subDivision.subDivision.thumbLogo ? true : false,
    });
  };
  onShowEditApartment = () => {
    this.setState({ isShowEditApartment: !this.state.isShowEditApartment });
  };

  onApartmentStatusChange = async (value: any) => {
    const {
      apartments,
      setSuperParent,
      subdivisionTreeNavigaitonStack,
      setCurrentBuildingType,
      setAPartmentOfSubdivision,
    }: any = this.context;
    this.setState({ loading: loadingEnu.PAGELOADING });
    setAPartmentOfSubdivision(value);
    subdivisionTreeNavigaitonStack.push({
      name: value.name,
      id: value.aparmentId,
    });
    setSuperParent(value.aparmentId);
    setCurrentBuildingType(
      getBuildingDetailsWithTypeName(
        value?.apartment?.apartmentType
          ? value?.apartment?.apartmentType
          : "Apartment"
      )
    );
    this.setState({ loading: loadingEnu.NONE });
  };
  editAparment = async () => {
    const { admin, superParent, setSuperParent }: any = this.context;
    const isAllValueAreValid = validateObject(this.state.apartmentToEdit, [
      "location",
      "phoneNumber",
      "logo",
    ]);
    if (isAllValueAreValid === "right") {
      this.setState({ loading: loadingEnu.EDITLOADING });
      const postApiData: {
        parentApartmentSubDivision: string | undefined;
        apartment: any;
        name: string;
        location: string;
        phoneNumber: string;
      } = {
        ...this.state.apartmentToEdit,
        parentApartmentSubDivision:
          this.props.apartmentId == superParent
            ? undefined
            : (this.props.apartmentId as string),
        apartment: superParent,
      };
      console.log(postApiData);
      const res = await addSubdivisionApi(postApiData, this.state.authToken);
      console.log(res);
      if (res.isSuccess && res.statusCode == 200) {
        if (superParent == this.props.apartmentId) {
          const resForGettingAparments = await getSubdivisionByApartment(
            admin?._id ? admin?._id : "",
            this.props.apartmentId as string,
            this.state.authToken
          );
          console.log(
            `get aparment resForGettingAparmentsponse by id ==${this.props.apartmentId}`,
            resForGettingAparments
          );
          if (
            resForGettingAparments.isSuccess &&
            resForGettingAparments.statusCode == 200
          ) {
            this.onShowEditApartment();
            this.setState({
              loading: loadingEnu.NONE,
              apartmentToEdit: {
                name: "",
                location: "",
                phoneNumber: "",
                _id: "",
                logo: "",
              },
            });
            // this.setState({ apartments: resForGettingAparments.data.data });
            this.getSubdivisions(this.props.apartmentId, this.state.authToken);
          } else {
            console.log("error while geting apartments after adding");
          }
          // }
        } else {
          const resForGettingAparments =
            await getSubdivisionByParentSubdivision(
              admin?._id ? admin?._id : "",
              this.props.apartmentId as string,
              this.state.authToken
            );
          console.log(
            `get aparment resForGettingAparmentsponse by id ==${this.props.apartmentId}`,
            resForGettingAparments
          );
          // if (resForGettingAparments.isSuccess && resForGettingAparments.statusCode == 200) {
          if (
            resForGettingAparments.isSuccess &&
            resForGettingAparments.statusCode == 200
          ) {
            this.onShowEditApartment();
            this.setState({
              loading: loadingEnu.NONE,
              apartmentToEdit: {
                name: "",
                location: "",
                phoneNumber: "",
                _id: "",
                logo: "",
              },
            });
            this.setState({ apartments: resForGettingAparments.data.data });
            // this.getSubdivisions(this.props.apartmentId, this.state.authToken);
          } else {
            console.log("error while geting apartments after adding");
          }
          // }
        }
      } else {
        console.log("someting is wrong when adding apartment");
      }
    } else {
      return isAllValueAreValid;
    }
  };
  render() {
    // if (this.state.loading == loadingEnu.PAGELOADING) {
    //   return <Loading_Page />
    // }
    return <SubdivisionVIew controller={this} />;
  }
}

const SubdivisionComponent = ({
  isShowDropdown,
}: {
  isShowDropdown?: boolean;
}) => {
  const navigate = useNavigate();
  const { parent } = useParams();
  const [loading, setLoading] = useState(true);
  const {
    apartments,
    setSuperParent,
    subdivisionTreeNavigaitonStack,
    setCurrentBuildingType,
    setAPartmentOfSubdivision,
  } = useStateContext();
  const {t}=useTranslation();
  const [apartmentId, setApartmentId] = useState(
    parent ?? apartments[0].aparmentId
  );
  useEffect(() => {
    console.log("parent apartment id", apartments);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 200);
    setApartmentId(parent ?? apartments[0]._id);
    if (!parent) {
      setAPartmentOfSubdivision(apartments[0]);
      subdivisionTreeNavigaitonStack.push({
        name: apartments[0].name,
        id: apartments[0].aparmentId,
      });
      setSuperParent(apartments[0].aparmentId);
      setCurrentBuildingType(
        getBuildingDetailsWithTypeName(
          apartments[0]?.apartment?.apartmentType
            ? apartments[0]?.apartment?.apartmentType
            : "Apartment"
        )
      );
    }
  }, [parent]);

  return loading ? (
    <Loading_Page />
  ) : (
    <SubdivisionComponentWrap
      navigate={navigate}
      apartmentId={apartmentId}
      isShowDropdown={isShowDropdown}
      t={t}
    />
  );
};

export default SubdivisionComponent;
