import React, { useState, ChangeEvent } from "react";
import ButtonWithIcon from "../Neet-Components/Buttons/ButtonWithIcon";
import { Clear, Preview, UploadFile } from "@mui/icons-material";
import { useStateContext } from "../context/ContextProvider";
import { AparmetsComponentWrap } from "../pages/dashboard/apartments/AparmetsComponent";
import { useTranslation } from "react-i18next";

interface Props {
  setLogoBase64: any;
  controller: AparmetsComponentWrap;
  isAddApartment: boolean;
}
const ImageUpload = ({ setLogoBase64, controller, isAddApartment }: Props) => {
  const { currentColor, currentMode } = useStateContext();
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [showImage, setShowImage] = useState(false);
  const {t}=useTranslation();
  const txtField: any = React.useRef(null);
  const [base64, setBase64]: any = useState("");
  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedImage(URL.createObjectURL(file));
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        if (isAddApartment) {
          controller.setState((prevState) => ({
            apartmentToAdd: {
              ...prevState.apartmentToAdd,
              logo: base64String as string,
            },
          }));
        } else {
          controller.setState((prevState) => ({
            apartmentToEdit: {
              ...prevState.apartmentToEdit,
              logo: base64String as string,
            },
          }));
        }
        setBase64(base64String);
        setLogoBase64(base64String);
      };
      reader.readAsDataURL(file);
    }
  };
  return (
    <div className="w-[50%]">
      <input
        type="file"
        accept="image/*"
        onChange={handleImageChange}
        style={{ display: "none" }}
        ref={txtField}
      />
      <ButtonWithIcon
        buttonName={
          selectedImage
            ? t("Reupload Logo")
            : !isAddApartment
            ? t("Edit Your Logo")
            : t("Upload Your Logo")
        }
        iconName={<UploadFile />}
        onClick={() => {
          txtField.current.click();
        }}
      />
      {selectedImage && (
        <ButtonWithIcon
          buttonName={t("Preview Logo")}
          background={"green"}
          iconName={<Preview />}
          onClick={() => {
            setShowImage(true);
          }}
        />
      )}
      {selectedImage && (
        <ButtonWithIcon
          buttonName={t("Clear logo")}
          background={"red"}
          iconName={<Preview />}
          onClick={() => {
            if (isAddApartment) {
              controller.setState((prevState) => ({
                apartmentToAdd: {
                  ...prevState.apartmentToAdd,
                  logo: "",
                },
              }));
            } else {
              controller.setState((prevState) => ({
                apartmentToEdit: {
                  ...prevState.apartmentToEdit,
                  logo: "",
                },
              }));
            }
            setBase64("");
            setLogoBase64("");
            setSelectedImage(null);
          }}
        />
      )}
      {showImage && selectedImage && (
        <div className="w-screen h-screen fixed flex items-center justify-center left-0 top-0 bg-[#0000007b] z-10">
          <div className="relative p-10 bg-white rounded-md">
            <div
              onClick={() => {
                setShowImage(false);
              }}
              className="rounded-full w-10 h-10 z-50 flex items-center justify-center absolute right-[-6px] top-[-6px] cursor-pointer shadow-black shadow-md"
              style={{ background: currentColor }}
            >
              <Clear sx={{ color: "white" }} />
            </div>
            <img
              src={selectedImage}
              alt="Selected"
              style={{ width: "300px", height: "auto" }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageUpload;
