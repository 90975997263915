import React, { useEffect, useState } from "react";
import { X, Eye, EyeOff, Copy } from "lucide-react";

interface LockPopupProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (data: Record<string, string>) => Promise<boolean>;
  title: string;
  fields: any[];
  initialData?: Record<string, string>;
  missingFieldsForIntelbrasLock: string[];
}

const LockConfigPopup: React.FC<LockPopupProps> = ({
  isOpen,
  onClose,
  onSave,
  title,
  fields,
  initialData = {},
  missingFieldsForIntelbrasLock,
}) => {
  const [formData, setFormData] = useState<Record<string, string>>(initialData);
  const [showPasswords, setShowPasswords] = useState<Record<string, boolean>>(
    {}
  );

  // Sync formData when initialData changes
  useEffect(() => {
    console.log(initialData, "updated initialData from parent");
    setFormData(initialData);
  }, [initialData]);

  const handleInputChange = (key: string, value: string) => {
    setFormData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleCopy = (value: string, label: string) => {
    navigator.clipboard.writeText(value).then(() => {
      alert(`${label} copied to clipboard`);
    });
  };

  const togglePasswordVisibility = (key: string) => {
    setShowPasswords((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const renderField = (field: any) => {
    switch (field.type) {
      case "password":
        return (
          <div key={field.key}>
            <label className="block text-sm font-medium text-gray-700">
              {field.label}
            </label>
            <div className="relative">
              <input
                type={showPasswords[field.key] ? "text" : "password"}
                className="w-full border rounded-md px-3 py-2"
                value={formData[field.key] || ""}
                onChange={(e) => handleInputChange(field.key, e.target.value)}
              />
              <div className="absolute right-2 top-2 flex space-x-2">
                <button
                  className="text-gray-400 hover:text-gray-600"
                  onClick={() => togglePasswordVisibility(field.key)}
                >
                  {showPasswords[field.key] ? (
                    <EyeOff className="w-4 h-4" />
                  ) : (
                    <Eye className="w-4 h-4" />
                  )}
                </button>
                <button
                  className="text-gray-400 hover:text-gray-600"
                  onClick={() => handleCopy(formData[field.key], field.label)}
                >
                  <Copy className="w-4 h-4" />
                </button>
              </div>
            </div>
          </div>
        );
      case "select":
        return (
          <div key={field.key}>
            <label className="block text-sm font-medium text-gray-700">
              {field.label}
            </label>
            <select
              className="w-full border rounded-md px-3 py-2"
              value={formData[field.key] || ""}
              onChange={(e) => handleInputChange(field.key, e.target.value)}
            >
              <option value="" disabled>
                Select an option
              </option>
              {field.options.map((option: string) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        );
      default:
        return (
          <div key={field.key}>
            <label className="block text-sm font-medium text-gray-700">
              {field.label}
            </label>
            <input
              type="text"
              className="w-full border rounded-md px-3 py-2"
              value={formData[field.key] || ""}
              onChange={(e) => handleInputChange(field.key, e.target.value)}
            />
          </div>
        );
    }
  };

  const handleSave = async () => {
    const success = await onSave({ ...formData });
    if (success) {
      setFormData(initialData); // Reset form data to initial values
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg w-full max-w-md p-6 relative">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">{title}</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        <div className="space-y-4">
          {fields.map((field: any) => renderField(field))}
        </div>

        {missingFieldsForIntelbrasLock.length > 0 && (
          <span className="block text-sm font-medium ml-2 text-red-700">
            {`(${missingFieldsForIntelbrasLock.join(", ")})`}
          </span>
        )}

        <div className="mt-6 flex justify-end space-x-3">
          <button
            onClick={onClose}
            className="px-4 py-2 border rounded-md text-gray-600 hover:bg-gray-50"
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default LockConfigPopup;
