import React, { useState, useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import ArticlesList from "../../../components/Article";

// Define language types
type Language = 'en' | 'hi' | 'es' | 'fr'|'ru'|'pt';

// Define multilingual form data structure
interface MultilingualFormData {
  [lang: string]: {
    title: string;
    shortDescription: string;
    notificationTitle: string;
    notificationDescription: string;
    content: string;
  }
}

const ArticlesEditor: React.FC = () => {
  const [activeTab, setActiveTab] = useState<"list" | "create">("list");
  const [activeLanguage, setActiveLanguage] = useState<Language>('en');
  const [multilingualFormData, setMultilingualFormData] = useState<MultilingualFormData>({
    en: {
      title: "",
      shortDescription: "",
      notificationTitle: "",
      notificationDescription: "",
      content: ""
    },
    hi: {
      title: "",
      shortDescription: "",
      notificationTitle: "",
      notificationDescription: "",
      content: ""
    },
    es: {
      title: "",
      shortDescription: "",
      notificationTitle: "",
      notificationDescription: "",
      content: ""
    },
    fr: {
      title: "",
      shortDescription: "",
      notificationTitle: "",
      notificationDescription: "",
      content: ""
    },
    ru: {
      title: "",
      shortDescription: "",
      notificationTitle: "",
      notificationDescription: "",
      content: ""
    },
    pt: {
      title: "",
      shortDescription: "",
      notificationTitle: "",
      notificationDescription: "",
      content: ""
    }
  });

  const [files, setFiles] = useState<File[]>([]);

  // Language configuration
  const languages: Language[] = ['en', 'hi', 'es', 'fr','ru','pt'];
  const languageNames: Record<Language, string> = {
    en: 'English',
    hi: 'Hindi',
    es: 'Spanish',
    fr: 'French',
    ru:"Russian",
    pt:"Portuguese"
  };

  const categories = [
    "Technology", 
    "Programming", 
    "Design", 
    "Web Development", 
    "Other"
  ];

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setMultilingualFormData(prev => ({
      ...prev,
      [activeLanguage]: {
        ...prev[activeLanguage],
        [name]: value
      }
    }));
  };

  const handleEditorChange = (content: string) => {
    setMultilingualFormData(prev => ({
      ...prev,
      [activeLanguage]: {
        ...prev[activeLanguage],
        content: content
      }
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    const submissionData = {
      multilingual: multilingualFormData,
      attachments: files
    };

    try {
      const response = await fetch('/api/articles', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(submissionData)
      });

      if (response.ok) {
        alert('Article submitted successfully!');
        // Reset form
        setMultilingualFormData({
          en: {
            title: "",
            shortDescription: "",
            notificationTitle: "",
            notificationDescription: "",
            content: ""
          },
          hi: {
            title: "",
            shortDescription: "",
            notificationTitle: "",
            notificationDescription: "",
            content: ""
          },
          es: {
            title: "",
            shortDescription: "",
            notificationTitle: "",
            notificationDescription: "",
            content: ""
          },
          fr: {
            title: "",
            shortDescription: "",
            notificationTitle: "",
            notificationDescription: "",
            content: ""
          },
          ru: {
            title: "",
            shortDescription: "",
            notificationTitle: "",
            notificationDescription: "",
            content: ""
          },
          pt: {
            title: "",
            shortDescription: "",
            notificationTitle: "",
            notificationDescription: "",
            content: ""
          }
        });
        setFiles([]);
      } else {
        alert('Failed to submit article');
      }
    } catch (error) {
      console.error('Submission error:', error);
      alert('An error occurred');
    }
  };

  // Quill modules for enhanced editor capabilities
  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      ['link', 'image', 'video'],
      [{ 'align': [] }],
      [{ 'color': [] }, { 'background': [] }],
      ['clean']
    ],
  };
  const articles = [
    { 
      id: 1, 
      title: "React Basics", 
      author: "John Doe", 
      status: "Published",
      publishedDate: "2024-01-15"
    },
    {
      id: 2,
      title: "Advanced JavaScript",
      author: "Jane Smith", 
      status: "Draft",
      publishedDate: "2024-02-20"
    },
    { 
      id: 3, 
      title: "CSS Grid Layout", 
      author: "Tom Lee", 
      status: "Published",
      publishedDate: "2024-03-10"
    }
  ];

  return (
    <div className="container mx-auto px-4 py-8">
      {/* Tabs */}
      <div className="border-b border-gray-200 dark:border-gray-700 mb-6">
        <div className="flex space-x-4">
          <button
            onClick={() => setActiveTab("list")}
            className={`
              flex items-center space-x-2 px-4 py-2 rounded-t-lg 
              ${activeTab === "list" 
                ? "bg-blue-600 text-white" 
                : "text-gray-600 hover:bg-gray-100"}
            `}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
            </svg>
            <span>Articles List</span>
          </button>
          <button
            onClick={() => setActiveTab("create")}
            className={`
              flex items-center space-x-2 px-4 py-2 rounded-t-lg 
              ${activeTab === "create" 
                ? "bg-blue-600 text-white" 
                : "text-gray-600 hover:bg-gray-100"}
            `}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
            </svg>
            <span>Create Article</span>
          </button>
        </div>
      </div>

      {/* Content Area */}
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6">
        {activeTab === "list" && (
          <div className="overflow-x-auto">
            {/* Previous articles list remains the same */}
          </div>
        )}
{activeTab === "list" && (
       <ArticlesList articles={articles} onEdit={()=>{}} onDelete={()=>{}}/>
        )}
        {activeTab === "create" && (
          <div>
            {/* Language Selector */}
            <div className="mb-4 flex space-x-2">
              {languages.map(lang => (
                <button
                  key={lang}
                  onClick={() => setActiveLanguage(lang)}
                  className={`
                    px-4 py-2 rounded-md
                    ${activeLanguage === lang 
                      ? 'bg-blue-600 text-white' 
                      : 'bg-gray-200 text-gray-700'}
                  `}
                >
                  {languageNames[lang]}
                </button>
              ))}
            </div>

            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="grid md:grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Article Title ({languageNames[activeLanguage]})
                  </label>
                  <input 
                    type="text"
                    name="title"
                    value={multilingualFormData[activeLanguage].title}
                    onChange={handleInputChange}
                    placeholder={`Enter article title in ${languageNames[activeLanguage]}`}
                    required
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">Category</label>
                  <select
                    name="category"
                    onChange={handleInputChange}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    <option value="">Select a category</option>
                    {categories.map(cat => (
                      <option key={cat} value={cat}>{cat}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Short Description ({languageNames[activeLanguage]})
                </label>
                <textarea
                  name="shortDescription"
                  value={multilingualFormData[activeLanguage].shortDescription}
                  onChange={handleInputChange}
                  placeholder={`Enter a brief article description in ${languageNames[activeLanguage]}`}
                  rows={3}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>

              <div className="grid md:grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Notification Title ({languageNames[activeLanguage]})
                  </label>
                  <input 
                    type="text"
                    name="notificationTitle"
                    value={multilingualFormData[activeLanguage].notificationTitle}
                    onChange={handleInputChange}
                    placeholder={`Enter notification title in ${languageNames[activeLanguage]}`}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Notification Description ({languageNames[activeLanguage]})
                  </label>
                  <input 
                    type="text"
                    name="notificationDescription"
                    value={multilingualFormData[activeLanguage].notificationDescription}
                    onChange={handleInputChange}
                    placeholder={`Enter notification description in ${languageNames[activeLanguage]}`}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Article Content ({languageNames[activeLanguage]})
                </label>
                <ReactQuill
                  theme="snow"
                  value={multilingualFormData[activeLanguage].content}
                  onChange={handleEditorChange}
                  modules={modules}
                  style={{ height: "400px",marginBottom:80, }}
                  placeholder={`Write your article in ${languageNames[activeLanguage]}...`}
                />
              </div>              

              <div className="flex justify-center mt-40">
                <button 
                  type="submit" 
                  className="px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  Submit Article
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default ArticlesEditor;