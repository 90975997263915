import React, { useState } from "react";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import { useTranslation } from "react-i18next";

interface YesNoRadioProps {
  value?: boolean; // The current value (true for "Yes", false for "No") - optional
  onChange?: (value: boolean) => void; // Function to handle the change event - optional
}

const YesNoRadio = ({ value, onChange }: YesNoRadioProps) => {
  const [internalValue, setInternalValue] = useState<boolean>(value ?? false);
  const {t}=useTranslation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isYes = event.target.value === "yes"; // Check if the selected value is "yes"
    if (onChange) {
      onChange(isYes); // Use the onChange handler if provided
    } else {
      setInternalValue(isYes); // Update internal state
    }
  };

  const currentValue = value !== undefined ? value : internalValue; // Use prop value if provided, else use internal state

  return (
    <FormControl>
      <FormLabel>{t("Auto-Approve Join Requests")}</FormLabel>
      <RadioGroup
        value={currentValue ? "yes" : "no"} // Map boolean value to "yes" or "no"
        onChange={handleChange} // Handle the change event
        row // Display radio buttons in a row
      >
        <FormControlLabel
          value="yes"
          control={<Radio />}
          label={t("Yes")} // Label for "Yes" option
        />
        <FormControlLabel
          value="no"
          control={<Radio />}
          label={t("No")} // Label for "No" option
        />
      </RadioGroup>
    </FormControl>
  );
};

export default YesNoRadio;
