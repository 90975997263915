import React, { MouseEventHandler, useState } from "react";
import TextFieldSimple from "../Neet-Components/Text-Fields/TextFieldSimple";
import { Clear } from "@mui/icons-material";
import { useStateContext } from "../context/ContextProvider";
import ButtonWithIcon from "../Neet-Components/Buttons/ButtonWithIcon";
import { AdminComponentWrap } from "../pages/dashboard/admin/AdminComponent";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { AdminRoles } from "../constants";
import { useTranslation } from "react-i18next";

interface Props {
  textFields: Array<{}>;
  onSubmit: Function;
  buttonLoading: boolean;
  onClose: MouseEventHandler<HTMLDivElement>;
  controller: AdminComponentWrap;
}
interface State {
  isAllValueAreValid: string;
}
const AddAdmin = (props: Props) => {
  const { currentColor, currentMode, admin } = useStateContext();
  const {t}=useTranslation();
  const [isAllValueAreValid, setIsAllValueAreValid] = useState("none");
  return (
    <div className="fixed h-screen w-screen left-0 top-0 bg-[#0000007b] z-10 flex items-center justify-center">
      <div className="bg-white  flex flex-wrap items-center justify-center gap-2 w-[500px] p-10 pt-20 relative rounded-xl">
        <div
          onClick={props.onClose}
          className="rounded-full w-10 h-10 flex items-center justify-center absolute right-2 top-2 cursor-pointer shadow-black shadow-md"
          style={{ background: currentColor }}
        >
          <Clear sx={{ color: "white" }} />
        </div>
        <div className=" absolute top-[20px] w-[80%] text-center">
          <p className="text-3xl font-semibold text-neutral-700">{t("Add Member")}</p>
        </div>
        <TextFieldSimple
          onChange={props.controller.onAddAdminTextChange}
          placeholder={t("Name")}
          defaultValue={props.controller.state.adminToAdd.name}
          value={props.controller.state.adminToAdd.name}
          errorMsg={isAllValueAreValid == "name" ? t("Enter a valid name") : ""}
          width="200px"
          name="name"
          errorAbsolute={true}
        />
        <TextFieldSimple
          onChange={props.controller.onAddAdminTextChange}
          placeholder={t("Email Address*")}
          defaultValue={props.controller.state.adminToAdd.email}
          value={props.controller.state.adminToAdd.email}
          errorMsg={isAllValueAreValid == "email" ? t("Enter a valid email") : ""}
          width="200px"
          name="email"
          errorAbsolute={true}
        />
        {/* <TextFieldSimple
                    onChange={props.controller.onAddAdminTextChange}
                    placeholder="country"
                    defaultValue={props.controller.state.adminToAdd.country}
                    value={props.controller.state.adminToAdd.country}
                    errorMsg={isAllValueAreValid == "country" ? "Enter a valid country" : ""}
                    width="200px"
                    name="country"
                /> */}
        <TextFieldSimple
          onChange={props.controller.onAddAdminTextChange}
          placeholder={t("Phone number")}
          defaultValue={props.controller.state.adminToAdd.phoneNo}
          value={props.controller.state.adminToAdd.phoneNo}
          errorMsg={
            isAllValueAreValid == "phoneNo" ? t("Enter a valid phone No") : ""
          }
          width="200px"
          name="phoneNo"
          errorAbsolute={true}
        />
        {/* <TextFieldSimple
                    onChange={props.controller.onAddAdminTextChange}
                    placeholder="address"
                    defaultValue={props.controller.state.adminToAdd.address}
                    value={props.controller.state.adminToAdd.address}
                    errorMsg={isAllValueAreValid == "address" ? "Enter a valid address" : ""}
                    width="200px"
                    name="address"
                /> */}
        <TextFieldSimple
          onChange={props.controller.onAddAdminTextChange}
          placeholder={t("Username for login*")}
          defaultValue={props.controller.state.adminToAdd.username}
          value={props.controller.state.adminToAdd.username}
          errorMsg={
            isAllValueAreValid == "username"
              ? t("Username mush be an email")
              : isAllValueAreValid == "duplicateUsername"
              ? t("User Already Exist")
              : ""
          }
          width="200px"
          name="username"
          errorAbsolute={true}
        />
        <TextFieldSimple
          onChange={props.controller.onAddAdminTextChange}
          placeholder={t("Password*")}
          defaultValue={props.controller.state.adminToAdd.password}
          value={props.controller.state.adminToAdd.password}
          errorMsg={
            isAllValueAreValid == "password" ? t("Enter a valid password") : ""
          }
          width="200px"
          name="password"
          errorAbsolute={true}
        />
        {admin?.role == AdminRoles.SuperAdmin && (
          <FormControl style={{ width: "200px", paddingLeft: "20px" }}>
            <FormLabel id="demo-radio-buttons-group-label">
              {t("Want To Make Owner")}
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={0}
              name="radio-buttons-group"
              value={props.controller.state.adminToAdd.role}
              sx={{ display: "flex", flexDirection: "row" }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                props.controller.setState((prevState) => ({
                  adminToAdd: {
                    ...prevState.adminToAdd,
                    role: parseInt(event.target.value),
                  },
                }));
              }}
            >
              <FormControlLabel
                value={AdminRoles.Owner}
                control={<Radio />}
                label={t("Yes")}
              />
              <FormControlLabel value={0} control={<Radio />} label={t("No")} />
            </RadioGroup>
          </FormControl>
        )}

        {admin?.role == AdminRoles.SuperAdmin && (
          <FormControl style={{ width: "100%", paddingLeft: "20px" }}>
            <FormLabel id="demo-radio-buttons-group-label">
              {t("Can Clone Lock Credentials")}
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={
                props.controller.state.adminToAdd.cloneLockCredentials
              }
              name="radio-buttons-group"
              value={props.controller.state.adminToAdd.cloneLockCredentials}
              sx={{ display: "flex", flexDirection: "row" }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                props.controller.setState((prevState) => ({
                  adminToAdd: {
                    ...prevState.adminToAdd,
                    cloneLockCredentials: event.target.value === "true",
                  },
                }));
              }}
            >
              <FormControlLabel value={true} control={<Radio />} label={t("Yes")} />
              <FormControlLabel value={false} control={<Radio />} label={t("No")} />
            </RadioGroup>
          </FormControl>
        )}
        <ButtonWithIcon
          buttonName={t("Save")}
          onClick={async () => {
            const res = await props.controller.addAdmin();
            console.log(res);
            if (res) {
              if (typeof res == "string") {
                setIsAllValueAreValid(res);
              } else if (res.type == "uni") {
                setIsAllValueAreValid("duplicateUsername");
              }
            }
          }}
          buttonLoading={props.buttonLoading}
        />
      </div>
    </div>
  );
};

export default AddAdmin;
