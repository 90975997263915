export const saveToLocalStorage = (key:string, value:any) => {
    try {
      const serializedValue = JSON.stringify(value);
      localStorage.setItem(key, serializedValue);
    } catch (error) {
      console.error("Error saving to localStorage", error);
    }
  };
  
  export const getFromLocalStorage = (key:string) => {
    try {
      const serializedValue = localStorage.getItem(key);
      if (serializedValue === null) {
        return null;
      }
      return JSON.parse(serializedValue);
    } catch (error) {
      console.error("Error getting data from localStorage", error);
      return null;
    }
  };
  
  export const removeFromLocalStorage = (key: string) => {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      console.error("Error removing from localStorage", error);
    }
  };

  export const validateObject = (obj: any) => {
    const invalidKeys = [];
    
    // Check if the object is empty
    if (Object.keys(obj).length === 0) {
      return false;  // Return false if the object is empty
    }
  
    for (let key in obj) {
      if (typeof obj[key] !== 'string' || obj[key].length <= 1) {
        invalidKeys.push(key);
      }
    }
  
    return invalidKeys.length === 0 ? true : invalidKeys;
  };
  