import React, { useState } from "react";
import SelectStatus from "../../../components/SelectStatus";
import { useNavigate, useParams } from "react-router-dom";
import ButtonWithIcon from "../../../Neet-Components/Buttons/ButtonWithIcon";
import { deleteRequestsApi, updatedUserRequestStatus } from "./apiHandler";
import { getUserToken } from "../../../Globals/Functions";
import { useStateContext } from "../../../context/ContextProvider";
import { Delete } from "@mui/icons-material";
import CustomAlert from "../../../components/CustomAlert";
import { useTranslation } from "react-i18next";

const ManageRequest = () => {
  const [status, setStatus] = useState("Approved");
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const navigate = useNavigate();
  const {t}=useTranslation();
  const { request } = useParams();
  const onStatusChange = (status: string) => {
    setStatus(status);
  };
  const { admin } = useStateContext();
  const onDeleteButtonPressed = async () => {
    const auth_Token = getUserToken();
    setDeleteLoading(true);
    const res = await deleteRequestsApi(
      request as string,
      auth_Token as string
    );
    console.log(res, "response for delete requst");
    if (res.isSuccess && res.statusCode == 200) {
      alert("deleted successfully");
      navigate("/dashboard/users");
    } else {
      alert("something went wrong!");
    }
    setDeleteLoading(false);
  };

  const onSubmitButtonPressed = async () => {
    const auth_Token = getUserToken();
    try {
      setLoading(true);
      const res = await updatedUserRequestStatus(
        {
          requestStatus: status,
          _id: request as string,
          statusUpdatedBy: admin._id,
        },
        auth_Token as string
      );
      console.log(res);
      if (res.isSuccess && res.statusCode == 200) {
        // alert(`status changed to ${status}`);
        navigate("/dashboard/requests");
        setLoading(false);
      } else {
        console.log("something went wrong!");
        setLoading(false);
      }
    } catch (error: any) {
      console.log("something went wrong while updating request", error);
    }
    setLoading(false);
  };
  return (
    <div className="px-10 py-5">
      <SelectStatus onStatusChange={onStatusChange} />
      <div className="mt-6 max-w-[400px] flex gap-5">
        <ButtonWithIcon
          buttonLoading={loading}
          buttonName={t("Submit")}
          onClick={onSubmitButtonPressed}
        />
        <CustomAlert
          onPressDelete={onDeleteButtonPressed}
          color={"white"}
          text={deleteLoading ? t("Loading..") : t("Delete Request")}
          width={"400px"}
          background={"red"}
        />
        {/* <ButtonWithIcon background={"red"} buttonLoading={loading} buttonName={"Delete Request"} iconName={<Delete />} onClick={onSubmitButtonPressed} /> */}
      </div>
    </div>
  );
};

export default ManageRequest;
