import React, { Component } from "react";
import style from "./forgotPassword.module.css";
import logo from "./../../Assets/multi-logo.png";
import ButtonWithBackground from "../../Neet-Components/Buttons/ButtonWithBackground";
import TextFieldSimple from "../../Neet-Components/Text-Fields/TextFieldSimple";
import { Navigation } from "../../Utils/eums";
import { forgotPasswordApi, getOrgDetailsByOrgAliasApi } from "./apiHandler";
import { validateEmail } from "../../Globals/Functions";
import Loading_Page from "../../Neet-Components/Loading-Page/Loading_Page";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface ForgotPasswordState {
  isShowPassMsg: boolean;
  email: string;
  loading: string;
  error: string;
}
interface ForgotPasswordProps {
  navigation: Function;
  t:any;
}
export class ForgotPassword extends Component<
  ForgotPasswordProps,
  ForgotPasswordState
> {
  constructor(props: ForgotPasswordProps) {
    super(props);
    this.state = {
      isShowPassMsg: !true,
      email: "",
      loading: "",
      error: "",
    };
  }
  onSubmitPasswordButtonClicked = async () => {
    this.setState({ loading: "buttonLoading" });
    if (!validateEmail(this.state.email)) {
      this.setState({ error: "email" });
      this.setState({ loading: "" });
      return;
    }
    const res = await forgotPasswordApi(this.state.email);
    this.setState({ isShowPassMsg: true });
    this.setState({ loading: "" });
  };
  render() {
    const { isShowPassMsg, loading, error } = this.state;
    const { navigation } = this.props;
    const t=this.props.t;
    if (loading === "pageLoading") {
      return <Loading_Page />;
    } else
      return (
        <div className={style.forgot_password}>
          <div className={style.logo_title}>
            <img src={logo} className={style.logo} />
            <p className={style.title}>
              {isShowPassMsg
                ? t("Check Inbox")
                : t("Enter associated email with your account")}
            </p>
          </div>
          <div
            className={style.container}
            style={isShowPassMsg ? { width: "485px" } : {}}
          >
            {isShowPassMsg ? (
              <p style={{ textAlign: "center", lineHeight: "24px" }}>
                {t("If the email address is found in our system, your new password will be sent to that email address.")}
              </p>
            ) : (
              <>
                <TextFieldSimple
                  placeholder={t("Email Address")}
                  errorMsg={error === "email" ? t("Invalid Email") : ""}
                  onChange={(e: any) => {
                    this.setState({
                      email: e.target.value,
                    });
                  }}
                />
                <ButtonWithBackground
                  buttonName={t("Submit")}
                  onClick={this.onSubmitPasswordButtonClicked}
                  buttonLoading={loading === "buttonLoading"}
                />
              </>
            )}
          </div>
          {/* <div className={style.link}> */}
          <p className={style.link}>
            <span onClick={() => {}} style={{ visibility: "hidden" }}>
              {t("Create Account")}
            </span>{" "}
            <span
              // className={style.forgot_link}
              className="link_css"
              onClick={() => {
                navigation("/");
              }}
            >
              {t("Back To Login ?")}
            </span>
          </p>
          {/* </div> */}
        </div>
      );
  }
}

const ForgotPasswordWrap = () => {
  const navigate = useNavigate();
  const {t}=useTranslation();
  return <ForgotPassword navigation={navigate} t={t}/>;
};

export default ForgotPasswordWrap;
