// const Base_Url = "https://apartment.api.doorvi.co:4015";
const Base_Url = "https://apart.api.new.doorvi.co";
// const Base_Url = "https://testserver.vanimeetings.com:4015";
export const Doorvi_Base_Url = "https://api.doorvi.co/api";
export const googleMapsApiKey = "88190388177c444389be89eb6bbe6a28";
export const googleMapsMainApiKey = "AIzaSyDh8s1xi403ly9vfgCEOPKuMp79_5_LVD4";
export const qrBaseUrl = "https://call.doorvi.co";
export const logsImageBaseUrl = "https://api.doorvi.co/image/";
// export const qrBaseUrl="https://testserver.vanimeetings.com";

const cookiesSaved = {
  login: "user_login_details",
  auth_Token: "AuthToken_Apartments",
  adminId: "Admin_ID",
};
export enum LevelsForLock {
  FLAT = "FLAT",
  APARTMENT = "Apartment",
  SUBDIVISION = "Subdivision",
}
enum loadingEnu {
  PAGELOADING = "pageLoging",
  BUTTONLOADING = "buttonLoading",
  NONE = "none",
}
enum errorTypeLogin {
  Username = "username",
  Password = "password",
  NONE = "none",
  API = "api",
  ALL = "all",
}
export enum AdminPrivileges {
  CanAddAdmin = "CanAddAdmin",
  CanAddApartments = "CanAddApartments",
  CanEditApartments = "CanEditApartments",
  CanAddTowerAndFloor = "CanAddTowerAndFloor",
  CanEditTowerAndFloor = "CanEditTowerAndFloor",
  CanApproveUsers = "CanApproveUsers",
}

export enum AdminRoles {
  SuperAdmin = 10000,
  Owner = 5000,
  Manager = 1000,
  Approver = 500,
  Viewer = 100,
  guard=99,
}
export const adminRolesArray = [
  { title: "Manager", value: 1000, name: "Admin" },
  { title: "Approver", value: 500, name: "Approve Only" },
  { title: "Viewer", value: 100, name: "View Only" },
  { title: "guard", value: 99, name: "Security Guard" },
  // {title:"cloneLockCredential",value:50,name:"Clone Lock Credential"}
];
export const adminPrivileges = [
  { name: "CanAddAdmin", title: "Can Add Admin" },
  { name: "CanAddApartments", title: "Can Add Apartments" },
  { name: "CanEditApartments", title: "Can Edit Apartments" },
  { name: "CanAddTowerAndFloor", title: "Can Add Tower And Floor" },
  { name: "CanEditTowerAndFloor", title: "Can Edit Tower And Floor" },
  {
    name: "CanApproveUsers",
    title: "Can Approve/Reject/Delete Joining Request",
  },
];
export { Base_Url, cookiesSaved, loadingEnu, errorTypeLogin };
