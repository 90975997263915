import React, { Component } from "react";
import { useNavigate } from "react-router-dom";
import { LogOutAdmin, getUserToken } from "../../../Globals/Functions";
import { StateContext } from "../../../context/ContextProvider";
import {
  getCallLogsByApartmentApi,
  getUsersByApartmentIdAndStatus,
} from "./apiHandler";
import CallLogsView from "./CallLogsView";
import { getApartmentsApi, getCallLogEventsApi } from "../apartments/api";
import ApiClient from "../../../Api/apiClient";
import axios from "axios";
import { AdminRoles } from "../../../constants";
import { useTranslation } from "react-i18next";

interface Props {
  navigate: any;
  t:any;
}
interface State {
  authToken: string;
  loading: string;
  isShowAddApartment: boolean;
  isShowEditApartment: boolean;
  isShowDeleteApartment: boolean;
  requests: any;
  status: string;
  apartments: Array<Object>;
  selectedApartment: any;
  adminRole: number;
  pageNo: number;
  pageSize: number;
  showImage: boolean;
  imageUrl: string;
  isShowLogDetails: boolean;
  setLogDetails: {};
  logEvents: string;
}
enum loadingEnu {
  PAGELOADING = "PAGELOADING",
  ADDBUTTONLOADING = "ADDBUTTONLOADING",
  EDITLOADING = "EDITLOADING",
  DELETELOADING = "DELETELOADING",
  NONE = "NONE",
}
export class CallLogsWrap extends Component<Props, State> {
  static contextType: any = StateContext; // Assign StateContext to static contextType property
  constructor(props: Props) {
    super(props);
    this.state = {
      authToken: "",
      loading: loadingEnu.PAGELOADING,
      isShowAddApartment: false,
      isShowEditApartment: false,
      isShowDeleteApartment: false,
      status: "all",
      apartments: [],
      selectedApartment: { _id: "" },
      requests: [],
      adminRole: 10000,
      pageNo: 0,
      pageSize: 10,
      showImage: false,
      imageUrl: "",
      isShowLogDetails: false,
      setLogDetails: {},
      logEvents: "-",
    };
  }
  componentDidMount(): void {
    const { setIsUserLogedIn }: any = this.context;
    const auth_Token = getUserToken();
    if (auth_Token) {
      this.setState({ authToken: auth_Token });
      this.getRequests(auth_Token);
    } else {
      LogOutAdmin(this.props.navigate, setIsUserLogedIn);
    }
  }

  getRequests = async (token: string) => {
    const { admin }: any = this.context;
    this.setState({ loading: loadingEnu.PAGELOADING });
    const res = await getApartmentsApi(token);
    console.log("get aparments response", res);
    if (res.isSuccess && res.statusCode == 200 && res.data.length > 0) {
      this.setState({
        apartments: res.data,
        selectedApartment: res.data[0].apartment,
        adminRole: res.data[0].role,
      });
      const userDetailsResponse = await getCallLogsByApartmentApi(
        token,
        res.data[0].apartment?._id,
        "none",
        this.state.pageSize,
        this.state.pageNo
      );
      console.log("get user response", userDetailsResponse);
      if (
        userDetailsResponse.isSuccess &&
        userDetailsResponse.statusCode == 200
      ) {
        this.setState({ requests: userDetailsResponse.data });
      }
    }
    this.setState({
      loading: loadingEnu.NONE,
    });
  };
  getCallLogsEventByLogId = async (log: any) => {
    try {
      const res: any = await getCallLogEventsApi(log._id);
      console.log(res);
      if (res.data.length > 0) {
        let listOfLogs = "";
        res.data.map((event: any) => {
          listOfLogs = listOfLogs + "\n," + event.event;
        });
        this.setState({ logEvents: listOfLogs });
        this.setState({ setLogDetails: log, isShowLogDetails: true });
      } else {
        this.setState({ setLogDetails: log, isShowLogDetails: true });
      }
    } catch (error: any) {}
  };
  onMoreDetailsButtonPressed = async (log: any) => {
    const { admin }: any = this.context;
    if (admin.role == AdminRoles.SuperAdmin) {
      this.getCallLogsEventByLogId(log);
    } else {
      this.getCallLogsEventByLogId(log);
    }
  };
  onMoreDetailsCloseButtonPressed = () => {
    this.setState({
      setLogDetails: {},
      isShowLogDetails: false,
      logEvents: "-",
    });
  };
  deleteApartment = async (apartmentId: string) => {
    // this.setState({ loading: loadingEnu.DELETELOADING });
    // const res = await deleteApartmentsByIdApi(apartmentId, this.state.authToken);
    // console.log(res);
    // if (res.isSuccess && res.statusCode == 200) {
    //   const resForGetingAparment = await getRequestsApi(this.state.authToken)
    //   console.log("resForGetingAparment", resForGetingAparment);
    //   if (resForGetingAparment.isSuccess && resForGetingAparment.statusCode == 200) {
    //     this.setState({ requests: resForGetingAparment.data });
    //   }
    //   else {
    //     console.log("errorr while refreshing list of aparment after deleting")
    //   }
    // }
    // else {
    //   console.log("errorr while deleting the apartment")
    // }
    // this.setState({ loading: loadingEnu.NONE });
  };
  onShowAddApartment = () => {
    console.log("onShowAddApartment clicked");
    this.setState({ isShowAddApartment: !this.state.isShowAddApartment });
  };
  onPageReload = async () => {
    this.setState({ loading: loadingEnu.PAGELOADING });
    // this.setState
    const res = await getCallLogsByApartmentApi(
      this.state.authToken,
      this.state.selectedApartment._id,
      "none",
      this.state.pageSize,
      this.state.pageNo
    );
    console.log(
      "get user response response after status change",
      this.state.selectedApartment
    );
    if (res.isSuccess && res.statusCode == 200) {
      this.setState({ requests: res.data });
    }
    this.setState({ loading: loadingEnu.NONE });
  };
  onApartmentStatusChange = async (value: any, role?: any) => {
    console.log(value);
    this.setState({ selectedApartment: value });
    const res = await getCallLogsByApartmentApi(
      this.state.authToken,
      value._id,
      "none",
      this.state.pageSize,
      this.state.pageNo
    );
    console.log("get user response response after status change", res);
    if (res.isSuccess && res.statusCode == 200) {
      this.setState({ requests: res.data });
    }
  };
  onPageNumberChange = async (pageNo: number) => {
    this.setState({ pageNo: pageNo });
    const res = await getCallLogsByApartmentApi(
      this.state.authToken,
      this.state.selectedApartment._id,
      "none",
      this.state.pageSize,
      pageNo
    );
    console.log("get user response response after status change", res);
    if (res.isSuccess && res.statusCode == 200) {
      this.setState({ requests: res.data });
    }
  };
  onPageSizeChange = async (pageSize: number) => {
    this.setState({ pageSize: pageSize, pageNo: 0 });
    const res = await getCallLogsByApartmentApi(
      this.state.authToken,
      this.state.selectedApartment._id,
      "none",
      pageSize,
      0
    );
    console.log("get user response response after status change", res);
    if (res.isSuccess && res.statusCode == 200) {
      this.setState({ requests: res.data });
    }
  };
  onStatusChange = async (value: string) => {
    // this.setState({ status: value });
    // const res = await getUsersByApartmentIdAndStatus(
    //   this.state.selectedApartment?._id,
    //   value,
    //   this.state.authToken
    // );
    // console.log("get user response response after status change", res);
    // if (res.isSuccess && res.statusCode == 200) {
    //   this.setState({ requests: res.data });
    // }
  };
  render() {
    // if (this.state.loading == loadingEnu.PAGELOADING) {
    //   return <Loading_Page />
    // }
    return <CallLogsView controller={this as any} />;
  }
}

const CallLogsComponent = () => {
  const navigate = useNavigate();
  const {t}=useTranslation();
  return <CallLogsWrap navigate={navigate} t={t}/>;
};

export default CallLogsComponent;
