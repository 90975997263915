import React from "react";
import style from "./button.module.css";
import { CircularProgress } from "@mui/material";
import { useStateContext } from "../../context/ContextProvider";

const ButtonWithIcon = ({ buttonName, onClick, buttonLoading,background,iconName ,color,isSelected,width}: any) => {
  const { currentColor, activeMenu, setActiveMenu, screenSize,currentBuildingType } = useStateContext();
  return (
    <div className="w-full h-full flex items-center">
    <button  onClick={onClick} className={isSelected ? style.button_with_icon:style.button_with_icon} style={{background: background|| currentColor,color:color,cursor:"pointer",width}}>
    {iconName && iconName}
      {buttonLoading ? (
        <CircularProgress
          sx={{
            color: "#fff",
            height: "20px!important",
            width: "20px!important",
          }}
        />
      ) : (
        buttonName
      )}
    </button>
    </div>
  );
};

export default ButtonWithIcon;
