import React, { ChangeEvent, Component } from "react";
import AssignApartmentView from "./AssignApartmentView";
import { useParams } from "react-router-dom";
import {getAdminsApiByApartment } from "../admin/api";
import Loading_Page from "../../../Neet-Components/Loading-Page/Loading_Page";
import { getUserToken } from "../../../Globals/Functions";
import {
  assignAdminToApartmentApi,
  deleteAdminFromApartmentApi,
  getAdminsByApartmentId,
} from "../apartments/api";
import DescriptionAlert from "../../../components/DescriptionAlert";
import { AlertTypeEnum } from "../../../Utils/Constants";
import { StateContext } from "../../../context/ContextProvider";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

interface Props {
  apartment: string;
  t:any;
}
interface State {
  admins: Array<any>;
  assignedAdmins: Array<any>;
  privileges: Array<any>;
  apartment: any;
  loading: string;
  authToken: string;
  selectedAdmin: any;
  selectedAdminForAssignedAdmin: any;
  screenType: string;
  assignedAdminPrivileges: Array<any>;
  role: number;
  alreadyAdminRole: number;
}
enum LoadingEnum {
  NONE = "NONE",
  PAGELOADING = "PAGELOADING",
  ASSIGNBUTTONLOADING = "ASSIGNBUTTONLOADING",
}
enum ScreenType {
  ASSIGNNEWADMIN = "ASSIGNNEWADMIN",
  MANAGEASSINEDADMIN = "MANAGEASSINEDADMIN",
}
export class AssignApartmentComponentWrap extends Component<Props, State> {
  static contextType: any = StateContext; // Assign StateContext to static contextType property
  constructor(props: Props) {
    super(props);
    this.state = {
      admins: [],
      privileges: [],
      assignedAdminPrivileges: [],
      apartment: this.props.apartment,
      loading: LoadingEnum.PAGELOADING,
      authToken: "",
      selectedAdmin: {},
      screenType: ScreenType.ASSIGNNEWADMIN,
      selectedAdminForAssignedAdmin: {},
      assignedAdmins: [],
      role: 1000,
      alreadyAdminRole: 1000,
    };
  }
  componentDidMount(): void {
    const auth_Token = getUserToken();
    if (auth_Token) {
      this.setState({ authToken: auth_Token });
      this.getAdmins(auth_Token);
      this.getAdminsByApartment(this.state.apartment, auth_Token);
    }
  }
  onToggleButtonClicked = (screenType: string) => {
    this.setState({ screenType: screenType });
    if (screenType === ScreenType.ASSIGNNEWADMIN) {
      this.setState({ privileges: [] });
    }
  };
  assignApartmentAndRoleToAdminAlredayInApartment = async () => {
    this.setState({ loading: LoadingEnum.ASSIGNBUTTONLOADING });
    const resForAssignApartment = await assignAdminToApartmentApi(
      {
        admin: this.state.selectedAdminForAssignedAdmin.admin._id,
        apartment: this.state.apartment,
        privileges: this.state.assignedAdminPrivileges,
        role: this.state.alreadyAdminRole,
      },
      this.state.authToken
    );
    console.log("resForAssignApartment", resForAssignApartment);
    if (
      resForAssignApartment.isSuccess &&
      resForAssignApartment.statusCode == 200
    ) {
      const res = await getAdminsByApartmentId(
        this.state.apartment,
        this.state.authToken
      );
      console.log("admins by apratments", res.data);
      if (res.isSuccess && res.statusCode == 200 && res.data.length > 1) {
        console.log("data",res.data.filter((admins:any)=>(admins.admin?._id==this.state.selectedAdminForAssignedAdmin.admin._id)))
        this.setState({
          selectedAdminForAssignedAdmin: res.data.filter((admins:any)=>(admins.admin?._id==this.state.selectedAdminForAssignedAdmin.admin._id))[0],
          // assignedAdminPrivileges: [...res.data[1].privileges],
        });
        this.setState({ assignedAdmins: res.data });
        this.getAdmins(this.state.authToken);
        toast(this.props.t("Assigned Role Succesfully"));
        DescriptionAlert({
          type: AlertTypeEnum.Success,
          msg: this.props.t("Admin Assigned Successfully"),
          title: this.props.t("Success"),
        });
        console.log(resForAssignApartment);
      }
    } else {
      console.log(
        "something went wrong while assiging aparment and role to admin"
      );
    }
    this.setState({
      loading: LoadingEnum.NONE,
    });
  };
  assignApartmentAndRoleToAdmin = async () => {
    this.setState({ loading: LoadingEnum.ASSIGNBUTTONLOADING });
    const resForAssignApartment = await assignAdminToApartmentApi(
      {
        admin: this.state.selectedAdmin._id,
        apartment: this.state.apartment,
        privileges: this.state.privileges,
        role: this.state.role,
      },
      this.state.authToken
    );
    console.log("resForAssignApartment", resForAssignApartment);
    if (
      resForAssignApartment.isSuccess &&
      resForAssignApartment.statusCode == 200
    ) {
      const res = await getAdminsByApartmentId(
        this.state.apartment,
        this.state.authToken
      );
      console.log("admins by apratments", res.data);
      if (res.isSuccess && res.statusCode == 200 && res.data.length > 1) {
        this.setState({
          selectedAdminForAssignedAdmin: res.data[1],
          assignedAdminPrivileges: [...res.data[1].privileges],
          alreadyAdminRole: res.data[0].role,
        });
        this.setState({ assignedAdmins: res.data });
        this.getAdmins(this.state.authToken);
        toast(this.props.t("Assigned Role Succesfully"));
        DescriptionAlert({
          type: AlertTypeEnum.Success,
          msg: this.props.t("Admin Assigned Successfully"),
          title: this.props.t("Success"),
        });
        console.log(resForAssignApartment);
      }
    } else {
      console.log(
        "something went wrong while assiging aparment and role to admin"
      );
    }
    this.setState({
      loading: LoadingEnum.NONE,
    });
  };
  handlePrivilegeChange = (e: any, privilegeName: string) => {
    const { checked } = e.target;
    const { privileges } = this.state;
    if (checked) {
      this.setState({
        privileges: [...privileges, privilegeName],
      });
    } else {
      this.setState({
        privileges: privileges.filter((name) => name !== privilegeName),
      });
    }
    console.log(this.state.privileges);
  };
  handleRoleSelection = (
    event: ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    console.log(value);
    this.setState({ role: parseInt(value) });
  };
  handleRoleSelectionForEdit = (
    event: ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    console.log(value);
    this.setState({ alreadyAdminRole: parseInt(value) });
  };
  handlePrivilegeChangeForAssignedAdmin = (e: any, privilegeName: string) => {
    const { checked } = e.target;
    const { assignedAdminPrivileges } = this.state;
    if (checked) {
      this.setState({
        assignedAdminPrivileges: [...assignedAdminPrivileges, privilegeName],
      });
    } else {
      this.setState({
        assignedAdminPrivileges: assignedAdminPrivileges.filter(
          (name) => name !== privilegeName
        ),
      });
    }
    console.log(this.state.privileges);
  };
  handleChangeForAssignedAdmin = (event: any) => {
    const { value } = event.target;
    const { privileges } = value;
    console.log(value);
    this.setState({
      assignedAdminPrivileges: [...privileges],
      selectedAdminForAssignedAdmin: value,
      alreadyAdminRole: value?.role,
    });
  };

  handleChange = (event: any) => {
    const { value } = event.target;
    const { privileges } = value;
    this.setState({
      privileges: [...privileges],
      selectedAdmin: value,
      role: value.role,
    });
  };

  getAdmins = async (token: string, isshowLoading?: boolean) => {
    {
      isshowLoading == false &&
        this.setState({ loading: LoadingEnum.PAGELOADING });
    }
    const res = await getAdminsApiByApartment(
      token,
      this.props.apartment as string
    );
    // console.log("all admins",res);
    if (res.isSuccess && res.statusCode == 200 && res.data.length > 0) {
      this.setState({
        selectedAdmin: res.data[0],
        privileges: [...res.data[0].privileges],
      });
      this.setState({ admins: res.data });
    }
    // this.setState({
    //   loading: LoadingEnum.NONE
    // })
  };
  getAdminsByApartment = async (apartment: string, token: string) => {
    this.setState({ loading: LoadingEnum.PAGELOADING });
    const res = await getAdminsByApartmentId(apartment, token);
    console.log("admins by apratments", res.data);
    if (res.isSuccess && res.statusCode == 200 && res.data.length > 1) {
      this.setState({
        selectedAdminForAssignedAdmin: res.data[1],
        assignedAdminPrivileges: [...res.data[1].privileges],
        alreadyAdminRole: res.data[0].role,
      });
      this.setState({ assignedAdmins: res.data });
    }
    this.setState({
      loading: LoadingEnum.NONE,
    });
  };
  deleteAdminFromApartment = async (adminId: string) => {
    console.log(adminId);
    console.log(this.state.selectedAdminForAssignedAdmin);
    const res = await deleteAdminFromApartmentApi(
      adminId,
      this.state.apartment,
      this.state.authToken
    );
    console.log(res);
    if (res.isSuccess && res.statusCode == 200) {
      const res = await getAdminsByApartmentId(
        this.state.apartment,
        this.state.authToken
      );
      if (res.isSuccess && res.statusCode == 200 && res.data.length > 0) {
        this.setState({
          selectedAdminForAssignedAdmin: res.data[1],
          assignedAdminPrivileges: [...res.data[1].privileges],
        });
        this.setState({ assignedAdmins: res.data });
        this.getAdmins(this.state.authToken, true);
        toast(this.props.t("Admin Removed Succesfully"));
      }
    }
  };
  render() {
    if (this.state.loading == LoadingEnum.PAGELOADING) {
      return <Loading_Page />;
    }
    return <AssignApartmentView controller={this} />;
  }
}
const AssignApartmentComponent = () => {
  const { apartment } = useParams();
  const {t}=useTranslation()
  return <AssignApartmentComponentWrap apartment={apartment as string} t={t}/>;
};

export default AssignApartmentComponent;
