import { Component } from "react";
import ButtonWithIcon from "../../../Neet-Components/Buttons/ButtonWithIcon";
import { Add, Delete, Edit, Key } from "@mui/icons-material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import CustomAlert from "../../../components/CustomAlert";
import LinearIndeterminate from "../../../Neet-Components/Loading-Page/LinearLoader";
import { LockManagerComponentWrap } from "./LockManagerComponent";
import AddLock from "../../../components/AddLock";
import Switch from "@mui/material/Switch";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { StyledDataGrid } from "../apartments/AparmetsView";

const label = { inputProps: { "aria-label": "Switch demo" } };

interface Props {
  controller: LockManagerComponentWrap;
}
enum loadingEnu {
  PAGELOADING = "PAGELOADING",
  ADDBUTTONLOADING = "ADDBUTTONLOADING",
  EDITLOADING = "EDITLOADING",
  DELETELOADING = "DELETELOADING",
  NONE = "NONE",
  REMOVELOGO = "REMOVELOGO",
}
export class LockManagerView extends Component<Props> {
  render() {
    const { controller } = this.props;
    const { admin, currentBuildingType }: any = this.props.controller.context;
    const t=this.props.controller.props.t;
    const columns: GridColDef[] = [
      {
        field: "name",
        headerName: t("Name"),
        width: 100,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "lockId",
        headerName: t("Device Id"),
        width: 100,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "autoLockTimeInSec",
        headerName: t("autoLockTimeInSec"),
        width: 180,
        headerAlign: "center",
        align: "center",
      },
      // {
      //   field: "edit",
      //   headerName: "Edit",
      //   width: 100,
      //   renderCell: (index) => {
      //     return index.api.getRow(index.row.id)?.aptOrSubDivision ==
      //       index.api.getRow(index.row.id)?.lock?.aptOrSubDivision ? (
      //       <div
      //         className="flex-row justify-center gap-5 cursor-pointer"
      //         onClick={() => {
      //           console.log(index.api.getRow(index.row.id));
      //           this.props.controller.onShowEditApartmentWithValue(
      //             index.api.getRow(index.row.id)
      //           );
      //         }}
      //       >
      //         <Edit />
      //       </div>
      //     ) : (
      //       <Edit color={"disabled"} />
      //     );
      //   },
      // },

      {
        field: "delete",
        headerName: t("Delete"),
        width: 100,
        headerAlign: "center",
        align: "center",
        renderCell: (index) => {
          return index.api.getRow(index.row.id)?.aptOrSubDivision ==
            index.api.getRow(index.row.id)?.lock?.aptOrSubDivision ? (
            <div className="flex-row justify-center gap-5 cursor-pointer">
              <CustomAlert
                onPressDelete={() => {
                  // console.log(index.api.getRow(index.row.id));
                  controller.deleteLock(
                    index.api.getRow(index.row.id)?.lock?._id
                  );
                  return;
                }}
              />
            </div>
          ) : (
            <Delete color={"disabled"} />
          );
        },
      },
      {
        field: "managelock",
        headerName: t("Lock Status"),
        width: 200,
        headerAlign: "center",
        align: "center",
        renderCell: (index) => {
          console.log(index.api.getRow(index.row.id));
          return true ? (
            this.props.controller.state.swithcButtonLoadingId !=
            index.api.getRow(index.row.id)._id ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {/* <h1 style={{ color: "red" }}>
                  {index.api.getRow(index.row.id).isActive.toString()}
                </h1> */}
                <p>{t("Disable")}</p>
                <Switch
                  {...label}
                  value={index.api.getRow(index.row.id).isActive}
                  defaultChecked={index.api.getRow(index.row.id).isActive}
                  checked={index.api.getRow(index.row.id).isActive}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    index.api.getRow(index.row.id)?.aptOrSubDivision ==
                    index.api.getRow(index.row.id)?.lock?.aptOrSubDivision
                      ? event.target.checked == false
                        ? controller.onLockDisableButtonPressed(
                            index.api.getRow(index.row.id)?.lock?._id,
                            event.target.checked,
                            index.api.getRow(index.row.id)._id
                          )
                        : controller.onToggleLockStatus(
                            index.api.getRow(index.row.id)?.lock?._id,
                            event.target.checked,
                            index.api.getRow(index.row.id)._id
                          )
                      : controller.onToggleLockStatusByPropertyId(
                          index.api.getRow(index.row.id)._id,
                          event.target.checked,
                          index.api.getRow(index.row.id)._id
                        );
                  }}
                />
                <p>{t("Enable")}</p>
              </div>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <CircularProgress size={20} />
              </Box>
            )
          ) : (
            <Switch
              disabled
              defaultChecked={index.api.getRow(index.row.id).isActive}
            />
          );
        },
      },
    ];
    return (
      <div className="p-3 lg:px-10 lg:py-5 flex-col items-center justify-center  lg:max-w-[84vw] w-[100vw]">
        {this.props.controller.state.showDisableLockPopup && (
          <div className="absolute z-20 h-[100vh] w-[100vw] top-0 left-0 bg-gray-400 bg-opacity-30 flex items-center justify-center">
            <div
              id="alert-additional-content-2"
              className="p-4 mb-4 text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800"
              role="alert"
            >
              <div className="flex items-center">
                <svg
                  className="flex-shrink-0 w-4 h-4 me-2"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                </svg>
                <span className="sr-only">{t("Info")}</span>
                <h3 className="text-lg font-medium">{t("Are you sure ?")}</h3>
              </div>
              <div className="mt-2 mb-4 text-sm">
              {t("Once this lock is disabled, it will no longer be accessible to the flat owner for making changes.")}
              </div>
              <div className="flex">
                <button
                  type="button"
                  className="text-white bg-red-800 hover:bg-red-900 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-xs px-3 py-1.5 me-2 text-center inline-flex items-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                  onClick={()=>{
                    this.props.controller.setState({showDisableLockPopup:false})
                    this.props.controller.onToggleLockStatus(this.props.controller.state.disableLockDetails.lockId,this.props.controller.state.disableLockDetails.status,this.props.controller.state.disableLockDetails._id)
                  }}
                >
                  {t("Disable Lock")}
                </button>
                <button
                  type="button"
                  className="text-red-800 bg-transparent border border-red-800 hover:bg-red-900 hover:text-white focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:bg-red-600 dark:border-red-600 dark:text-red-500 dark:hover:text-white dark:focus:ring-red-800"
                  data-dismiss-target="#alert-additional-content-2"
                  aria-label="Close"
                  onClick={()=>{
                    this.props.controller.setState({showDisableLockPopup:false});
                  }}
                >
                  {t("Dismiss")}
                </button>
              </div>
            </div>
          </div>
        )}
        {controller.state.isShowAddLock && (
          <AddLock
            controller={controller}
            onClose={controller.onShowAddLock}
            onSubmit={controller.addLock}
            buttonLoading={
              controller.state.loading == loadingEnu.EDITLOADING ||
              controller.state.loading == loadingEnu.ADDBUTTONLOADING
                ? true
                : false
            }
            textFields={[]}
            isAddBox={true}
          />
        )}
        {controller.state.isShowEditLock && (
          <AddLock
            controller={controller}
            onClose={controller.onShowEditLock}
            onSubmit={controller.editAparment}
            isAddBox={false}
            buttonLoading={
              controller.state.loading == loadingEnu.EDITLOADING ||
              controller.state.loading == loadingEnu.ADDBUTTONLOADING
                ? true
                : false
            }
            textFields={[]}
          />
        )}
        <div className="flex gap-10 w-60">
          <ButtonWithIcon
            buttonName={t("Add Lock")}
            iconName={<Add />}
            onClick={controller.onShowAddLock}
            width={"130px"}
          />
        </div>
        {this.props.controller.state.loading == loadingEnu.PAGELOADING ? (
          <div className="mt-10 rounded-md overflow-x-scroll h-[77vh] overflow-y-scroll">
            <LinearIndeterminate />
            <StyledDataGrid
              columns={columns}
              hideFooterPagination={true}
              style={{ background: "#fff", height: "90%" }}
            />
          </div>
        ) : (
          <div className="mt-10 rounded-md overflow-x-scroll h-[77vh] overflow-y-scroll">
            <StyledDataGrid
              rows={controller.state.locks.map((row: any, index) => ({
                ...row,
                autoLockTimeInSec:row.lock?.autoLockTimeInSec,
                id: index + 1,
                lockId: row.lock?.device?.deviceId ?? row?.lock?.lockProvider,
                cloudNode:
                  row.lock?.device?.cloudNode ?? row.lock?.lockCredentials,
                name: row?.lock?.name,
                Key: index,
              }))}
              columns={columns}
              style={{ background: "#fff", height: "100%" }}
              // hideFooterPagination={true}
              autoPageSize={true}
              pagination={true}
              disableColumnSelector={true}
              disableRowSelectionOnClick={true}
            />
          </div>
        )}
      </div>
    );
  }
}

export default LockManagerView;
