import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import styled from "@emotion/styled";
import { Popper } from "@mui/material";
import { useTranslation } from "react-i18next";

interface Apartment {
  apartment: any;
  role: string;
}

interface SelectApartmentProps {
  onStatusChange: (apartment: string | null, role: string | null) => void;
  apartments: Apartment[];
}

const CustomPopper = styled(Popper)({
  "& .MuiAutocomplete-listbox": {
    maxHeight: 300, // Max height of dropdown
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "20px", // Scrollbar width
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "gray", // Scrollbar color
    },
  },
});

export default function SelectApartment({
  onStatusChange,
  apartments,
}: SelectApartmentProps) {
  const [status, setStatus] = React.useState<string | null>(
    apartments.length > 0
      ? apartments.length > 1
        ? "all"
        : apartments[0].apartment.name
      : null
  );
  const { t } = useTranslation();
  const handleChange = (
    event: React.ChangeEvent<{}>,
    newValue: string | null
  ) => {
    if (newValue === "all") {
      setStatus("all");
      onStatusChange("all", null);
    } else {
      const selectedApartment = apartments.find(
        (apartment) => apartment.apartment.name === newValue
      );
      if (selectedApartment) {
        setStatus(selectedApartment.apartment.name);
        onStatusChange(selectedApartment.apartment, selectedApartment.role);
      }
    }
  };

  return (
    <Box
      sx={{
        width: 400,
        background: "#fff",
        height: "50px",
        borderRadius: 4,
        display: "flex",
        justifyContent: "center",
        paddingTop: "17px",
      }}
    >
      <Autocomplete
        value={status}
        onChange={handleChange}
        options={[
          ...(apartments.length > 1
            ? [{ apartment: { name: "all" }, role: "" }]
            : []),
          ...apartments,
        ].map((apartment) => apartment.apartment.name)}
        getOptionLabel={(option) => option}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("Select Unit Type")}
            variant="outlined"
          />
        )}
        fullWidth
        size="small"
        PopperComponent={CustomPopper}
      />
    </Box>
  );
}
