import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { styled } from "@mui/material/styles";
import Popper from "@mui/material/Popper";
import { useTranslation } from "react-i18next";

interface Apartment {
  apartment: any;
  role: string;
}

interface SelectApartmentProps {
  onStatusChange: (apartment: string | null, role: string | null) => void;
  apartments: Apartment[];
  value?: any;
}

// Customizing scrollbar style for the dropdown
const CustomPopper = styled(Popper)({
  "& .MuiAutocomplete-listbox": {
    maxHeight: 300, // Max height of dropdown
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "20px", // Scrollbar width
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "gray", // Scrollbar color
    },
  },
});

export default function SelectApartmentOneOfAll({
  onStatusChange,
  apartments,
  value,
}: SelectApartmentProps) {
  const [status, setStatus] = React.useState<string>(
    apartments.length > 0 ? value?._id ?? apartments[0].apartment._id : "no"
  );
  const {t}=useTranslation();
  const [searchTerm, setSearchTerm] = React.useState<string>("");

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: Apartment | null
  ) => {
    if (newValue) {
      setStatus(newValue.apartment._id);
      onStatusChange(newValue.apartment, newValue.role);
    }
  };

  // Filter apartments based on the search term
  const filteredApartments = apartments.filter((apartment) =>
    apartment.apartment.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box
      sx={{
        width: 400,
        background: "#fff",
        height: "auto",
        borderRadius: 4,
        display: "flex",
        justifyContent: "center",
        paddingTop: "17px",
        flexDirection: "column",
      }}
    >
      <Autocomplete
        id="apartment-select"
        options={filteredApartments}
        getOptionLabel={(apartment) => apartment.apartment.name}
        value={
          apartments.find((apartment) => apartment.apartment._id === status) ||
          null
        }
        onChange={handleChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("Select Unit Type")}
            size="small"
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search apartment..."
            autoFocus
          />
        )}
        PopperComponent={CustomPopper}
        isOptionEqualToValue={(option, value) =>
          option.apartment._id === value.apartment._id
        }
      />
    </Box>
  );
}
