import React, { MouseEventHandler, useState } from "react";
import TextFieldSimple from "../Neet-Components/Text-Fields/TextFieldSimple";
import { Clear } from "@mui/icons-material";
import { useStateContext } from "../context/ContextProvider";
import ButtonWithIcon from "../Neet-Components/Buttons/ButtonWithIcon";
import { SubdivisionComponentWrap } from "../pages/dashboard/subdivision/SubdivisionComponent";
import ImageUpload from "./ImageUpload";
import ImageUploadSubdivision from "./ImageUploadSubdivision";
import CustomAlert from "./CustomAlert";
import { useTranslation } from "react-i18next";

interface Props {
  textFields: Array<{}>;
  onSubmit: Function;
  buttonLoading: boolean;
  onClose: MouseEventHandler<HTMLDivElement>;
  controller: SubdivisionComponentWrap;
  isAddBox: boolean;
  nameToShow:string;
}
enum loadingEnu {
  PAGELOADING = "PAGELOADING",
  ADDBUTTONLOADING = "ADDBUTTONLOADING",
  EDITLOADING = "EDITLOADING",
  DELETELOADING = "DELETELOADING",
  NONE = "NONE",
  REMOVELOGO = "REMOVELOGO",
}
interface State {
  isAllValueAreValid: string;
}
const AddSubdivision = (props: Props) => {
  const { currentColor, currentMode, subdivisionTreeNavigaitonStack } =
    useStateContext();
  const [isAllValueAreValid, setIsAllValueAreValid] = useState("none");
  const {t}=useTranslation();
  const [base64, setBase64]: any = useState();
  return (
    <div className="fixed h-screen w-screen left-0 top-0 bg-[#0000007b] z-10 flex items-center justify-center">
      <div className="bg-white  flex flex-wrap items-center justify-center gap-2 w-[500px] p-10 pt-20 relative rounded-xl">
        <div
          onClick={props.onClose}
          className="rounded-full w-10 h-10 flex items-center justify-center absolute right-2 top-2 cursor-pointer shadow-black shadow-md"
          style={{ background: currentColor }}
        >
          <Clear sx={{ color: "white" }} />
        </div>
        <div className=" absolute top-[20px] w-[80%] text-center">
          <p className="text-2xl font-semibold text-neutral-700">{props.nameToShow}</p>
        </div>
        <TextFieldSimple
          onChange={
            props.isAddBox
              ? props.controller.onAddAparmentTextChange
              : props.controller.onEditAparmentTextChange
          }
          placeholder={t("Name*")}
          defaultValue={
            props.isAddBox
              ? props.controller.state.apartmentToAdd.name
              : props.controller.state.apartmentToEdit.name
          }
          value={
            props.isAddBox
              ? props.controller.state.apartmentToAdd.name
              : props.controller.state.apartmentToEdit.name
          }
          errorMsg={isAllValueAreValid == "name" ? t("Enter a valid name") : ""}
          width="200px"
          name="name"
          errorAbsolute={true}
        />
        {/* <TextFieldSimple
                    onChange={props.isAddBox? props.controller.onAddAparmentTextChange:props.controller.onEditAparmentTextChange}
                    placeholder="Location"
                    defaultValue={props.isAddBox? props.controller.state.apartmentToAdd.location:props.controller.state.apartmentToEdit.location}
                    value={props.isAddBox? props.controller.state.apartmentToAdd.location:props.controller.state.apartmentToEdit.location}
                    errorMsg={isAllValueAreValid == "location" ? "Enter a valid location" : ""}
                    width="200px"
                    name="location"
                />
                <TextFieldSimple
                    onChange={props.isAddBox? props.controller.onAddAparmentTextChange:props.controller.onEditAparmentTextChange}
                    placeholder="Phone Number"
                    defaultValue={props.isAddBox? props.controller.state.apartmentToAdd.phoneNumber:props.controller.state.apartmentToEdit.phoneNumber}
                    value={props.isAddBox? props.controller.state.apartmentToAdd.phoneNumber:props.controller.state.apartmentToEdit.phoneNumber}
                    errorMsg={isAllValueAreValid == "phoneNumber" ? "Enter a valid phone number" : ""}
                    width="200px"
                    name="phoneNumber"
                />                 */}
        {props.controller.state.editHasLogo &&
          subdivisionTreeNavigaitonStack.length <= 2 && (
            <div className="absolute right-10 w-52 bottom-[26%]">
              <CustomAlert
                loading={
                  props.controller.state.loading == loadingEnu.REMOVELOGO
                }
                text={t("Remove Logo")}
                msg={t("Do you want to remove logo?")}
                background={"red"}
                color={"#fff"}
                width={"95%"}
                onPressDelete={() => {
                  props.controller.removeLogo(
                    props.controller.state.apartmentToEdit._id
                  );
                }}
              />
            </div>
          )}
        {subdivisionTreeNavigaitonStack.length <= 2 && (
          <div className="w-full mt-6">
            <ImageUploadSubdivision
              setLogoBase64={setBase64}
              controller={props.controller}
              isAddApartment={props.isAddBox}
            />
          </div>
        )}
        <ButtonWithIcon
          buttonName={t("Save")}
          onClick={async () => {
            const res = await props.onSubmit();
            console.log(res);
            if (res) {
              setIsAllValueAreValid(res);
            }
          }}
          buttonLoading={props.buttonLoading}
        />
      </div>
    </div>
  );
};

export default AddSubdivision;
