import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Determine the default language
const savedLanguage =
  typeof window !== "undefined" ? localStorage.getItem("selectedLanguage") : null;
const browserLanguage =
  typeof window !== "undefined" ? window.navigator.language.split("-")[0] : "en";
const defaultLanguage = savedLanguage || browserLanguage || "en";

i18n
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    fallbackLng: "en",
    lng: defaultLanguage, // Set the default language
    resources: {
      en: {
        translations: require("./locales/en/translation.json"),
      },
      hi: {
        translations: require("./locales/hin/translation.json"),
      },
      pt: {
        translations: require("./locales/pt/translation.json"),
      },
      ru: {
        translations: require("./locales/ru/translation.json"),
      },
      fr: {
        translations: require("./locales/fr/translation.json"),
      },
      es: {
        translations: require("./locales/es/translation.json"),
      },
    },
    ns: ["translations"],
    defaultNS: "translations",
    interpolation: {
      escapeValue: false, // React already does escaping
    },
    react: {
      useSuspense: false, // Set to false if you do not use Suspense
    },
  });

i18n.languages = ["en", "hi", "pt", "ru", "es", "fr"];

export default i18n;
